import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FxProgress } from './FxProgress';
import { FxProgressLine } from './FxProgressLine';

var node: any;
var progressBars: any = [];

const renderProgress = () => {
   if (progressBars.length == 0)
      return;

   const component = progressBars.shift();
   if (!node) {
      node = document.createElement('div');
      document.getElementById('loadingbar')?.appendChild(node);
   }
   ReactDOM.render(component, node);
}

export const ProgressManager = {
   open() {
      //let ProgressComp = <FxProgressLine />
      let ProgressComp = <FxProgress />
      progressBars.push(ProgressComp);
      if (progressBars.length == 1) { // render the modal only if there is no other showing progressBars
         renderProgress();
      }
   },
   close() {
      ReactDOM.unmountComponentAtNode(node);
      renderProgress();// render the other progressBars which are waiting.
   }
}