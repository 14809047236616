import _ from "lodash";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";


type CartContextType = {
  addQuantity: (options: any) => void,
  minusQuantity: (options: any) => void,
  deleteProduct: (options: any) => void,
  items: any,
  currentProductQuantity: any
  computeCurrentProductQuantity: (options: any) => void,
  totalItems: any,
  totalAmount: any,
  clearCart: () => void
};

export const CartContext = createContext<CartContextType>(null!);
export const CartProvider: React.FC = ({ children }) => {

  const [items, setItems] = useState<any[]>([]);
  const [currentProductQuantity, setCurrentProductQuantity] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const computeTotal = (currentItems:any) => {
    const totalCount= _.sumBy(currentItems, 'quantity');
    const totalAmt = _.sumBy(currentItems, 'lineTotal');
    setTotalItems(totalCount);
    setTotalAmount(totalAmt);
  }

  const computeLineTotal = (product:any) => {
    product.lineTotal = product.quantity * product.item.unitPrice;
  }

  const clearCart = () => {
    setItems([]);
    computeTotal([]);
  }


  const updateItems = (data: any) => {
    setItems((prevState: any) => {
      return [...[], ...data ]
    });
  };

  const addQuantity = (options: any) => {
    let currentItems = items;
    let product = currentItems.find((i: any) => i.productId == options.productId);
    let index = currentItems.findIndex((i: any) => i.productId == options.productId);
    let currentItemQuantity = options.quantity;
    if(product) {
      product.quantity = (product.quantity || 0)+ options.quantity;
      computeLineTotal(product);
      currentItems.splice(index, 1, product);
      currentItemQuantity = product.quantity;
    } else {
      computeLineTotal(options);
      currentItems.push(options);
    }
    setItems(currentItems);
    setCurrentProductQuantity(currentItemQuantity);
    computeTotal(currentItems);
  }

  const minusQuantity = (options: any) => {
    let currentItems = items;
    let product = currentItems.find((i: any) => i.productId == options.productId);
    let index = currentItems.findIndex((i: any) => i.productId == options.productId);
    if(product && product.quantity > 0) {
      product.quantity = product.quantity - options.quantity;
      computeLineTotal(product);
      currentItems.splice(index, 1, product);
      setItems(currentItems);
      setCurrentProductQuantity(product.quantity);
      computeTotal(currentItems);
    } 
  }

  const deleteProduct = (options: any) => {
    let currentItems = items;
    let product = currentItems.find((i: any) => i.productId == options.productId);
    let index = currentItems.findIndex((i: any) => i.productId == options.productId);
    if(product) {
      currentItems.splice(index, 1);
      updateItems(currentItems);
      computeTotal(currentItems);
    } 
  }

  const computeCurrentProductQuantity = (productId : any) => {
    let item = items.find((i:any)=> i.productId == productId);
    let productQuantity = item ? item.quantity : 0;
    setCurrentProductQuantity(productQuantity);
  }

  return (
    <CartContext.Provider
      value={{
        addQuantity,
        minusQuantity,
        deleteProduct,
        items,
        currentProductQuantity,
        computeCurrentProductQuantity,
        totalItems,
        totalAmount,
        clearCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
