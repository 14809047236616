import { combineReducers, createStore, Action } from 'redux';
import { businessCategory } from './business-category.reducer';
import { navData } from './navdata.reducer';
import { profileInfo } from './profile-info.reducer';

export interface IAppAction extends Action<string> {
  payload?: any;
}
const rootReducer = combineReducers({
  navData,
  profileInfo,
  businessCategory
});

function configureStore(rootReducer?: any) {
  const store = createStore(rootReducer);
  return store;
}

export const store = configureStore(rootReducer);