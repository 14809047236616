import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { store } from './reducers';
import './fonts/corporate/Corporate Rounded Regular.ttf';
import './fonts/corporate/Corporate Rounded Bold.ttf';
import './fonts/corporate/Corporate Rounded Oblique.ttf';
import './fonts/corporate/Corporate Rounded Bold Oblique.ttf';
import './fonts/balooBhaina/BalooBhaina2-Regular.ttf';
import './fonts/balooBhaina/BalooBhaina2-Medium.ttf';
import './fonts/balooBhaina/BalooBhaina2-SemiBold.ttf';
import './fonts/balooBhaina/BalooBhaina2-Bold.ttf';
import './fonts/balooBhaina/BalooBhaina2-ExtraBold.ttf';
import './fonts/myriad/MyriadHebrew-Regular.ttf';
import './fonts/himalaya/himalaya.ttf';
import './fonts/literata/Literata-SemiBold.ttf';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
