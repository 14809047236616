import { useNavigation } from "@fx/components";
import { BottomNavigation, BottomNavigationAction, Box, Drawer } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { localstorage } from "../../../../services";
import { businessCategoryMap } from "../../businessCategoryMap";
import "./BookingFooter.scss";

export const BookingFooter = (props: any) => {
  const { navigate } = useNavigation();
  const [value, setValue] = React.useState(0);
  const businessCategory: any = localstorage.getItem('businesscategory');

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToCart = () => {
    let route = businessCategoryMap[businessCategory]?.routes.cart;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToLogin = () => {
    let route = "/login";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [leftMenuOpen, setLeftMenuOpen] = useState(false); 

  const openLeftMenu = (item: any) => {    
    setLeftMenuOpen(true);
  }

  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  return (
    <div className="footer">
    </div>
  );
}
