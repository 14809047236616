import { FxButton, useNavigation } from "@fx/components";
import { Box, Breadcrumbs, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import { TemplateService } from "../../../../services";
import { FxAccordion } from "../../../fx/accordion/FxAccordion";
import { CardIcon } from "../../../fx/CardIcon";
import { FxCurrency } from "../../../FxCurrency/FxCurrency";
import useCart from "../../../providers/cart/useCart";
import useCategories from "../../../providers/catalog/useCategories";
import useProduct from "../../../providers/catalog/useProduct";
import "./JewelleryProductDetail.scss";

export const JewelleryProductDetail = ({ data, actions }: any) => {

  return (
    <>
      <div className="product-details">
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 5, pt: { xs: "100px", md: "175px" } }}>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
          <Box sx={{ width: { xs: "100%", md: "80%" } }}>
            <Box sx={{ px: { sm: 2, md: 5 } }} className="px-5">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{position: "relative", zIndex: 99}}>
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "",
                        isFluidWidth: true,
                        src: data.item.productImage
                      },
                      largeImage: {
                        alt: "",
                        src: data.item.productImage,
                        width: 1200,
                        height: 1800
                      },
                    }}
                  />
                  </Box>
                  {/* <Box sx={{ overflow: "hidden" }}>
                    <Box component="img" sx={{ transition: "0.6s all ease-in-out", "&:hover": { transform: "scale(1.1)" } }} src={data.item.productImage} width="100%" />
                  </Box> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ fontWeight: 600 }} className="font-20 pt-2">{data.item.name}</Box>
                  <Box sx={{ display: "flex", color: "#454545" }} className="pt-4 font-17">
                    <Box>Product Code : </Box>
                    <Box sx={{ pl: 1 }}>{data.item.vendorCode}</Box>
                  </Box>
                  <Box sx={{ display: "flex", fontSize: "16px", color: "#454545" }} className="pt-4">
                    <Box>Availability : </Box>
                    <Box sx={{ pl: 1 }}>In Stock</Box>
                  </Box>
                  <Box sx={{ display: "flex", fontSize: "17px", color: "primary.main", alignItems: "center" }} className="pt-4">
                    <Box>Price</Box>
                    <Box sx={{ pl: 1 }} className="jewel-price"><FxCurrency value={data.item.unitPrice} /></Box>
                  </Box>
                  <Box sx={{ color: "#454545" }} className="pt-2 font-13">
                    (incl. of all taxes)
                  </Box>
                  <div className="pt-4 font-11">No two products are exactly same, therefore weights are approximate.</div>

                  <Box sx={{ fontSize: 13, pt: 3, pb: 1 }}>Quantity</Box>
                  <Box sx={{ width: "90px", border: "1px solid #CCCCCC", py: 1, px: 2, display: "flex", justifyContent: "space-between", fontSize: 15 }}>
                    <CardIcon action={() => actions.minusQuantity(data.item)} item={data.item} icon="fas fa-minus" sx={{ color: '#989898' }} />
                    <Box>{data.item.currentProductQuantity}</Box>
                    <CardIcon action={() => actions.addQuantity(data.item)} item={data.item} icon="fas fa-plus" sx={{ color: '#989898' }} />
                  </Box>

                  <Box sx={{ pt: 4 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} md={4}>
                        <FxButton label="Add to Cart" action={() => actions.addQuantity(data.item)} variant="contained" size="small" fullWidth className="btn-style" />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box><FxButton label="Buy Now" variant="contained" size="small" fullWidth className="btn-style"
                          action={() => actions.buyNow(data.item)} /></Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ pt: 4, pb: { xs: 5, md: 3 }, width: { xs: "100%", md: "70%" } }}>
                    <Box sx={{ color: "#454545", fontSize: 15, fontWeight: 800, py: 2, pl: "15px", display: "flex" }}>
                      <Box><i className="fas fa-share-square pr-2"></i></Box>
                      <Box>Share</Box>
                    </Box>
                    <FxAccordion
                      header={<Box sx={{ color: "#454545", fontSize: 15, fontWeight: 800, display: "flex" }}>
                        <Box><i className="fas fa-truck pr-2"></i></Box>
                        <Box>Shipping</Box>
                      </Box>}
                    >
                      <div>
                        <Box>In-stock items will ship within 7 business days of purchase. (Excluding Holidays)</Box>
                        <Box sx={{ pt: "5px" }}>Chennai Jewelry has no control over FedEx shipping delays.</Box>
                        <Box sx={{ pt: "5px" }}>*Refer to our Shipping Section for more details.</Box>
                      </div>
                    </FxAccordion>
                    <FxAccordion
                      header={<Box sx={{ color: "#454545", fontSize: 15, fontWeight: 800, display: "flex" }}>
                        <Box><i className="fas fa-undo pr-2"></i></Box>
                        <Box>Returns</Box>
                      </Box>}
                    >
                      <div>
                        <Box>Exchanges must be made within 7 days of delivery date.  We do not provide refunds.</Box>
                        <Box sx={{ pt: "10px" }}>*Refer to our return policy for more details.</Box>
                      </div>
                    </FxAccordion>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
        </Box>
      </div>
    </>
  );
}