import navathaniyapillayar from "../../../../assets/honeybee/navathaniyapillayar.jpg";
import vazhkavalamudan from "../../../../assets/honeybee/vazhkavalamudan.jpg";
import leafpillayar from "../../../../assets/honeybee/leafpillayar.jpg";
import fishwallhang from "../../../../assets/honeybee/fishwallhang.jpg";
import clock from "../../../../assets/honeybee/clock.jpg";


const items: any = [
  { productImage: navathaniyapillayar, productName: "Navathaniya Pillayar", unitPrice: 900, size: "15 x 12",
  description: "Ganesh photo with frame is made up of 9 grains such as Wheat, PAddy, Toor Dhal, Moong Dhal, Chenna, White Beans, Sesame, Black gram, Horse gram"},
  { productImage: vazhkavalamudan, productName: "Vazhka Valamudan", unitPrice: 700, size: "15 x 15",
  description: "Welcoming frame, suitable to hang in front of the house. Made with oil painting anf clay." },
  { productImage: leafpillayar, productName: "Leaf Pillayar", unitPrice: 500, size: "10 x 16",
  description: "Hand printed Ganesh photo in sacred Bodhi tree leaf." },
  { productImage: fishwallhang, productName: "Fish Wall Hang", unitPrice: 600, size: "5 x 25",
  description: "A beautiful handmade wall handing with fish design." },
  { productImage: clock, productName: "Clock", unitPrice: 400, size: "20 x 20",
  description: "Handmade clock with clay and chart." }
];

const cartItems: any = [
  { productName: "Navathaniya Pillayar", unitPrice: 900, productQty: 1, qtyPrice: 900 },
  { productName: "vazhkavalamudan", unitPrice: 700, productQty: 1, qtyPrice: 700 },
  { productName: "Leaf Pillayar", unitPrice: 500, productQty: 1, qtyPrice: 500 },
  { productName: "Fish Wall Hang", unitPrice: 600, productQty: 1, qtyPrice: 600 },
  { productName: "Clock", unitPrice: 400, productQty: 1, qtyPrice: 400 }
];

const paymentOptions: Array<any> = [
  { code: "cash", text: "Cash on Delivery", icon: "fas fa-wallet" },
  { code: "card", text: "Credit/Debit Card", icon: "far fa-credit-card" },
];

const deliveryOptions: any = [
  { code: "DoorDelivery", text: "Door Delivery", icon: "fas fa-biking" },
  { code: "TakeAway", text: "Take Away", icon: "fas fa-people-carry" },
];

const summaryItems: any = [
  { productImage: navathaniyapillayar, productName: "Navathaniya Pillayar", unitPrice: 900, productQty: 1, qtyPrice: 900 },
  { productImage: vazhkavalamudan, productName: "Vazhka Valamudan", unitPrice: 700, productQty: 1, qtyPrice: 700 },
  { productImage: leafpillayar, productName: "Leaf Pillayar", unitPrice: 500, productQty: 1, qtyPrice: 500 },
  { productImage: fishwallhang, productName: "Fish Wall Hang", unitPrice: 600, productQty: 1, qtyPrice: 600 },
  { productImage: clock, productName: "Clock", unitPrice: 400, productQty: 1, qtyPrice: 400 }
];

const leftMenu = [
  {icon: "fas fa-user", menuName: "My Profile", route: '/my-profile'},
  {icon: "fas fa-envelope", menuName: "Address", route: '/address-list'},
  {icon: "fas fa-clipboard-list", menuName: "My Orders", route: '/my-orders'}
];

const myOrders = [
  { orderDate: "04/09/21, 08.00 am", orderDisplayId: "#12345", orderAmount: 93.00, paymentMode: "Pending" },
  { orderDate: "01/09/21, 11.00 am", orderDisplayId: "#25634", orderAmount: 115.00, paymentMode: "Paid" },
  { orderDate: "25/08/21, 05.00 pm", orderDisplayId: "#35489", orderAmount: 120.00, paymentMode: "Paid" },
  { orderDate: "13/08/21, 09.00 am", orderDisplayId: "#86432", orderAmount: 150.00, paymentMode: "Paid" },
  { orderDate: "07/08/21, 03.00 pm", orderDisplayId: "#23895", orderAmount: 50.00, paymentMode: "Paid" },
];

const orderDetail: any = [
  { productImage: navathaniyapillayar, productName: "Navathaniya Pillayar", unitPrice: 900, productQty: 1, qtyPrice: 900 },
  { productImage: vazhkavalamudan, productName: "Vazhka Valamudan", unitPrice: 700, productQty: 1, qtyPrice: 700 },
  { productImage: leafpillayar, productName: "Leaf Pillayar", unitPrice: 500, productQty: 1, qtyPrice: 500 },
  { productImage: fishwallhang, productName: "Fish Wall Hang", unitPrice: 600, productQty: 1, qtyPrice: 600 },
  { productImage: clock, productName: "Clock", unitPrice: 400, productQty: 1, qtyPrice: 400 }
];

export const honeybeeCraftzData: any = {
  items: items,
  cartItems: cartItems,
  paymentOptions: paymentOptions,
  deliveryOptions: deliveryOptions,
  summaryItems: summaryItems,
  leftMenu: leftMenu,
  myOrders: myOrders,
  orderDetail: orderDetail  
}
