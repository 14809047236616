import { AppBar, Box, Drawer, Toolbar } from "@mui/material";
import React from "react";
import "./Header.scss";

import { useNavigation } from "@fx/components";
import { businessCategoryMap } from "../businessCategoryMap";
import { useSelector } from "react-redux";
import { localstorage, MockDataService } from "../../../services";
import Filter from "../filter/Filter";
import InfoList from "../info-list/InfoList";
import LeftMenu from "../left-menu/LeftMenu";

export const Header = (props: any) => {
  const mockDataService = MockDataService();
  const { navigate, navigateRoot } = useNavigation();
  const businessCategory: any = localstorage.getItem('businesscategory');

  const headerTitle = businessCategoryMap[businessCategory]?.headerTitle;
  const headerLogo = businessCategoryMap[businessCategory]?.headerLogo;

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route
    };
    navigateRoot(navData);
  }

  const navigateToCart = () => {
    let route = businessCategoryMap[businessCategory]?.routes.cart;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToLogin = () => {
    let route = "/login";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [leftMenuOpen, setLeftMenuOpen] = React.useState(false); 

  const openLeftMenu = (item: any) => {    
    setLeftMenuOpen(true);
  }

  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  const [filterOpen, setFilterOpen] = React.useState(false);
  const closeFilter = () => {
    setFilterOpen(false);
  }
  const openFilter = () => {
    setFilterOpen(true);
  }

  const [infoListOpen, setInfoListOpen] = React.useState(false);
  const closeInfoList = () => {
    setInfoListOpen(false);
  }
  const openInfoList = () => {
    setInfoListOpen(true);
  }

  //const filter = mockDataService.getData('filter');

  return (
    <div className="header">
      <AppBar>
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
          {headerLogo && 
          <Box component="img" sx={{height: "40px", display: "flex", alignItems: "center"}} src={headerLogo} className="pointer" onClick={navigateToDashboard} >
           </Box> }
          <Box className="font-24">{headerTitle}</Box>
          <Box sx={{display: "flex"}}>
            <Box sx={{display: {xs: "none", md: "flex"}}}>
              <Box sx={{cursor: "pointer"}}><i className="fas fa-home icon-style font-17 pl-5" onClick={navigateToDashboard}></i></Box>
              <Box sx={{cursor: "pointer"}}><i className="fas fa-shopping-cart icon-style font-17 pl-5" onClick={navigateToCart}></i></Box>
              <Box sx={{cursor: "pointer"}}><i className="fas fa-bars icon-style font-17 pl-5" onClick={openLeftMenu}></i></Box>
              <Box sx={{cursor: "pointer"}}><i className="fas fa-sign-in-alt icon-style font-17 pl-5" onClick={navigateToLogin}></i></Box>
            </Box>
          <Box sx={{cursor: "pointer"}}><i className="fas fa-info-circle font-17 pl-5" onClick={openInfoList}></i></Box>
          <Box sx={{cursor: "pointer" }}><i className="fas fa-filter font-17 pl-5" onClick={openFilter}></i></Box>
          </Box>         
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="bottom"
        open={filterOpen}
        onClose={closeFilter}
        PaperProps={{
          sx: { height: "65%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px" },
        }}
      >
        <Filter />
      </Drawer>
      <Drawer
        anchor="right"
        open={infoListOpen}
        onClose={closeInfoList}
        PaperProps={{
          sx: { width: { xs: "85%", sm: "70%", md: "40%" } },
        }}>
        <InfoList closeInfoList={closeInfoList} />
      </Drawer>
      <Drawer
        anchor="left"       
        open={leftMenuOpen}
        onClose={closeLeftMenu}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%", md: "20%" } },
        }}>
        <LeftMenu closeAction={closeLeftMenu} />
      </Drawer>
    </div>
  );
}
