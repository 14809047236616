import { useNavigation } from "@fx/components";
import { Badge, BottomNavigation, BottomNavigationAction, Box, Drawer, Grid, List, ListItem, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { localstorage } from "../../../../services";
import { businessCategoryMap } from "../../businessCategoryMap";
import LeftMenu from "../../left-menu/LeftMenu";
import "./JewelleryFooter.scss";
import logo from "../../../../assets/jewellery/chennai-jewel-logo.jpg";
import { JewelleryExclusive } from "../../exclusive-features/jewellery/JewelleryExclusive";
import useCart from "../../../providers/cart/useCart";
import useLogin from "../../../providers/security/useLogin";

export const JewelleryFooter = (props: any) => {
  const { navigate } = useNavigation();
  const [value, setValue] = React.useState(0);
  const businessCategory: any = localstorage.getItem('businesscategory');
  const { totalItems } = useCart();  
  const { isLogin, signOut, getCurrentUser } = useLogin();
  const { clearCartItems } = useCart();

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToCart = () => {
    // let route = businessCategoryMap[businessCategory]?.routes.cart;
    // let navData = {
    //   targetRoute: route
    // };
    // navigate(navData);
    let route = '/jewel-cart';
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToLogin = () => {
    let route = "/login";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToDelivery = () => {
    let route = "/jewel-delivery";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToReturn = () => {
    let route = "/jewel-returns";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }
  const navigateToPolicy = () => {
    let route = "/jewel-privacy-policy";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }
  const navigateToFaq = () => {
    let route = "/jewel-faq";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }
  const navigateToTermsAndCondition = () => {
    let route = "/jewel-terms-and-conditions";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const openLeftMenu = (item: any) => {
    setLeftMenuOpen(true);
  }
  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  const navigateToMyProfile = () => {
    let route = "/my-profile";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToMyOrders = () => {
    let route = "/my-orders";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }
  const navigateToAbout = () => {
    let route = "/jewel-about";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }
  const navigateToRegister = () => {
    let route = "/register";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [anchorE3, setAnchorE3] = React.useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(anchorE3);
  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE3(event.currentTarget);
  };
  const closeUserMenu = () => {
    setAnchorE3(null);
  };

  const navToMyProfile = () => {
    let navData = {
      targetRoute: '/my-profile'
    };
    navigate(navData);
    closeUserMenu();
  }

  const navToMyOrders = () => {
    let navData = {
      targetRoute: '/my-orders'
    };
    navigate(navData);
    closeUserMenu();
  }

  const logout = () => {
    signOut();
    closeUserMenu();
    clearCartItems();
  }

  const openLink = (link: string, openInNewWindow = true) => window.open(link, openInNewWindow ? "_blank" : "_self")

  return (
    <div className="footer">
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { md: "none" } }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Home" icon={<i className="fas fa-home icon-style" onClick={navigateToDashboard}></i>} />
          <BottomNavigationAction label="Cart"
           icon={
            <Badge badgeContent={totalItems} color="error" onClick={navigateToCart}>
            <i className="fas fa-shopping-cart icon-style"></i>
          </Badge>
          }          
           />         
          <BottomNavigationAction label="Wishlist" icon={<i className="far fa-heart icon-style"></i>} />
          {!isLogin && <BottomNavigationAction label="Signin" icon={<i className="fas fa-sign-in-alt icon-style" onClick={navigateToLogin}></i>} />}
          {/* {isLogin && <BottomNavigationAction label="My Orders" icon={<i className="fas fa-clipboard-list icon-style" onClick={navigateToMyOrders}></i>} />} */}
          {isLogin && <BottomNavigationAction label="Profile" icon={<i className="fas fa-user icon-style icon-style" onClick={openUserMenu}></i>} />}
          {/* <BottomNavigationAction label="Profile" icon={<i className="fas fa-user icon-style" onClick={navigateToMyProfile}></i>} /> */}
        </BottomNavigation>
      </Box>

      <Menu
        id="user-menu"
        anchorEl={anchorE3}
        open={userMenuOpen}
        onClose={closeUserMenu}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}       
      >
        <MenuItem sx={{ color: "primary.main" }} onClick={navToMyProfile}>My Profile</MenuItem>
        <MenuItem sx={{ color: "primary.main" }} onClick={navToMyOrders}>My Orders</MenuItem>
        <MenuItem sx={{ color: "primary.main" }} onClick={logout}>Logout</MenuItem>
      </Menu>


      <Box sx={{ display: { xs: "none", md: "block" }, borderTop: "10px solid", borderColor: "primary.main" }}>
        {/*  */}
        {/* <JewelleryExclusive /> */}


        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="px-4 pt-5 pb-5">
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
          <Grid container sx={{ mx: 2 }}>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "space-between", }}>
              <Box>
                <List sx={{ fontSize: "17px" }}>
                  <Box sx={{ fontWeight: 600 }}>My Account</Box>
                  {!isLogin && <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToRegister}>Register</ListItem>}
                  {!isLogin && <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToLogin}>Login</ListItem>}
                  {isLogin && <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToMyProfile}>Account Info</ListItem>}
                  {isLogin && <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToMyOrders}>My Orders</ListItem>}
                </List>
              </Box>
              <Box>
                <List sx={{ fontSize: "17px" }}>
                  <Box sx={{ fontWeight: 600 }}>Information</Box>
                  <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToAbout}>About</ListItem>
                  <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToPolicy}>Privacy Policy</ListItem>
                  <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToDelivery}>Delivery Information</ListItem>
                  <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToReturn}>Return Policy</ListItem>
                </List>
              </Box>
              <Box>
                <List sx={{ fontSize: "17px" }}>
                  <Box sx={{ pb: 2 }}></Box>
                  <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToTermsAndCondition}>Terms & conditions</ListItem>
                  <ListItem sx={{ px: 0, cursor: "pointer" }} onClick={navigateToFaq}>FAQ</ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box component="img" src={logo} sx={{ width: "95%", mx: 5 }} />
            </Grid>
          </Grid>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
        </Box>

        <Box sx={{ backgroundColor: "primary.main", color: "#fff", display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "17px", height: "50px", width: { xs: "100%", md: "80%" } }}>
            <Box>&copy;2022,Chennai Jewels, All Right Reserved</Box>
            <Box sx={{ display: "flex", fontSize: "17px" }}>
              <Box sx={{ cursor: "pointer" }} onClick={() => openLink('https://www.facebook.com/chennaijewel/')}>
                <i className="fab fa-facebook-f" ></i>
              </Box>
              <Box sx={{ ml: 3, cursor: "pointer" }} onClick={() => openLink(' https://www.instagram.com/chennai_jewels/')}>
                <i className="fab fa-instagram" ></i>
              </Box>
              <Box sx={{ ml: 3, cursor: "pointer" }} onClick={() => openLink('https://web.whatsapp.com/send?phone=+919150456745')}>
                <i className="fab fa-whatsapp"></i>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
        </Box>

      </Box>
      <Drawer
        anchor="left"
        open={leftMenuOpen}
        onClose={closeLeftMenu}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%", md: "20%" } },
        }}>
        <LeftMenu closeAction={closeLeftMenu} />
      </Drawer>
    </div>
  );
}
