import { useContext } from "react";
import { SecurityContext } from "./SecurityProvider";

export default function useSecurityContext() {
  const context = useContext(SecurityContext);
  if (!context) {
    throw new Error("useContext must be used within a SecurityProvider");
  }
  return context;
}
