import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const ProductService = ({ getToken }: any) => {

  const getProducts = async ( { queryKey }: any) => {
    const [_, inputData] = queryKey;
    let apiOptions: any = {
      url: 'products/query',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const queryProducts = async (inputData: any) => {
    let apiOptions: any = {
      url: 'products/query',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getProduct = async ( { queryKey }: any) => {
    const [_, inputData] = queryKey;
    let apiOptions: any = {
      url: `products/${inputData.id}`,
      method: 'get',
      data: {}
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getProducts, getProduct, queryProducts };
}