
export const liquorTemplates = {
  category: `<div>
    <Card className="pointer" sx={{ backgroundImage: "url(" + data.item.categoryImage + ")", backgroundRepeat: "no-repeat", borderRadius: "10px", height: "200px", }} >
      <Box sx={{ backgroundColor : "rgba(0, 0, 0, 0.5)", color: "#ffffff", fontWeight: 500, fontSize: "20px", height: "200px", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <span className="font-22">{data.item.categoryName}</span>
      </Box>
    </Card>
  </div>`,
  offerCard: `<div>
    <Card className="pointer" sx={{ display: "flex", borderRadius: "10px", height: "180px", py: 2, px: 3 }} >
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <Box component="img" src={data.item.productImage} sx={{height: "180px", position: "relative", zIndex: 0}}></Box>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{textAlign: "center"}}>         
            <div className="pt-3">
              <div className="font-17 font-weight-medium text-center color-black">{data.item.headerText}</div>
              <div className="font-16 font-weight-medium text-center pt-4 pb-5">{data.item.productName}</div>
              <div className="text-center px-5 pt-5"><FxButton label="Shop Now" variant="contained" size="small" 
                sx={{textTransform: "none",
                fontWeight: "bold",
                height: "30px"}}
                />
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Card>
  </div>`,
  product: `<LiquorProduct data={data} actions={actions}/>`,
  productDetail: `<div className="product-details pb-5">
    <Box sx={{
      display: "flex", justifyContent: "space-between", width: { xs: "85%", sm: "70%", md: "40%" },
      backgroundColor: "primary.main", color: "secondary.main", position: "fixed"
    }} className="py-2 px-3">
      <span>
        <div className="font-17 font-weight-medium">{data.item.productName}</div>
        <div className="font-15 pt-1">{data.item.subProductName}</div>
      </span>
      <CardIcon icon="fas fa-times-circle font-17 pr-5" action={actions.closeProductDetail}/>
    </Box>

    <Box sx={{ pt: 10, px: { sm: 2, md: 5 } }} className="px-5">
      <Grid container direction="row" spacing={1}>
        <Grid item xs={3}>
          <ProductDetailImageRepeater items={data.item.certificates} sx={{ height: "50px" }} className="pb-5" />         
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="img" src={data.item.productImage} sx={{height: "200px"}}></Box>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
          <Box className="text-right">
            <ProductDetailImageRepeater items={data.item.foodMatch} sx={{ height: "25px", display: "flex", flexDirection: "column", alignItems: "flex-end", pr: 0, mr: 0}} className="pb-1" /> 
          </Box>
          <div>
            <div className="pt-2 font-17 font-weight-medium text-right">
              <FxCurrency value={data.item.unitPrice} />
            </div>
            <div className="pt-2 text-right">
              <FxButton label="Add" variant="contained" size="small"
              sx={{
                height: "25px",
                backgroundColor: "#3f3f3f",
                color: "#ffffff",
                textTransform: "none",
                fontWeight: 600
              }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="pt-2 color-7c">
        <ul>        
          <ProductDetailTextList items={data.item.shortDesc} />         
        </ul>
      </div>
      <div>
        <ProductDetailTextRepeater items={data.item.longDesc} /> 
      </div>
      <div className="pt-3 font-15">
        <span className="font-weight-medium">The Lowdown: </span>
        <span>{data.item.alcohol} / {data.item.quantity} / {data.item.cap}</span>
      </div>
    </Box>
  </div>`,
  cart: `<div className="cart-card">
    <Card className="pointer" sx={{ borderRadius: "4px", height: "70px", display: "flex", justifyContent: "space-between", alignItems: "center", px: "15px" }} >
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <div>
            <span className="font-15 font-weight-medium pr-1">{data.item.productName}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div><FxCurrency value={data.item.unitPrice} /></div>
        </Grid>
        <Grid item xs={6} sx={{display: "flex", justifyContent: "center"}}>
          <div className="d-flex font-20">
            <Box sx={{color: "primary.main"}}><i className="fas fa-minus-circle pointer"></i></Box>
            <span className="px-3">{data.item.productQty}</span>
            <Box sx={{color: "primary.main"}}><i className="fas fa-plus-circle pointer"></i></Box>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="font-15 font-weight-medium text-right"><FxCurrency value={data.item.qtyPrice} /></div>
        </Grid>
      </Grid>
    </Card>
  </div>`,
  paymentOptions: `<FxButtonGroup action={actions.handleChange} selected={data.paymentType} item={data.item}/>`,
  deliveryOptions: `<FxButtonGroup action={actions.handleChange} selected={data.deliveryType} item={data.item}/>`,
  deliveryAddress: `<div className="delivery-address">
    <Card sx={{ borderRadius: "4px", p: "10px" }} >
      <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
        <div className="font-weight-medium font-16">Delivery Address</div>
        <Box sx={{color: "primary.main"}}><i className="fas fa-pen font-15"></i></Box>
      </Box>

      {/* If no address selected */}
      {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div className="font-17">No Address Selected</div>
        <div><FxButton label="Select" variant="contained" size="small" className="btn-style" /></div>
      </Box> */}

      <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
        <div>Karthik</div>
        <div>North Street</div>
        <div>Chennai</div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
        <div className="font-weight-medium font-16">Phone Number</div>
        <Box sx={{color: "primary.main"}}><i className="fas fa-pen font-15"></i></Box>
      </Box>
      <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
        <div>9012345678</div>
      </Box>
    </Card>
  </div>`,
  deliverySlot: `<Grid item xs={6} lg={3}>
    <Box
      sx={{
        backgroundColor: "#EDEDED",
        border: "1px solid #ededed",
        borderRadius: "20px",
        paddingRight: "10px",
        paddingLeft: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        {data.item}
    </Box>
  </Grid>`,
  billDetails: `<div">
    <Card sx={{ borderRadius: "4px", p: "10px" }} >
      <Box sx={{ display: "flex" }} className="pb-2">
        <div className="font-weight-medium font-16">Bill Details</div>         
      </Box>
      <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
        <div>Subtotal</div>
        <div><FxCurrency value={171.95} /></div>
      </Box>
      <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="font-15 pb-1">
        <div>Delivery charge</div>
        <Box className="font-12 font-weight-medium" sx={{color: "#00A611"}}>Free Delivery</Box>
      </Box>
      <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
        <div>Coupon</div>
        <div><FxCurrency value={0} /></div>
      </Box>
      <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
        <div>GST</div>
        <div><FxCurrency value={0} /></div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }} className="font-15 pb-1 font-weight-medium">
        <div>Grand Total</div>
        <div><FxCurrency value={171.95} /></div>
      </Box>
    </Card>
  </div>`,
  couponCard: `<div>
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex" }} className="pb-2">
      <div><img src={data.item.icon} width="23px" /></div>
      <div className="font-weight-medium font-16 pl-2">Apply Coupon</div>
    </Box>
    <Box className="pb-3">
      <div>Use coupon code to avail 10% offer for your order</div>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <div>
        <FxInput name="couponCode" value={data.item.couponCode} variant="outlined" size="small" />
      </div>
      <div className="pl-3">
        <FxButton label="Select" variant="contained" size="small" sx={{height: "30px",
          textTransform: "none",
          fontWeight: 600}} />
      </div>
    </Box>
  </Card>
  </div>`,
  summary: `<div>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={1}>
          <Box component="img" src={data.item.productImage} sx={{height: "60px"}}></Box>     
        </Grid>
        <Grid item xs={7}>
          <div>{data.item.productName}</div>
          <div className="font-11 pb-1 color-888">{data.item.subProductName}</div>
          <div><FxCurrency value={data.item.unitPrice} /></div>
        </Grid>
        <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
        <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
      </Grid>
    </Box>
  </div>`,
  myOrders: `<MyordersCard data={data} actions={actions} />`,
  orderDetail: `<div>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={1}>
          <Box component="img" src={data.item.productImage} sx={{height: "60px"}}></Box>     
        </Grid>
        <Grid item xs={7}>
          <div>{data.item.productName}</div>
          <div className="font-11 pb-1 color-888">{data.item.subProductName}</div>
          <div><FxCurrency value={data.item.unitPrice} /></div>
        </Grid>
        <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
        <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
      </Grid>
    </Box>
  </div>`,
  filter: `<div className="filter px-5 py-5">
  <div className="font-20 font-weight-medium">Search</div>
    <SearchCard items={data.searchCategories} />
  <div className="pt-4 pb-4 font-20 font-weight-medium">Price Range</div>   
  <Box className="px-3"><FxSlider priceList={data.priceList} min={0} max={100} /></Box>
  <Box sx={{position: "fixed", bottom: 20, left: 15, right: 15}}>
    <FxButton label="Apply" fullWidth variant="contained" />
  </Box>
</div>`
}