
export const groceryTemplates = {
  category: `<div>
    <Box className="text-center">
      <Box component="img" src={data.item.categoryImage} sx={{borderRadius: "5px",
        width: "60px",
        height: "60px"}}/>
    </Box>
    <Box className="text-center">{data.item.categoryName}</Box>     
  </div>`,
  offerCard: `<div>
    <Card className="pointer" sx={{ display: "flex", borderRadius: "10px", height: "200px"}} >
      <Box>
        <Box component="img" src={data.item.productImage} sx={{width: "180%",
          height: "200px",
          position: "relative",
          zIndex: 0
        }}/>
      </Box>
      <Box>
        <Box component="img" src={data.item.contentArea} sx={{width: "250px", 
          position: "relative",
          zIndex: 1,
          height: "200px"}} />
        <Box sx={{position: "relative",
          zIndex: 2,
          paddingLeft: "40px",
          top: "-130px",  
          color: "#FFFFFF",
          textAlign: "right"}}>
          <div className="font-17 font-weight-medium px-5 text-center">{data.item.headerText}</div>
          <div className="text-center px-5 pt-5">
            <FxButton label="Shop Now" variant="contained" size="small" 
              sx={{ backgroundColor: "#FFFFFF",
                color: "#26A1B3",
                textTransform: "none",
                fontWeight: "bold",
                height: "30px"}} />
          </div>
        </Box>
      </Box>
    </Card>
  </div>`,
  product: `<div>
  <Card sx={{ borderRadius: "10px", height: "150px", p: 1 }} >
    <Box sx={{display: "flex"}}>
    <div>
      <Box component="img" src={data.item.productImage} sx={{width: "120px",
        height: "90px",
        borderRadius: "4px"}} />
    </div>
    <div className="pl-4">
      <div className="font-15 font-weight-medium">{data.item.productName}</div>
      <div className="font-12 color-7c">{data.item.productUnit1}</div>
      <div className="font-15 font-weight-medium pt-1"><FxCurrency value={data.item.unit1Price} /></div>
      <div className="pt-2 d-flex font-18">
        <span><i className="fas fa-minus-circle"></i></span>
        <span className="px-3">0</span>
        <span><i className="fas fa-plus-circle"></i></span>
      </div>
    </div>
    </Box>
    <Box sx={{display: "flex", justifyContent: "space-between", pt: 1}}>
        <Box sx={{ border: "1px solid #E8E8E8",
          borderRadius: "5px",
          padding: "5px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          width: "95px"}}>
          <div>{data.item.productUnit3}</div>
          <div><FxCurrency value={data.item.unit3Price} /></div>
        </Box>
        <Box sx={{ border: "1px solid #E8E8E8",
          borderRadius: "5px",
          padding: "5px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          width: "95px"}}>
          <div>{data.item.productUnit2}</div>
          <div><FxCurrency value={data.item.unit2Price} /></div>
        </Box>
        <Box sx={{backgroundColor: "#E4F8FB",
          color: "#26A1B3",
          border: "1px solid #26A1B3",
          borderRadius: "5px",
          padding: "5px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          width: "95px",
          fontWeight: 600}}>
          <div>{data.item.productUnit1}</div>
          <div><FxCurrency value={data.item.unit1Price} /></div>
        </Box>
    </Box>
  </Card>
</div>`,
  cart: ` <div className="cart-card">
  <Card className="pointer" sx={{ borderRadius: "4px", height: "70px", display: "flex", justifyContent: "space-between", alignItems: "center", px: "15px" }} >

    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item xs={6}>
        <div>
          <span className="font-15 font-weight-medium pr-1">{data.item.productName}</span>
          <span>[{data.item.productUnit}]</span>
        </div>
        <div><FxCurrency value={data.item.unitPrice} /></div>
      </Grid>
      <Grid item xs={3}>
      <div className="d-flex font-20">
        <CardIcon action={actions.minusQuantity} icon="fas fa-minus-circle" sx={{color: 'primary.main' }}/>
        <span className="px-3">{data.item.productQty}</span>
        <CardIcon action={actions.addQuantity} icon="fas fa-plus-circle" sx={{color: 'primary.main' }}/>
      </div>
      </Grid>
      <Grid item xs={3}>
        <div className="font-15 font-weight-medium text-right"><FxCurrency value={data.item.qtyPrice} /></div>
      </Grid>
    </Grid>
  </Card>
</div>`,
  paymentOptions: `<FxButtonGroup action={actions.handleChange} selected={data.paymentType} item={data.item}/>`,
  deliveryOptions: `<FxButtonGroup action={actions.handleChange} selected={data.deliveryType} item={data.item}/>`,
  deliveryAddress: `<div className="delivery-address">
      <Card sx={{ borderRadius: "4px", p: "10px" }} >
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
          <div className="font-weight-medium font-16">Delivery Address</div>
          <Box sx={{color: "primary.main"}}><i className="fas fa-pen font-15"></i></Box>
        </Box>
        <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
          <div>Karthik</div>
          <div>North Street</div>
          <div>Chennai</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
          <div className="font-weight-medium font-16">Phone Number</div>
          <Box sx={{color: "primary.main"}}><i className="fas fa-pen font-15"></i></Box>
        </Box>
        <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
          <div>9012345678</div>
        </Box>
      </Card>
    </div>`,
  deliverySlot: `<Grid item xs={6} sm={3}>
        <Box
            sx={{
              backgroundColor: "#EDEDED",
              border: "1px solid #ededed",
              borderRadius: "20px",
              paddingRight: "10px",
              paddingLeft: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
          {data.item}
        </Box>
      </Grid>`,
  billDetails: `<div>
      <Card sx={{ borderRadius: "4px", p: "10px" }} >
        <Box sx={{ display: "flex" }} className="pb-2">
          <div className="font-weight-medium font-16">Bill Details</div>         
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
          <div>Subtotal</div>
          <div><FxCurrency value={1100} /></div>
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="font-15 pb-1">
          <div>Delivery charge</div>
          <Box className="font-12 font-weight-medium" sx={{color: "#00A611"}}>Free Delivery</Box>
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
          <div>Coupon</div>
          <div><FxCurrency value={0} /></div>
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
          <div>GST</div>
          <div><FxCurrency value={0} /></div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="font-15 pb-1 font-weight-medium">
          <div>Grand Total</div>
          <div><FxCurrency value={1100} /></div>
        </Box>
      </Card>
    </div>`,
  couponCard: `<div>
        <Card sx={{ borderRadius: "4px", p: "10px" }} >
          <Box sx={{ display: "flex" }} className="pb-2">
            <div><img src={data.item.icon} width="23px" /></div>
            <div className="font-weight-medium font-16 pl-2">Apply Coupon</div>
          </Box>
          <Box className="pb-3">
            <div>Use coupon code to avail 10% offer for your order</div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <div>
              <FxInput name="couponCode" value={data.item.couponCode} variant="outlined" size="small" />
            </div>
            <div className="pl-3">
              <FxButton label="Select" variant="contained" size="small" sx={{height: "30px",
                textTransform: "none",
                fontWeight: 600}} />
            </div>
          </Box>
        </Card>
      </div>`,
  summary: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Box component="img" src={data.item.productImage} sx={{width: "50px",
            height: "50px",
            borderRadius: "4px"}}  />
        </Grid>
        <Grid item xs={5}>
          <div>{data.item.productName}</div>
          <div className="font-12 color-888">{data.item.productUnit}</div>
          <div><FxCurrency value={data.item.unitPrice} /></div>
        </Grid>
        <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
        <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
      </Grid>
    </Box>`,
  myOrders: `<MyordersCard data={data} actions={actions} />`,
  orderDetail: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item xs={3}>
        <Box component="img" src={data.item.productImage} sx={{width: "50px",
          height: "50px",
          borderRadius: "4px"}}  />
      </Grid>
      <Grid item xs={5}>
        <div>{data.item.productName}</div>
        <div className="font-12 color-888">{data.item.productUnit}</div>
        <div><FxCurrency value={data.item.unitPrice} /></div>
      </Grid>
      <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
      <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
    </Grid>
  </Box>`,
  filter: `<div className="filter px-5 py-5">    
    <div className="pt-4 pb-4 font-20 font-weight-medium">Price Range</div> 
    <Box className="px-3"><FxSlider priceList={data.priceList} min={0} max={1500} /></Box>
    <Box sx={{position: "fixed", bottom: 20, left: 15, right: 15}}>
      <FxButton label="Apply" fullWidth variant="contained" />
    </Box>
  </div>`
}