import { AppBar, Box, Drawer, Toolbar } from "@mui/material";
import React from "react";
import { useNavigation } from "@fx/components";
import { businessCategoryMap } from "../../businessCategoryMap";
import { localstorage, MockDataService } from "../../../../services";
import wave from "../../../../assets/honeybee/wave.svg";


export const HoneybeeHeader = (props: any) => {
  const mockDataService = MockDataService();
  const { navigate } = useNavigation();
  const businessCategory: any = localstorage.getItem('businesscategory');

  const headerTitle = businessCategoryMap[businessCategory]?.headerTitle;
  const headerLogo = businessCategoryMap[businessCategory]?.headerLogo;

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  return (
    <div className="header">
      <AppBar sx={{boxShadow: "none", background: 'transparent', height: "20px"}}>
        <Toolbar sx={{display: "flex", justifyContent: "center", minHeight: "20px", backgroundColor: "primary.main"}}>
          {headerLogo && 
          <Box component="img" sx={{height: "40px", display: "flex", alignItems: "center"}} src={headerLogo} className="pointer" onClick={navigateToDashboard} >
           </Box> }
          <Box sx={{fontWeight: 600}} className="font-24 pt-4">{headerTitle}</Box>             
        </Toolbar>       
         <Box component="img" src={wave}></Box>
      </AppBar>
    </div>
  );
}
