import React from 'react';
import { sweetStallTemplates } from '../components/generic/data/sweetStall/templates';
import { groceryTemplates } from '../components/generic/data/grocery/templates';
import { liquorTemplates } from '../components/generic/data/liquor/templates';
import { restaurantTemplates } from '../components/generic/data/restaurant/templates';
import { honeybeeCraftzTemplates } from '../components/generic/data/honeybeeCraftz/templates';
import { jewelleryTemplates } from '../components/generic/data/jewellery/template';
import { localstorage } from './LocalStorageService';

export const TemplateService = () => {

  const templateMap: any = {
    sweetStall : sweetStallTemplates,
    grocery : groceryTemplates,
    liquor : liquorTemplates,
    restaurant: restaurantTemplates,
    honeybee: honeybeeCraftzTemplates,
    jewellery: jewelleryTemplates
  }
  const getTemplate = (templateKey:any) => {
    const businessCategory:any = localstorage.getItem('businesscategory');
    const templates = templateMap[businessCategory];
    return templates[templateKey];
  }

  return { getTemplate };
}