import { Box, Divider, Grid } from "@mui/material";
import { propsToClassKey } from "@mui/styles";
import React from "react";
import { CardIcon } from "../../fx/CardIcon";
import { FxCurrency } from "../../FxCurrency/FxCurrency";

const InfoList = (props: any) => {
  const openinghours = [
    { day: "Tuesday - Friday", openingtime: "08:00 AM - 02:00 PM" },
    { day: "Saturday - Sunday", openingtime: "08:30 AM - 02:30 PM" },
    { day: "Tuesday - Thursday, Sunday", openingtime: "05:30 PM - 09:00 PM" },
    { day: "Friday - Saturday", openingtime: "05:30 PM - 09:30 PM" }
  ];

  const deliveryfees = [
    { distance: "Within 1km", fee: 0.00 }, { distance: "1km - 3km", fee: 3.00 }, { distance: "3km - 5km", fee: 5.00 },
    { distance: "5km - 8km", fee: 7.00 }
  ];

  const paymenttypes = [
    "Cash( Pickup, Delivery )", "Card at pickup counter( Pickup )",
    "Card( Pickup, Delivery )", "Card (G-Pay / via Browser)( Pickup, Delivery )"
  ];

  return (
    <div>
      <Box sx={{ backgroundColor: "primary.main", color: "secondary.main", position: "fixed", width: { xs: "78%", sm: "66%", md: "38.5%" }, py: 1, px: 2, display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ fontSize: 17 }}>Info</Box>
        <Box><CardIcon icon="fas fa-times-circle font-17" onClick={props.closeInfoList} /></Box>
      </Box>
      <Box sx={{ px: 2, pb: 3, pt: 7 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ fontSize: 17, fontWeight: 700 }}>Opening Hours</Box>
            <Divider sx={{ mt: 1 }} />
            <Box>
              {openinghours.length > 0 && openinghours.map((item: any, index: any) => (
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  <Grid item xs={6}>{item.day}</Grid>
                  <Grid item xs={6}>{item.openingtime}</Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ fontSize: 17, fontWeight: 700 }}>Address</Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ pt: 1 }}>
              <Box>Address line1, Address line2, Address line3</Box>
              <Box sx={{ pt: 1 }}>Phone : <b>+12123123123</b></Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ fontSize: 17, fontWeight: 700 }}>Pickup Hours</Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ pt: 1 }}>Same as opening hours</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ fontSize: 17, fontWeight: 700 }}>Delivery Hours</Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ pt: 1 }}>Same as opening hours</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ fontSize: 17, fontWeight: 700 }}>Delivery Fees</Box>
            <Divider sx={{ mt: 1 }} />
            <Box>
              {deliveryfees.length > 0 && deliveryfees.map((item: any, index: any) => (
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  <Grid item xs={6}>{item.distance}</Grid>
                  <Grid item xs={6} sx={{ fontWeight: 600 }}><FxCurrency value={item.fee} /></Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ fontSize: 17, fontWeight: 700 }}>Payment Types</Box>
            <Divider sx={{ mt: 1 }} />
            <Box>
              {paymenttypes.length > 0 && paymenttypes.map((item: any, index: any) => (
               <Box sx={{pt: 1}}>{item}</Box>
              ))}
            </Box>
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}
export default InfoList;