import { Box, Card, CardContent, CardMedia } from "@mui/material";
import React from "react";
import { FxCurrency } from "../../../FxCurrency/FxCurrency";
import JewelleryDummary from "../../../../assets/jewellery/jewelly-dummy.jpeg";
import "./JewelleryProduct.scss"
import { FxButton, AlertService, useNavigation } from "@fx/components";
import useCart from "../../../providers/cart/useCart";
import { WishlistService } from "../../../../services";
import useSecurityContext from "../../../providers/security/useSecurityContext";
import useWishlist from "../../../providers/wishlist/useWishlist";

export const JewelleryProduct = ({ data, actions }: any) => {
  const { addItem } = useCart(data.item._id);
  const { navigate } = useNavigation();
  const { isLogin } = useSecurityContext();
  const { refreshMyWishlistCount } = useWishlist();
  const wishlistService = WishlistService({});
  const addToCart = (item: any) => {
    addItem(item);
  }

  const addToWishlist = async (item: any) => {
    if (isLogin) {
      let inputData = { id: item._id };
      await wishlistService.createWishlist(inputData);
      await refreshMyWishlistCount();
      AlertService.showSuccessMsg();
    } else {
      AlertService.showErrorMsg('Please login to add to wishlist!');
      let navData = {
        targetRoute: '/login'
      };
      navigate(navData);
    }
  }

  return (
    <div className="jewellery-product">
      <Card sx={{ height: "500px", mb: 3, boxShadow: "none", aspectRatio: "auto ", justifyContent: "center", cursor: "pointer", px: 1 }} >
        <Box sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
          <Box onClick={() => actions.openJewelProductDetail(data.item)} sx={{ overflow: "hidden", display: "flex", justifyContent: "center" }}>
            {data.item.productImage &&
              <Box sx={{ overflow: "hidden" }}>
                <CardMedia
                  component="img"
                  sx={{ height: "270px", width: "270px", aspectRatio: "auto " }}
                  image={data.item.productImage}
                />
              </Box>
            }
            {!data.item.productImage &&
              <Box sx={{ overflow: "hidden" }}>
                <CardMedia
                  component="img"
                  sx={{ height: "270px", width: "270px", aspectRatio: "auto " }}
                  image={JewelleryDummary}
                />
              </Box>
            }
          </Box>
          <Box sx={{ color: "primary.main", textAlign: "right", position: "absolute", top: 10, right: 10 }}
            onClick={() => addToWishlist(data.item)}>
            <i className="far fa-heart font-20"></i>
          </Box>
        </Box>

        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "140px" }}>
            <Box onClick={() => actions.openJewelProductDetail(data.item)} sx={{ fontSize: "18px", textAlign: "center", fontWeight: 600 }}>
              {data.item.name}
            </Box>
            <Box>
              <Box sx={{ color: "primary.main", display: "flex", justifyContent: "center" }} className="font-17 pt-1 jewel-price">
                <FxCurrency value={data.item.unitPrice} />
              </Box>
              <Box>
                <FxButton label="Add to Cart" fullWidth size="small" variant="contained" onClick={() => addToCart(data.item)} />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* <Card sx={{ borderRadius: "10px", height: "230px", px: 1, pt: 3, cursor: "pointer" }} onClick={() => actions.openJewelProductDetail(data.item)} >
        <Box sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
          {data.item.productImage &&
            <Box sx={{ overflow: "hidden" }}>
              <CardMedia
                component="img"
                sx={{ height: "150px", width: "150px", aspectRatio: "auto " }}
                image={data.item.productImage}
                alt="pepper and pearls"
              />
            </Box>
          }
          {!data.item.productImage &&
            <Box sx={{ overflow: "hidden" }}>
              <CardMedia
                component="img"
                sx={{ height: "150px", width: "150px", aspectRatio: "auto " }}
                image={JewelleryDummary}
                alt="pepper and pearls"
              />
            </Box>
          }
          <Box sx={{ color: "primary.main", textAlign: "right", position: "absolute", top: 10, right: 10 }}><i className="far fa-heart font-20"></i></Box>
        </Box>

        <Box>
          <div className="font-12 pt-2">{data.item.name}</div>
          <div className="font-15 pt-1 jewel-price"><FxCurrency value={data.item.unitPrice} /></div>
        </Box>
      </Card> */}
    </div>
  );
}