import { Box } from "@mui/material";
import React from "react";
import "./FxButtonGroup.styles.scss";

export const FxButtonGroup = ({ item, selected, action}: any) => {
  return (
    <Box sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center",
            backgroundColor: (item.code == selected) ? "primary.main" : "#fffff",
          color: (item.code == selected) ? "secondary.main" : "#000000"  }}
            className={item.code == selected ? "fx-button-group" : "fx-button-group"}
      onClick={() => action(item)}>
      {item.icon && <Box><i className={item.icon}></i></Box>}
      {item.text && <Box sx={{ pl: "3px" }}>{item.text}</Box>}
    </Box>
  );
}