import { Box } from "@mui/material";
import React from "react";

export const ProductDetailImageRepeater = ({ items, sx, className }: any) => {

  return (
    <div>
      {items.length > 0 && items.map((item: any, i: any) => (
        <Box component="img" sx={sx} className={className} src={item.image}></Box>
      ))
      }
    </div>
  );
}
