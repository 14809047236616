import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const WishlistService = ({ getToken }: any) => {

  const getMyWishlist = async (inputData: any) => {
    let apiOptions: any = {
      url: 'my/wishlist/query',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const createWishlist = async (inputData: any) => {
    let apiOptions: any = {
      url: `wishlist/products/${inputData.id}`,
      method: 'post',
      data: {}
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const deleteWishlist = async (inputData: any) => {
    let apiOptions: any = {
      url: `wishlist/products/${inputData.id}`,
      method: 'delete',
      data: {}
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getWishlistCount = async () => {
    let apiOptions: any = {
      url: `my/wishlist/count`,
      method: 'get',
      data: {}
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getMyWishlist, createWishlist, deleteWishlist, getWishlistCount };
}