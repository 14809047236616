import { FxButton } from "@fx/components";
import { Box, Card } from "@mui/material";
import React from "react";
import { FxCurrency } from "../../../FxCurrency/FxCurrency";

export const RestaurantProduct = ({ data, actions }: any) => {

  return (
    <div>
      <Card sx={{ borderRadius: "10px", height: "105px", p: 1 }} >
        <Box sx={{ display: "flex", py: 1 }} onClick={() => actions.customiseAction(data.item)}>
          <div>
            <Box component="img" src={data.item.productImage} sx={{
              width: "120px",
              height: "90px",
              borderRadius: "4px"
            }} />
          </div>
          
          <Box className="pl-4" sx={{width: "100%"}}>
            <div className="font-15 font-weight-medium">{data.item.productName}</div>
            <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
              <div className="font-15 font-weight-medium"><FxCurrency value={data.item.unitPrice} /></div>
              <div>
                <FxButton label="Add" variant="contained" size="small"
                  sx={{ height: "25px", backgroundColor: "#3f3f3f", color: "#ffffff", textTransform: "none", fontWeight: 600 }}
                />
              </div>
            </Box>

            {/* <div className="pt-3 d-flex font-18">
          <span><i className="fas fa-minus-circle"></i></span>
          <span className="px-3">0</span>
          <span><i className="fas fa-plus-circle"></i></span>
        </div> */}
          </Box>
        </Box>
      </Card>
    </div>
  );
}
