import { Box } from "@mui/material";
import React from "react";

export const ProductDetailTextRepeater = ({ items }: any) => {

  return (
    <div>
      {items.length > 0 && items.map((item: any, i: any) => (
        <Box>{item}</Box>
      ))
      }
    </div>
  );
}