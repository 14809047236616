import React from "react";
import { MockDataService, TemplateService } from "../../../services";
import DynamicComponent from "../dynamic-component/DynamicComponent";

const Filter = (props: any) => {
  const mockDataService = MockDataService();
  const data = mockDataService.getData('filter');
  const filterModel = mockDataService.getData('productFilterModel');

  const handleInputChange = (item: any) => {
    console.log('Filter.handleInputChange item=', item);
  }

  const actions = {
    handleInputChange : handleInputChange
  }
  const options = { data : { ...data, ...filterModel }, actions }  
  const templateService = TemplateService();
  return (
    <DynamicComponent options={options}
      componentHtml={templateService.getTemplate('filter')} />
  );
}
export default Filter;