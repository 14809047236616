import { FxButton, FxInput, FxSelect } from "@fx/components";
import { Box, Breadcrumbs, Button, Card, Drawer, FormGroup, Grid, Link, TextField } from "@mui/material";
import { CardIcon } from "../../fx/CardIcon";
import { FxButtonGroup } from "../../fx/button-group/FxButtonGroup";
import { FxCurrency } from "../../FxCurrency/FxCurrency";
import { LiquorProduct } from "../products/liquor-product/LiquorProduct";
import { ProductDetailImageRepeater } from "../product-detail/product-detail-image-repeater/ProductDetailImageRepeater";
import { ProductDetailTextRepeater } from "../product-detail/product-detail-text-repeater/ProductDetailTextRepeater";
import { ProductDetailTextList } from "../product-detail/product-detail-text-list/ProductDetailTextList";
import { MyOrdersCard } from "../my-orders/my-orders-card/MyOrdersCard";
import { SearchCard } from "../filter/search-card/SearchCard";
import { FxSlider } from "../../fx/slider/FxSlider";
import { RestaurantProduct } from "../products/restaurant-product/RestaurantProduct";
import { HoneybeeCraftzProduct } from "../products/honeybee-craftz-product/HoneybeeCraftzProduct";
import { JewelleryProduct } from "../products/jewellery-product/JewelleryProduct";
import { FxAccordion } from "../../fx/accordion/FxAccordion";
import ReactImageMagnify from "react-image-magnify";
import { JewelleryProductDetail } from "../product-detail/jewellery-product-detail/JewelleryProductDetail";

export const ComponentMap: any = {
    Card : Card,
    FxCurrency: FxCurrency,
    Box: Box,
    Grid: Grid,
    FxInput: FxInput,
    FxButton: FxButton,
    CardIcon: CardIcon,
    FxButtonGroup: FxButtonGroup,
    LiquorProduct: LiquorProduct,
    Drawer: Drawer,
    ProductDetailImageRepeater: ProductDetailImageRepeater,
    ProductDetailTextRepeater: ProductDetailTextRepeater,
    ProductDetailTextList: ProductDetailTextList,
    MyOrdersCard: MyOrdersCard,
    SearchCard: SearchCard,
    FxSelect : FxSelect,
    FxSlider: FxSlider,
    RestaurantProduct: RestaurantProduct,
    HoneybeeCraftzProduct: HoneybeeCraftzProduct,
    JewelleryProduct: JewelleryProduct,    
    FxAccordion: FxAccordion,
    TextField: TextField,
    Button: Button,
    FormGroup: FormGroup,
    Breadcrumbs: Breadcrumbs,
    Link: Link,
    ReactImageMagnify:ReactImageMagnify,
    JewelleryProductDetail:JewelleryProductDetail
}

