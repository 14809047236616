import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const FxAccordion = (props: any) => {
  return (
    <Accordion sx={{ "&.MuiAccordion-root:last-of-type": { borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }, "&.MuiAccordion-root:first-of-type": { borderTopLeftRadius: "0px", borderTopRightRadius: "0px" } }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {props.header}
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}