import baguetteDiamond from "../../../../assets/jewellery/baguetteDiamond.png";
import sapphireBaguette from "../../../../assets/jewellery/sapphireBaguette.png";
import emeraldBaguette from "../../../../assets/jewellery/emeraldBaguette.png";
import graduatedDiamond from "../../../../assets/jewellery/graduatedDiamond.png";
import tworowDiamond from "../../../../assets/jewellery/tworowDiamond.png";

import necklaceMain from "../../../../assets/jewellery/necklaceMain.png";
import necklace1 from "../../../../assets/jewellery/necklace1.png";
import necklace2 from "../../../../assets/jewellery/necklace2.png";
import necklace3 from "../../../../assets/jewellery/necklace3.png";
import necklace4 from "../../../../assets/jewellery/necklace4.png";

import braceletMain from "../../../../assets/jewellery/braceletMain.png";
import bracelet1 from "../../../../assets/jewellery/bracelet1.png";
import bracelet2 from "../../../../assets/jewellery/bracelet2.png";
import bracelet3 from "../../../../assets/jewellery/bracelet3.png";
import bracelet4 from "../../../../assets/jewellery/bracelet4.png";

import earringMain from "../../../../assets/jewellery/earringMain.png";
import earring1 from "../../../../assets/jewellery/earring1.png";
import earring2 from "../../../../assets/jewellery/earring2.png";
import earring3 from "../../../../assets/jewellery/earring3.png";
import earring4 from "../../../../assets/jewellery/earring4.png";

import e1 from "../../../../assets/jewellery/e1.jpg";
import e2 from "../../../../assets/jewellery/e2.jpg";
import e3 from "../../../../assets/jewellery/e3.jpg";
import e4 from "../../../../assets/jewellery/e4.jpg";

import allEarrings from "../../../../assets/jewellery/all-earrings.png";
import allBangles from "../../../../assets/jewellery/all-bangles.png";
import allRings from "../../../../assets/jewellery/all-rings.png";
import allNecklace from "../../../../assets/jewellery/all-necklace.png";
import allPendent from "../../../../assets/jewellery/all-pendent.png";

import banner1 from "../../../../assets/jewellery/banner-1.jpg";
import banner2 from "../../../../assets/jewellery/banner-2.jpg";
import banner3 from "../../../../assets/jewellery/banner-3.jpg";
import banner4 from "../../../../assets/jewellery/cj-1.jpg";
import banner5 from "../../../../assets/jewellery/cj-2.jpg";

// const items: any = [
//   { productImage: baguetteDiamond, productName: "Baguette Diamond Statement Earrings", unitPrice: 28000, 
//   stone: "17.35 Cts of Diamonds, G-H Color, VS-SI Clarity", gold: "56.89 Grams of 18K White Gold", barcode: "Ref. 160334" },
//   { productImage: sapphireBaguette, productName: "Sapphire & Baguette Frame Diamond Earrings", unitPrice: 4700, 
//   stone: "2.03 Cts of Natural Sapphire & 2.00 Cts of Diamonds, G-H Color, VS-SI", gold: "7.0 Grams of 18K White Gold", barcode: "Ref. 167288" },
//   { productImage: emeraldBaguette, productName: "Emerald & Baguette Frame Diamond Earrings", unitPrice: 4100, 
//   stone: "2.01 Cts of Natural Emerald & 1.93 Cts of Diamonds, G-H Color, VS-S", gold: " 6.7 Grams of 18K White Gold", barcode: "Ref. 167286" },
//   { productImage: graduatedDiamond, productName: "Graduated Diamond Crawler Earrings", unitPrice: 11400, 
//   stone: "7.79 Cts of Diamonds, G-H Color, VS-SI Clarity", gold: "13.7 Grams of 18K White Gold", barcode: "Ref. 147730" },
//   { productImage: tworowDiamond, productName: "Two-Row Diamond Crawler Earrings", unitPrice: 6500, 
//   stone: "4.98 Cts of Diamonds, G-H Color, VS-SI Clarity", gold: "12.1 Grams of 18K White Gold", barcode: "Ref. 148594" }
// ];

const items: any = [
  {
    "code": "No.1800",
    "productName": "Chain with Rose Color Stone",
    "unitPrice": 2120,
    "shipping": 120,
    "imageUrl": "sr-1800-1.jpg"
  },
  {
    "code": "No.1800",
    "productName": "Chain with Lavender Color Stone",
    "unitPrice": 2120,
    "shipping": 120,
    "imageUrl": "sr-1800-3.jpg"
  },
  {
    "code": "No.1800",
    "productName": "Chain with Green Color Stone",
    "unitPrice": 2120,
    "shipping": 120,
    "imageUrl": "sr-1800-2.jpg"
  },
  {
    "code": "No.1600",
    "productName": "",
    "unitPrice": 1940,
    "shipping": 120,
    "imageUrl": "sr-1600-1.jpg"
  },
  {
    "code": "No.1600",
    "productName": "Chain with Black Color Stone",
    "unitPrice": 1940,
    "shipping": 120,
    "imageUrl": "sr-1600-3.jpg"
  },
  {
    "code": "No.1600",
    "productName": "Chain with White Color",
    "unitPrice": 1940,
    "shipping": 120,
    "imageUrl": "sr-1600-2.jpg"
  },
  {
    "code": "No.1600",
    "productName": "Green Color with Chain",
    "unitPrice": 1940,
    "shipping": 120,
    "imageUrl": "sr-1600-3.jpg"
  },
  {
    "code": "No.1600",
    "productName": "Pinkish Red with Chain",
    "unitPrice": 1940,
    "shipping": 120,
    "imageUrl": "sr-1600-1.jpg"
  },
  {
    "code": "No.5800",
    "productName": "Set with Maang Tika",
    "unitPrice": 6420,
    "shipping": 120,
    "imageUrl": "sr-5800.jpg"
  },
  {
    "code": "No.6000",
    "productName": "Set with Maang Tika",
    "unitPrice": 6600,
    "shipping": 120,
    "imageUrl": "sr-6000.jpg"
  },
  {
    "code": "No.4200",
    "productName": "Set with Color Beads",
    "unitPrice": 4630,
    "shipping": 120,
    "imageUrl": "sr-4200.jpg"
  },
  {
    "code": "No.4200",
    "productName": "Green Color Beads with Set",
    "unitPrice": 4630,
    "shipping": 120,
    "imageUrl": "sr-4200-1.jpg"
  },
  {
    "code": "No.4200",
    "productName": "Lavender Color Beads with Set",
    "unitPrice": 4630,
    "shipping": 120,
    "imageUrl": "sr-4200-3.jpg"
  },
  {
    "code": "No.4200",
    "productName": "Colored Beads with Set",
    "unitPrice": 4630,
    "shipping": 120,
    "imageUrl": "sr-4200-2.jpg"
  },
  {
    "code": "No.2800",
    "productName": "",
    "unitPrice": 3370,
    "shipping": 120,
    "imageUrl": "sr-2800.jpg"
  },
  {
    "code": "No.7000",
    "productName": "",
    "unitPrice": 7500,
    "shipping": 120,
    "imageUrl": "sr-7000.jpg"
  },
  {
    "code": "No.3120",
    "productName": "Green Color Stone with Set",
    "unitPrice": 3658,
    "shipping": 120,
    "imageUrl": "sr-3120.jpg"
  },
  {
    "code": "No.3120",
    "productName": "",
    "unitPrice": 3658,
    "shipping": 120,
    "imageUrl": "sr-3120.jpg"
  },
  {
    "code": "No.3120",
    "productName": "",
    "unitPrice": 3658,
    "shipping": 120,
    "imageUrl": "sr-3120.jpg"
  },
  {
    "code": "No.4800",
    "productName": "",
    "unitPrice": 5170,
    "shipping": 120,
    "imageUrl": "sr-4800.jpg"
  },
  {
    "code": "No.4800",
    "productName": "",
    "unitPrice": 5170,
    "shipping": 120,
    "imageUrl": "sr-4800.jpg"
  },
  {
    "code": "No.5520",
    "productName": "Dark Navy Blue Stones in set",
    "unitPrice": 5818,
    "shipping": 120,
    "imageUrl": "sr-5520.jpg"
  },
  {
    "code": "No.5520",
    "productName": "",
    "unitPrice": 5818,
    "shipping": 120,
    "imageUrl": "sr-5520-1.jpg"
  },
  {
    "code": "No.5520",
    "productName": "",
    "unitPrice": 5818,
    "shipping": 120,
    "imageUrl": "sr-5520-2.jpg"
  },
  {
    "code": "No.5520",
    "productName": "",
    "unitPrice": 5818,
    "shipping": 120,
    "imageUrl": "sr-5520-3.jpg"
  },
  {
    "code": "No.7000",
    "productName": "",
    "unitPrice": 7500,
    "shipping": 120,
    "imageUrl": "sr-7000-1.jpg"
  },
  {
    "code": "No.7000",
    "productName": "",
    "unitPrice": 7500,
    "shipping": 120,
    "imageUrl": "sr-7000-2.jpg"
  },
  {
    "code": "No.5200",
    "productName": "Choker Pendant Set Piece",
    "unitPrice": 5530,
    "shipping": 120,
    "imageUrl": "sr-5200.jpg"
  },
  {
    "code": "Rams 11690",
    "productName": "",
    "unitPrice": 1109,
    "shipping": 120,
    "imageUrl": "sr-11690.jpg"
  },
  {
    "code": "Rams 111050",
    "productName": "",
    "unitPrice": 1755,
    "shipping": 120,
    "imageUrl": "sr-111050.jpg"
  },
  {
    "code": "Rams 111999",
    "productName": "",
    "unitPrice": 2789,
    "shipping": 120,
    "imageUrl": "sr-11999-1.jpg"
  },
  {
    "code": "Rams 111290",
    "productName": "",
    "unitPrice": 1998,
    "shipping": 120,
    "imageUrl": "sr-112990.jpg"
  },
  {
    "code": "Rams 113200",
    "productName": "",
    "unitPrice": 4200,
    "shipping": 120,
    "imageUrl": "sr-113200.jpg"
  },
  {
    "code": "Rams 11599(18\"&24\")",
    "productName": "",
    "unitPrice": 1009,
    "shipping": 120,
    "imageUrl": "sr-11599.jpg"
  },
  {
    "code": "Rams 11499(18\" Only)",
    "productName": "",
    "unitPrice": 899,
    "shipping": 120,
    "imageUrl": "sr-11499.jpg"
  },
  {
    "code": "Rams 11690",
    "productName": "",
    "unitPrice": 1109,
    "shipping": 120,
    "imageUrl": "sr-11690.jpg"
  },
  {
    "code": "Rams 115990",
    "productName": "",
    "unitPrice": 7889,
    "shipping": 120,
    "imageUrl": "sr-115990.jpg"
  },
  {
    "code": "Rams 114600",
    "productName": "",
    "unitPrice": 6360,
    "shipping": 120,
    "imageUrl": "sr-114600.jpg"
  },
  {
    "code": "Rams 111350",
    "productName": "",
    "unitPrice": 2085,
    "shipping": 120,
    "imageUrl": "sr-111350.jpg"
  },
  {
    "code": "Rams 111350-2",
    "productName": "",
    "unitPrice": 2085,
    "shipping": 120,
    "imageUrl": "sr-111350-1.jpg"
  },
  {
    "code": "Rams 111350-3",
    "productName": "",
    "unitPrice": 2085,
    "shipping": 120,
    "imageUrl": "sr-111350-1.jpg"
  },
  {
    "code": "Rams 111850",
    "productName": "",
    "unitPrice": 2635,
    "shipping": 120,
    "imageUrl": "sr-111850.jpg"
  },
  {
    "code": "Rams 11650( Baby & Kids)",
    "productName": "",
    "unitPrice": 2415,
    "shipping": 120,
    "imageUrl": "sr-11650.jpg"
  },
  {
    "code": "Rams 112990",
    "productName": "",
    "unitPrice": 4239,
    "shipping": 120,
    "imageUrl": "sr-112990.jpg"
  },
  {
    "code": "Rams 11999",
    "productName": "",
    "unitPrice": 2789,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Rams 112990-2",
    "productName": "",
    "unitPrice": 4239,
    "shipping": 120,
    "imageUrl": "sr-112990.jpg"
  },
  {
    "code": "Rams 112600",
    "productName": "",
    "unitPrice": 3810,
    "shipping": 120,
    "imageUrl": "sr-112600.jpg"
  },
  {
    "code": "Rams 111450",
    "productName": "",
    "unitPrice": 2195,
    "shipping": 120,
    "imageUrl": "sr-111450.jpg"
  },
  {
    "code": "Rams 112600 - Green",
    "productName": "",
    "unitPrice": 3810,
    "shipping": 120,
    "imageUrl": "sr-112600.jpg"
  },
  {
    "code": "Rams 11750",
    "productName": "",
    "unitPrice": 1175,
    "shipping": 120,
    "imageUrl": "sr-11750.jpg"
  },
  {
    "code": "Rams 11999",
    "productName": "",
    "unitPrice": 1699,
    "shipping": 120,
    "imageUrl": "sr-11999-1.jpg"
  },
  {
    "code": "Rams 11750",
    "productName": "",
    "unitPrice": 1175,
    "shipping": 120,
    "imageUrl": "sr-11750-1.jpg"
  },
  {
    "code": "Rams 11999",
    "productName": "",
    "unitPrice": 1699,
    "shipping": 120,
    "imageUrl": "sr-111999.jpg"
  },
  {
    "code": "Rams 11900",
    "productName": "",
    "unitPrice": 1590,
    "shipping": 120,
    "imageUrl": "sr-11900.jpg"
  },
  {
    "code": "Rams 111650",
    "productName": "",
    "unitPrice": 2415,
    "shipping": 120,
    "imageUrl": "sr-111650.jpg"
  },
  {
    "code": "Rams 111650",
    "productName": "",
    "unitPrice": 2415,
    "shipping": 120,
    "imageUrl": "sr-111650-1.jpg"
  },
  {
    "code": "Rams 111650",
    "productName": "",
    "unitPrice": 2415,
    "shipping": 120,
    "imageUrl": "sr-11650-2.jpg"
  },
  {
    "code": "Rams 113600",
    "productName": "",
    "unitPrice": 4910,
    "shipping": 120,
    "imageUrl": "sr-113600.jpg"
  },
  {
    "code": "Rams 111750",
    "productName": "",
    "unitPrice": 2525,
    "shipping": 120,
    "imageUrl": "sr-111750.jpg"
  },
  {
    "code": "Rams 11499 Earring",
    "productName": "",
    "unitPrice": 899,
    "shipping": 120,
    "imageUrl": "sr-11499-1.jpg"
  },
  {
    "code": "Rams 11600 Earring",
    "productName": "",
    "unitPrice": 1010,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Rams 111850",
    "productName": "",
    "unitPrice": 2635,
    "shipping": 120,
    "imageUrl": "sr-111850.jpg"
  },
  {
    "code": "Rams 111990",
    "productName": "",
    "unitPrice": 2789,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Rams 112400",
    "productName": "",
    "unitPrice": 3590,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Rams 111150",
    "productName": "",
    "unitPrice": 1865,
    "shipping": 120,
    "imageUrl": "sr-111550.jpg"
  },
  {
    "code": "Rams 111850",
    "productName": "",
    "unitPrice": 2635,
    "shipping": 120,
    "imageUrl": "sr-111850.jpg"
  },
  {
    "code": "Rams 11599 Earring",
    "productName": "",
    "unitPrice": 1009,
    "shipping": 120,
    "imageUrl": "sr-11599.jpg"
  },
  {
    "code": "Rams 11450",
    "productName": "",
    "unitPrice": 845,
    "shipping": 120,
    "imageUrl": "sr-11450.jpg"
  },
  {
    "code": "Rams 11999 Earring",
    "productName": "",
    "unitPrice": 1699,
    "shipping": 120,
    "imageUrl": "sr-11999.jpg"
  },
  {
    "code": "Rams 11450 Earring",
    "productName": "",
    "unitPrice": 845,
    "shipping": 120,
    "imageUrl": "sr-11450.jpg"
  },
  {
    "code": "Rams 11950 Earring",
    "productName": "",
    "unitPrice": 1645,
    "shipping": 120,
    "imageUrl": "sr-11950.jpg"
  },
  {
    "code": "Rams 11690 Earring",
    "productName": "",
    "unitPrice": 1109,
    "shipping": 120,
    "imageUrl": "sr-11690.jpg"
  },
  {
    "code": "AK 00204223-102160",
    "productName": "Antique Kada",
    "unitPrice": 2976,
    "shipping": 120,
    "imageUrl": "sr-00204223-102160.jpg"
  },
  {
    "code": "AK112111 10675 -24 Inches",
    "productName": "Pearl Mala",
    "unitPrice": 1093,
    "shipping": 120,
    "imageUrl": "sr-112111810-10675.jpg"
  },
  {
    "code": "AK112111810 10675-24 Inches 8 L",
    "productName": "Pearl Mala",
    "unitPrice": 1093,
    "shipping": 120,
    "imageUrl": "sr-112111810-10675-1.jpg"
  },
  {
    "code": "AK11209188 10302-5 Lines",
    "productName": "Pearl Mala (24 inches)",
    "unitPrice": 632,
    "shipping": 120,
    "imageUrl": "sr-11209188-10302-5.jpg"
  },
  {
    "code": "AK67905221 10720 AD Pearl",
    "productName": "Choker set",
    "unitPrice": 1142,
    "shipping": 120,
    "imageUrl": "sr-67905221-10720-1.jpg"
  },
  {
    "code": "AK11209188 10302-24 Inches",
    "productName": "Pearl Mala (24 inches)",
    "unitPrice": 632,
    "shipping": 120,
    "imageUrl": "sr-11209188-10302-1.jpg"
  },
  {
    "code": "AK67905225 10990 AD Pearl Choker Set",
    "productName": "",
    "unitPrice": 1689,
    "shipping": 120,
    "imageUrl": "sr-67905225-10990-1.jpg"
  },
  {
    "code": "AK67905225 10990 AD Pearl Choker Set",
    "productName": "",
    "unitPrice": 1689,
    "shipping": 120,
    "imageUrl": "sr-67905225-10990-2.jpg"
  },
  {
    "code": "AK67905225 10990 AD Pearl Choker Set",
    "productName": "",
    "unitPrice": 1689,
    "shipping": 120,
    "imageUrl": "sr-67905225-10990-3.jpg"
  },
  {
    "code": "AK67905225 10990 AD Pearl Choker Set",
    "productName": "",
    "unitPrice": 1689,
    "shipping": 120,
    "imageUrl": "sr-67905225-10990-4.jpg"
  },
  {
    "code": "AK67905221 10720 AD Pearl",
    "productName": "Choker set",
    "unitPrice": 1142,
    "shipping": 120,
    "imageUrl": "sr-167905221-10720-2.jpg"
  },
  {
    "code": "AK67905221 10720 AD Pearl",
    "productName": "Choker set",
    "unitPrice": 1142,
    "shipping": 120,
    "imageUrl": "sr-167905221-10720-3.jpg"
  },
  {
    "code": "AK67905221 10720 AD Pearl",
    "productName": "",
    "unitPrice": 1142,
    "shipping": 120,
    "imageUrl": "sr-67905221-10720-4.jpg"
  },
  {
    "code": "AK06605223 102970-18 Inches",
    "productName": "AD Mala Set",
    "unitPrice": 4217,
    "shipping": 120,
    "imageUrl": "sr-06605223-102970-18-1.jpg"
  },
  {
    "code": "AK06605223 102970-18 Inches",
    "productName": "AD Mala Set",
    "unitPrice": 4217,
    "shipping": 120,
    "imageUrl": "sr-06605223-102970-18-2.jpg"
  },
  {
    "code": "AK06605223 102970-18 Inches",
    "productName": "AD Mala Set",
    "unitPrice": 4217,
    "shipping": 120,
    "imageUrl": "sr-06605223-102970-18-3.jpg"
  },
  {
    "code": "AK06605223 102970-18 Inches",
    "productName": "AD Mala Set",
    "unitPrice": 4217,
    "shipping": 120,
    "imageUrl": "sr-06605223-102970-18-4.jpg"
  },
  {
    "code": "AK06605224 103420 18 inches",
    "productName": "AD Mala Set",
    "unitPrice": 4712,
    "shipping": 120,
    "imageUrl": "sr-06605224-103420-18-1.jpg"
  },
  {
    "code": "AK06605224 103420 18 inches",
    "productName": "AD Mala Set",
    "unitPrice": 4712,
    "shipping": 120,
    "imageUrl": "sr-06605224-103420-18-2.jpg"
  },
  {
    "code": "AK06605224 103420 18 inches",
    "productName": "AD Mala Set",
    "unitPrice": 4712,
    "shipping": 120,
    "imageUrl": "sr-06605224-103420-18-3.jpg"
  },
  {
    "code": "AK06605221 102880-20 Inches",
    "productName": "AD Mala Set",
    "unitPrice": 4712,
    "shipping": 120,
    "imageUrl": "sr-06605221-102880-20.jpg"
  },
  {
    "code": "STJ 92280 JADA Billalu",
    "productName": "",
    "unitPrice": 3458,
    "shipping": 120,
    "imageUrl": "sr-92280-1.jpg"
  },
  {
    "code": "STJ 92280 JADA Billalu",
    "productName": "",
    "unitPrice": 3458,
    "shipping": 120,
    "imageUrl": "sr-92280-2.jpg"
  },
  {
    "code": "STJ 93150",
    "productName": "",
    "unitPrice": 4415,
    "shipping": 120,
    "imageUrl": "sr-93150.jpg"
  },
  {
    "code": "STJ 9399",
    "productName": "",
    "unitPrice": 789,
    "shipping": 120,
    "imageUrl": "sr-9399.jpg"
  },
  {
    "code": "STJ 9399-1",
    "productName": "",
    "unitPrice": 789,
    "shipping": 120,
    "imageUrl": "sr-9399-1.jpg"
  },
  {
    "code": "STJ 9399-2",
    "productName": "",
    "unitPrice": 789,
    "shipping": 120,
    "imageUrl": "sr-9399-2.jpg"
  },
  {
    "code": "STJ 9399-3",
    "productName": "",
    "unitPrice": 789,
    "shipping": 120,
    "imageUrl": "sr-9399-3.jpg"
  },
  {
    "code": "STJ 9399-4",
    "productName": "",
    "unitPrice": 789,
    "shipping": 120,
    "imageUrl": "sr-9399-4.jpg"
  },
  {
    "code": "STJ 9450",
    "productName": "",
    "unitPrice": 845,
    "shipping": 120,
    "imageUrl": "sr-9450.jpg"
  },
  {
    "code": "STJ 9350",
    "productName": "",
    "unitPrice": 685,
    "shipping": 120,
    "imageUrl": "sr-9350.jpg"
  },
  {
    "code": "STJ 9299",
    "productName": "",
    "unitPrice": 629,
    "shipping": 120,
    "imageUrl": "sr-9299.jpg"
  },
  {
    "code": "STJ 9299-1",
    "productName": "",
    "unitPrice": 629,
    "shipping": 120,
    "imageUrl": "sr-9299-1.jpg"
  },
  {
    "code": "STJ 9299-2",
    "productName": "",
    "unitPrice": 629,
    "shipping": 120,
    "imageUrl": "sr-9299-2.jpg"
  },
  {
    "code": "STJ 9299-3",
    "productName": "",
    "unitPrice": 629,
    "shipping": 120,
    "imageUrl": "sr-9299-3.jpg"
  },
  {
    "code": "Styleno 390",
    "productName": "",
    "unitPrice": 2745,
    "shipping": 120,
    "imageUrl": "sr-390.jpg"
  },
  {
    "code": "Styleno 225",
    "productName": "",
    "unitPrice": 1837,
    "shipping": 120,
    "imageUrl": "sr-225.jpg"
  },
  {
    "code": "Styleno 225-1",
    "productName": "",
    "unitPrice": 1837,
    "shipping": 120,
    "imageUrl": "sr-225-1.jpg"
  },
  {
    "code": "Styleno 290",
    "productName": "",
    "unitPrice": 2195,
    "shipping": 120,
    "imageUrl": "sr-290.jpg"
  },
  {
    "code": "Styleno 450",
    "productName": "",
    "unitPrice": 3425,
    "shipping": 120,
    "imageUrl": "sr-450.jpg"
  },
  {
    "code": "Styleno 635",
    "productName": "",
    "unitPrice": 4442,
    "shipping": 120,
    "imageUrl": "sr-635.jpg"
  },
  {
    "code": "Styleno 375",
    "productName": "",
    "unitPrice": 2662,
    "shipping": 120,
    "imageUrl": "sr-375.jpg"
  },
  {
    "code": "Styleno 375-1",
    "productName": "",
    "unitPrice": 2662,
    "shipping": 120,
    "imageUrl": "sr-375-1.jpg"
  },
  {
    "code": "Styleno 375-2",
    "productName": "",
    "unitPrice": 2662,
    "shipping": 120,
    "imageUrl": "sr-375-2.jpg"
  },
  {
    "code": "Styleno 375-3",
    "productName": "",
    "unitPrice": 2662,
    "shipping": 120,
    "imageUrl": "sr-375-3.jpg"
  },
  {
    "code": "Styleno 500",
    "productName": "",
    "unitPrice": 3700,
    "shipping": 120,
    "imageUrl": "sr-500.jpg"
  },
  {
    "code": "Styleno 500-1",
    "productName": "",
    "unitPrice": 3700,
    "shipping": 120,
    "imageUrl": "sr-500-1.jpg"
  },
  {
    "code": "Styleno 500-2",
    "productName": "",
    "unitPrice": 3700,
    "shipping": 120,
    "imageUrl": "sr-500-2.jpg"
  },
  {
    "code": "Styleno 355",
    "productName": "",
    "unitPrice": 2552,
    "shipping": 120,
    "imageUrl": "sr-355.jpg"
  },
  {
    "code": "Styleno 360",
    "productName": "",
    "unitPrice": 2580,
    "shipping": 120,
    "imageUrl": "sr-360.jpg"
  },
  {
    "code": "Styleno 360-1",
    "productName": "",
    "unitPrice": 2580,
    "shipping": 120,
    "imageUrl": "sr-360-1.jpg"
  },
  {
    "code": "Styleno 360-2",
    "productName": "",
    "unitPrice": 2580,
    "shipping": 120,
    "imageUrl": "sr-360-2.jpg"
  },
  {
    "code": "Styleno 485",
    "productName": "",
    "unitPrice": 3617,
    "shipping": 120,
    "imageUrl": "sr-485.jpg"
  },
  {
    "code": "Styleno 365",
    "productName": "",
    "unitPrice": 2607,
    "shipping": 120,
    "imageUrl": "sr-365.jpg"
  },
  {
    "code": "Styleno 250",
    "productName": "",
    "unitPrice": 1975,
    "shipping": 120,
    "imageUrl": "sr-250.jpg"
  },
  {
    "code": "Styleno 800",
    "productName": "",
    "unitPrice": 5350,
    "shipping": 120,
    "imageUrl": "sr-800.jpg"
  },
  {
    "code": "Styleno 380",
    "productName": "",
    "unitPrice": 2690,
    "shipping": 120,
    "imageUrl": "sr-380.jpg"
  },
  {
    "code": "Styleno 465",
    "productName": "",
    "unitPrice": 3507,
    "shipping": 120,
    "imageUrl": "sr-465.jpg"
  },
  {
    "code": "Styleno 825",
    "productName": "",
    "unitPrice": 5487,
    "shipping": 120,
    "imageUrl": "sr-825.jpg"
  },
  {
    "code": "Styleno 750",
    "productName": "",
    "unitPrice": 5075,
    "shipping": 120,
    "imageUrl": "sr-750.jpg"
  },
  {
    "code": "Styleno 345",
    "productName": "",
    "unitPrice": 2497,
    "shipping": 120,
    "imageUrl": "sr-345.jpg"
  },
  {
    "code": "Styleno 475",
    "productName": "",
    "unitPrice": 3562,
    "shipping": 120,
    "imageUrl": "sr-475.jpg"
  },
  {
    "code": "Styleno 540",
    "productName": "",
    "unitPrice": 3920,
    "shipping": 120,
    "imageUrl": "sr-540.jpg"
  },
  {
    "code": "V 280",
    "productName": "",
    "unitPrice": 4030,
    "shipping": 120,
    "imageUrl": "sr-280.jpg"
  },
  {
    "code": "V 280",
    "productName": "",
    "unitPrice": 4030,
    "shipping": 120,
    "imageUrl": "sr-280-1.jpg"
  },
  {
    "code": "V115",
    "productName": "",
    "unitPrice": 1865,
    "shipping": 120,
    "imageUrl": "sr-115.jpg"
  },
  {
    "code": "V115-1",
    "productName": "",
    "unitPrice": 1865,
    "shipping": 120,
    "imageUrl": "sr-115-1.jpg"
  },
  {
    "code": "V165",
    "productName": "",
    "unitPrice": 2415,
    "shipping": 120,
    "imageUrl": "sr-165.jpg"
  },
  {
    "code": "V90",
    "productName": "",
    "unitPrice": 1590,
    "shipping": 120,
    "imageUrl": "sr-90.jpg"
  },
  {
    "code": "V90-1",
    "productName": "",
    "unitPrice": 1590,
    "shipping": 120,
    "imageUrl": "sr-90-1jpg"
  },
  {
    "code": "V 195",
    "productName": "",
    "unitPrice": 2745,
    "shipping": 120,
    "imageUrl": "sr-195.jpg"
  },
  {
    "code": "V240",
    "productName": "",
    "unitPrice": 3590,
    "shipping": 120,
    "imageUrl": "sr-240.jpg"
  },
  {
    "code": "V 435",
    "productName": "",
    "unitPrice": 5735,
    "shipping": 120,
    "imageUrl": "sr-435.jpg"
  },
  {
    "code": "V 130",
    "productName": "",
    "unitPrice": 2030,
    "shipping": 120,
    "imageUrl": "sr-130.jpg"
  },
  {
    "code": "V 155",
    "productName": "",
    "unitPrice": 2305,
    "shipping": 120,
    "imageUrl": "sr-155.jpg"
  },
  {
    "code": "V 175",
    "productName": "",
    "unitPrice": 2470,
    "shipping": 120,
    "imageUrl": "sr-175.jpg"
  },
  {
    "code": "V 160",
    "productName": "",
    "unitPrice": 2360,
    "shipping": 120,
    "imageUrl": "sr-160.jpg"
  },
  {
    "code": "V 75",
    "productName": "",
    "unitPrice": 1175,
    "shipping": 120,
    "imageUrl": "sr-75.jpg"
  },
  {
    "code": "V 105",
    "productName": "",
    "unitPrice": 1755,
    "shipping": 120,
    "imageUrl": "sr-105.jpg"
  },
  {
    "code": "V 95",
    "productName": "",
    "unitPrice": 1645,
    "shipping": 120,
    "imageUrl": "sr-95.jpg"
  },
  {
    "code": "V 95-1",
    "productName": "",
    "unitPrice": 1645,
    "shipping": 120,
    "imageUrl": "sr-95-1.jpg"
  },
  {
    "code": "V 550",
    "productName": "",
    "unitPrice": 7350,
    "shipping": 120,
    "imageUrl": "sr-550.jpg"
  },
  {
    "code": "V 50",
    "productName": "",
    "unitPrice": 900,
    "shipping": 120,
    "imageUrl": "sr-50.jpg"
  },
  {
    "code": "V 125",
    "productName": "",
    "unitPrice": 1975,
    "shipping": 120,
    "imageUrl": "sr-125.jpg"
  },
  {
    "code": "V 125-1",
    "productName": "",
    "unitPrice": 1975,
    "shipping": 120,
    "imageUrl": "sr-125-1.jpg"
  },
  {
    "code": "V 65",
    "productName": "Micro Gold Plated",
    "unitPrice": 1065,
    "shipping": 120,
    "imageUrl": "sr-65.jpg"
  },
  {
    "code": "V 215",
    "productName": "",
    "unitPrice": 2965,
    "shipping": 120,
    "imageUrl": "sr-215.jpg"
  },
  {
    "code": "V 150",
    "productName": "",
    "unitPrice": 2250,
    "shipping": 120,
    "imageUrl": "sr-150.jpg"
  },
  {
    "code": "V 100",
    "productName": "",
    "unitPrice": 1700,
    "shipping": 120,
    "imageUrl": "sr-100.jpg"
  },
  {
    "code": "V 180",
    "productName": "",
    "unitPrice": 2580,
    "shipping": 120,
    "imageUrl": "sr-180.jpg"
  },
  {
    "code": "V 540",
    "productName": "Kundan Look jewellry",
    "unitPrice": 7240,
    "shipping": 120,
    "imageUrl": "sr-540.jpg"
  },
  {
    "code": "SSG102250",
    "productName": "",
    "unitPrice": 3425,
    "shipping": 120,
    "imageUrl": "sr-102250.jpg"
  },
  {
    "code": "SSG103900",
    "productName": "Rice Pearls Kundan Jewellry",
    "unitPrice": 5240,
    "shipping": 120,
    "imageUrl": "sr-103900.jpg"
  },
  {
    "code": "SSG 103600",
    "productName": "",
    "unitPrice": 4910,
    "shipping": 120,
    "imageUrl": "sr-103600.jpg"
  },
  {
    "code": "SSG 103600-1",
    "productName": "",
    "unitPrice": 4910,
    "shipping": 120,
    "imageUrl": "sr-103600-1.jpg"
  },
  {
    "code": "SSG102500",
    "productName": "Gold Finish",
    "unitPrice": 3700,
    "shipping": 120,
    "imageUrl": "sr-102500.jpg"
  },
  {
    "code": "SSG102500-1",
    "productName": "Gold Finish",
    "unitPrice": 3700,
    "shipping": 120,
    "imageUrl": "sr-102500-1.jpg"
  },
  {
    "code": "SSG 102580",
    "productName": "Jaddu earrings/Screw back",
    "unitPrice": 3788,
    "shipping": 120,
    "imageUrl": "sr-102580.jpg"
  },
  {
    "code": "SSG 10799",
    "productName": "Minichoker/bajuband",
    "unitPrice": 1229,
    "shipping": 120,
    "imageUrl": "sr-10799.jpg"
  },
  {
    "code": "SSG102550",
    "productName": "",
    "unitPrice": 3755,
    "shipping": 120,
    "imageUrl": "sr-102550.jpg"
  },
  {
    "code": "Micro Plated Combo- First Touch",
    "productName": "Micro Plated Combi",
    "unitPrice": 1659,
    "shipping": 120,
    "imageUrl": "sr-1659.jpg"
  },
  {
    "code": "Micro Plated Combo- First Touch",
    "productName": "Micro Plated Combi",
    "unitPrice": 1659,
    "shipping": 120,
    "imageUrl": "sr-1659-1.jpg"
  },
  {
    "code": "Micro Plated Chain Set-First Touch",
    "productName": "Micro Plated Chain Set-First Touch",
    "unitPrice": 939,
    "shipping": 120,
    "imageUrl": "sr-939.jpg"
  },
  {
    "code": "Micro Plated Combo- First Touch",
    "productName": "Micro Plated Combo- First Touch",
    "unitPrice": 2509,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Impon Combo Set",
    "productName": "Five Metal Combo",
    "unitPrice": 1859,
    "shipping": 120,
    "imageUrl": "sr-1859.jpg"
  },
  {
    "code": "Micro Plated Mope Chain -1 Set",
    "productName": "24 inchesMicro Plated Mope Chain -1 Set",
    "unitPrice": 889,
    "shipping": 120,
    "imageUrl": "sr-889.jpg"
  },
  {
    "code": "Micro Plated Combo Set 1079",
    "productName": "Micro Plated Combo Set 1079",
    "unitPrice": 1579,
    "shipping": 120,
    "imageUrl": "sr-1579.jpg"
  },
  {
    "code": "1 Set Jhumkha 439",
    "productName": "1 Set Jhumkha 439",
    "unitPrice": 899,
    "shipping": 120,
    "imageUrl": "sr-899.jpg"
  },
  {
    "code": "Bangles 2:4/6/8-679",
    "productName": "Forming Gold Bangles 2:4/6/8",
    "unitPrice": 1099,
    "shipping": 120,
    "imageUrl": "sr-1099.jpg"
  },
  {
    "code": "Bangles Set 2:4/6/8-509",
    "productName": "1 Set bangle Micro Plated / Forming Gold",
    "unitPrice": 909,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Micro Plated 2:4/6/8-455",
    "productName": "1 set bangle",
    "unitPrice": 955,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Impon Jhumkha 1 set",
    "productName": "Impon Jhumkha 1 set",
    "unitPrice": 779,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Matte Finish Combo set 1329",
    "productName": "Matte Finish Combo set 1329",
    "unitPrice": 1829,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "AD set Combo 6499",
    "productName": "Ad set Combo",
    "unitPrice": 7699,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Matte Finish Combo Set 679",
    "productName": "Matte Finish Combo Set 679",
    "unitPrice": 1079,
    "shipping": 120,
    "imageUrl": "sr-1079.jpg"
  },
  {
    "code": "Bangles AD 2:4/6/8-659",
    "productName": "Bangles AD 2:4/6/8",
    "unitPrice": 1059,
    "shipping": 120,
    "imageUrl": "sr-1059.jpg"
  },
  {
    "code": "Bangles AD 2:4/6/8-539",
    "productName": "Bangles AD 2:4/6/8",
    "unitPrice": 789,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Necklace Matt Finish 0379",
    "productName": "Matte Finish necklace 1 set",
    "unitPrice": 679,
    "shipping": 120,
    "imageUrl": "sr-679.jpg"
  },
  {
    "code": "Matte Finish Combo set 729",
    "productName": "Matte Finish Combo 729 set",
    "unitPrice": 1200,
    "shipping": 120,
    "imageUrl": "sr-1200.jpg"
  },
  {
    "code": "AD Necklace 579",
    "productName": "AD 1 set Necklace",
    "unitPrice": 979,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Bangles 399",
    "productName": "Matte Finish Bangles",
    "unitPrice": 799,
    "shipping": 120,
    "imageUrl": "sr-799.jpg"
  },
  {
    "code": "Haram-0909",
    "productName": "Matte Finish Long Haram",
    "unitPrice": 1400,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Micro Plates Bangles 2:4/6/8-509",
    "productName": "Micro Plates Bangles 2:4/6/8",
    "unitPrice": 950,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Beads / Matte Finish Necklace",
    "productName": "1 Set beads matte Finish Necklace",
    "unitPrice": 809,
    "shipping": 120,
    "imageUrl": "sr-809.jpg"
  },
  {
    "code": "Forehead Tikka",
    "productName": "1 Set Forehead Tikka Set",
    "unitPrice": 1209,
    "shipping": 120,
    "imageUrl": "sr-1209.jpg"
  },
  {
    "code": "JA 310",
    "productName": "Micro Plated Bangles 24,26,28",
    "unitPrice": 1063,
    "shipping": 120,
    "imageUrl": "sr-310.jpg"
  },
  {
    "code": "JA390",
    "productName": "Premium Micro Plated Bangles 24,26,28",
    "unitPrice": 1247,
    "shipping": 120,
    "imageUrl": "sr-390.jpg"
  },
  {
    "code": "JA620",
    "productName": "Micro Plated Bangles 24,26,28",
    "unitPrice": 2026,
    "shipping": 120,
    "imageUrl": "sr-620.jpg"
  },
  {
    "code": "JA780",
    "productName": "Premium Micro Plated Bangles 24,26,28",
    "unitPrice": 2394,
    "shipping": 120,
    "imageUrl": "sr-780.jpg"
  },
  {
    "code": "JA230",
    "productName": "Matte Finish Kumkum Box Single",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr-230.jpg"
  },
  {
    "code": "JA230-1",
    "productName": "Micro Plated Bangles 24,26,28",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr230-1.jpg"
  },
  {
    "code": "JA230-2",
    "productName": "Micro Plated Bangles 24,26,28",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr230-2.jpg"
  },
  {
    "code": "JA230-3",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr230-3.jpg"
  },
  {
    "code": "JA230-4",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr230-4.jpg"
  },
  {
    "code": "JA230-5",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr-230-5.jpg"
  },
  {
    "code": "JA230-6",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 879,
    "shipping": 120,
    "imageUrl": "sr-230-6.jpg"
  },
  {
    "code": "JA200",
    "productName": "Matte Finish Kumkum Box Single Multi color stone",
    "unitPrice": 560,
    "shipping": 120,
    "imageUrl": "sr-200.jpg"
  },
  {
    "code": "JA 505",
    "productName": "Matte Finish Kumkum Box Single Multi color stone Bottom",
    "unitPrice": 855,
    "shipping": 120,
    "imageUrl": "sr-505.jpg"
  },
  {
    "code": "JA 505-1",
    "productName": "Matte Finish Kumkum Box Single Multi color stone Bottom",
    "unitPrice": 855,
    "shipping": 120,
    "imageUrl": "sr-505-1.jpg"
  },
  {
    "code": "JA355",
    "productName": "Matte Finish Kumkum Box Single",
    "unitPrice": 635,
    "shipping": 120,
    "imageUrl": "sr-335.jpg"
  },
  {
    "code": "JA2260",
    "productName": "Premium Quality Rajwadi Polish Haram",
    "unitPrice": 6498,
    "shipping": 120,
    "imageUrl": "sr-2260.jpg"
  },
  {
    "code": "JA2260-1",
    "productName": "Premium Quality Rajwadi Polish Haram",
    "unitPrice": 6498,
    "shipping": 120,
    "imageUrl": "sr-2260-1.jpg"
  },
  {
    "code": "JA2260-2",
    "productName": "Premium Quality Rajwadi Polish Haram Green",
    "unitPrice": 6498,
    "shipping": 120,
    "imageUrl": "sr-2260-2.jpg"
  },
  {
    "code": "JA 460",
    "productName": "Premium Micro Plated AD Bangles 24,26,28",
    "unitPrice": 1658,
    "shipping": 120,
    "imageUrl": "sr-460.jpg"
  },
  {
    "code": "JA460",
    "productName": "Micro Plated Bangles 24,26,28",
    "unitPrice": 1658,
    "shipping": 120,
    "imageUrl": "sr-460-1.jpg"
  },
  {
    "code": "JA305",
    "productName": "Micro Plated Bangles 24,26,28",
    "unitPrice": 1051,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "JA1070",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 3061,
    "shipping": 120,
    "imageUrl": "sr-1070.jpg"
  },
  {
    "code": "JA1640",
    "productName": "Premium Quality Matte Finish Haram",
    "unitPrice": 4722,
    "shipping": 120,
    "imageUrl": "sr-1640.jpg"
  },
  {
    "code": "JA2710",
    "productName": "Premium Quality Matte Finish Haram Combo",
    "unitPrice": 7533,
    "shipping": 120,
    "imageUrl": "sr-2710.jpg"
  },
  {
    "code": "JA2710-1",
    "productName": "Premium Quality Matte Finish Haram Combo",
    "unitPrice": 7533,
    "shipping": 120,
    "imageUrl": "sr-2710-1.jpg"
  },
  {
    "code": "JA100",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 530,
    "shipping": 120,
    "imageUrl": "sr-100.jpg"
  },
  {
    "code": "JA100-1",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 530,
    "shipping": 120,
    "imageUrl": "sr-100-1.jpg"
  },
  {
    "code": "JA100-2",
    "productName": "Premium Quality Matte Finish Necklace",
    "unitPrice": 530,
    "shipping": 120,
    "imageUrl": "sr-100-2.jpg"
  },
  {
    "code": "JA240",
    "productName": "Matt Finish Combo",
    "unitPrice": 902,
    "shipping": 120,
    "imageUrl": "sr-240.jpg"
  },
  {
    "code": "JA240-1",
    "productName": "Matt Finish Combo",
    "unitPrice": 902,
    "shipping": 120,
    "imageUrl": "sr-240-1.jpg"
  },
  {
    "code": "JA350",
    "productName": "Matt Finish Haram",
    "unitPrice": 1155,
    "shipping": 120,
    "imageUrl": "sr-350.jpg"
  },
  {
    "code": "Ani3420",
    "productName": "Jadau Kundan",
    "unitPrice": 8824,
    "shipping": 120,
    "imageUrl": "sr-3420.jpg"
  },
  {
    "code": "Ani2550",
    "productName": "Jadau Kundan",
    "unitPrice": 6910,
    "shipping": 120,
    "imageUrl": "sr-2550.jpg"
  },
  {
    "code": "Ani1230",
    "productName": "Jadau Kundan",
    "unitPrice": 3656,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Ani1400",
    "productName": "Jadau Kundan",
    "unitPrice": 4030,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Ani2025",
    "productName": "Jadau Kundan",
    "unitPrice": 5405,
    "shipping": 120,
    "imageUrl": "sr-2025.jpg"
  },
  {
    "code": "Ani1650",
    "productName": "Jadau Kundan",
    "unitPrice": 4580,
    "shipping": 120,
    "imageUrl": "sr-1650.jpg"
  },
  {
    "code": "Ani600",
    "productName": "Jadau Kundan",
    "unitPrice": 1920,
    "shipping": 120,
    "imageUrl": "sr-600.jpg"
  },
  {
    "code": "Ani975",
    "productName": "Jadau Kundan",
    "unitPrice": 2745,
    "shipping": 120,
    "imageUrl": "sr-975.jpg"
  },
  {
    "code": "Ani600",
    "productName": "Jadau Kundan",
    "unitPrice": 1920,
    "shipping": 120,
    "imageUrl": "sr-600-1.jpg"
  },
  {
    "code": "Ani880",
    "productName": "Jadau Kundan",
    "unitPrice": 2536,
    "shipping": 120,
    "imageUrl": "sr-880.jpg"
  },
  {
    "code": "Ani880",
    "productName": "Jadau Kundan",
    "unitPrice": 2536,
    "shipping": 120,
    "imageUrl": "sr-880-1.jpg"
  },
  {
    "code": "Ani1650",
    "productName": "Jadau Kundan",
    "unitPrice": 4580,
    "shipping": 120,
    "imageUrl": "sr-1050.jpg"
  },
  {
    "code": "Ani1050",
    "productName": "Jadau Kundan",
    "unitPrice": 2910,
    "shipping": 120,
    "imageUrl": "sr-1050-1.jpg"
  },
  {
    "code": "KS01159",
    "productName": "Micro Plated Combo KS01159",
    "unitPrice": 1659,
    "shipping": 120,
    "imageUrl": "sr-1159.jpg"
  },
  {
    "code": "IMPON-0379",
    "productName": "Impon single set earring",
    "unitPrice": 579,
    "shipping": 120,
    "imageUrl": "sr-0379.jpg"
  },
  {
    "code": "COIN-0959",
    "productName": "Rose Coin Combo",
    "unitPrice": 1209,
    "shipping": 120,
    "imageUrl": "sr-0959.jpg"
  },
  {
    "code": "KS01179",
    "productName": "Micro Plated Combo KS01179",
    "unitPrice": 1679,
    "shipping": 120,
    "imageUrl": "sr-01179.jpg"
  },
  {
    "code": "KS0859",
    "productName": "Micro Plated Combo KS0859",
    "unitPrice": 1109,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "Impon 0409",
    "productName": "Impon Earring 2 Pairs 409",
    "unitPrice": 609,
    "shipping": 120,
    "imageUrl": ""
  },
  {
    "code": "bangle0399",
    "productName": "Microplated 2 set bangles 399",
    "unitPrice": 599,
    "shipping": 120,
    "imageUrl": "sr-0399.jpg"
  },
  {
    "code": "bangle0329",
    "productName": "Microplated 1 set bangles 329",
    "unitPrice": 529,
    "shipping": 120,
    "imageUrl": "sr-0329.jpg"
  },
  {
    "code": "KS01029",
    "productName": "Matt Finish Premium Quality Combo",
    "unitPrice": 1529,
    "shipping": 120,
    "imageUrl": "sr-1029.jpg"
  },
  {
    "code": "KS0679-Necklace",
    "productName": "Matt Finish Necklace",
    "unitPrice": 929,
    "shipping": 120,
    "imageUrl": "sr-0679.jpg"
  },
  {
    "code": "Jada0709",
    "productName": "Jada Matt Finish 709",
    "unitPrice": 959,
    "shipping": 120,
    "imageUrl": "sr-0709.jpg"
  },
  {
    "code": "Jada0829",
    "productName": "Jada Matt Finish 829",
    "unitPrice": 1079,
    "shipping": 120,
    "imageUrl": "sr-0829.jpg"
  },
  {
    "code": "Cempu0479",
    "productName": "Matt Finish Cempu stone",
    "unitPrice": 679,
    "shipping": 120,
    "imageUrl": "sr-0479.jpg"
  },
  {
    "code": "necklace0679",
    "productName": "Necklace Matt Finish 0679",
    "unitPrice": 929,
    "shipping": 120,
    "imageUrl": "sr-679.jpg"
  },
  {
    "code": "0809-First Touch",
    "productName": "Necklace and earring combo",
    "unitPrice": 1059,
    "shipping": 120,
    "imageUrl": "sr-0809.jpg"
  }
];

const cartItems: any = [
  { productImage: baguetteDiamond, productName: "Baguette Diamond Statement Earrings", unitPrice: 28000, productQty: 1, qtyPrice: 28000 },
  { productImage: sapphireBaguette, productName: "Sapphire & Baguette Frame Diamond Earrings", unitPrice: 4700, productQty: 1, qtyPrice: 4700 },
  { productImage: emeraldBaguette, productName: "Emerald & Baguette Frame Diamond Earrings", unitPrice: 4100, productQty: 1, qtyPrice: 4100 },
  { productImage: graduatedDiamond, productName: "Graduated Diamond Crawler Earrings", unitPrice: 11400, productQty: 1, qtyPrice: 11400 },
  { productImage: tworowDiamond, productName: "Two-Row Diamond Crawler Earrings", unitPrice: 6500, productQty: 1, qtyPrice: 6500 }
];

const paymentOptions: Array<any> = [
  { code: "stripe", text: "Credit/Debit Card", icon: "far fa-credit-card" },
];

const deliveryOptions: any = [
  { code: "DoorDelivery", text: "Door Delivery", icon: "fas fa-biking" },
  { code: "TakeAway", text: "Shop Pickup", icon: "fas fa-people-carry" },
];

const summaryItems: any = [
  { productImage: baguetteDiamond, productName: "Baguette Diamond Statement Earrings", unitPrice: 28000, productQty: 1, qtyPrice: 28000 },
  { productImage: sapphireBaguette, productName: "Sapphire & Baguette Frame Diamond Earrings", unitPrice: 4700, productQty: 1, qtyPrice: 4700 },
  { productImage: emeraldBaguette, productName: "Emerald & Baguette Frame Diamond Earrings", unitPrice: 4100, productQty: 1, qtyPrice: 4100 },
  { productImage: graduatedDiamond, productName: "Graduated Diamond Crawler Earrings", unitPrice: 11400, productQty: 1, qtyPrice: 11400 },
  { productImage: tworowDiamond, productName: "Two-Row Diamond Crawler Earrings", unitPrice: 6500, productQty: 1, qtyPrice: 6500 }
];

const leftMenu = [
  // {icon: "fas fa-user", menuName: "My Profile", route: '/my-profile'},
  // {icon: "fas fa-envelope", menuName: "Address", route: '/address-list'},
  // {icon: "fas fa-clipboard-list", menuName: "My Orders", route: '/my-orders'},
  { icon: "fas fa-portrait", menuName: "About", route: '/jewel-about' },
  { icon: "fas fa-address-card", menuName: "Contact Us", route: '/jewel-contact' },
  { icon: "fas fa-truck", menuName: "Delivery Information", route: '/jewel-delivery' },
  { icon: "fas fa-undo", menuName: "Return Policy", route: '/jewel-returns' },
  // {icon: "fas fa-question", menuName: "FAQ", route: '/jewel-faq'},  
];

const myOrders = [
  { orderDate: "04/09/21, 08.00 am", orderDisplayId: "#12345", orderAmount: 28000, paymentMode: "Pending" },
  { orderDate: "01/09/21, 11.00 am", orderDisplayId: "#25634", orderAmount: 4700, paymentMode: "Paid" },
  { orderDate: "25/08/21, 05.00 pm", orderDisplayId: "#35489", orderAmount: 4100, paymentMode: "Paid" },
  { orderDate: "13/08/21, 09.00 am", orderDisplayId: "#86432", orderAmount: 11400, paymentMode: "Paid" },
  { orderDate: "07/08/21, 03.00 pm", orderDisplayId: "#23895", orderAmount: 6500, paymentMode: "Paid" },
];

const orderDetail: any = [
  { productImage: baguetteDiamond, productName: "Baguette Diamond Statement Earrings", unitPrice: 28000, productQty: 1, qtyPrice: 28000 },
  { productImage: sapphireBaguette, productName: "Sapphire & Baguette Frame Diamond Earrings", unitPrice: 4700, productQty: 1, qtyPrice: 4700 },
  { productImage: emeraldBaguette, productName: "Emerald & Baguette Frame Diamond Earrings", unitPrice: 4100, productQty: 1, qtyPrice: 4100 },
  { productImage: graduatedDiamond, productName: "Graduated Diamond Crawler Earrings", unitPrice: 11400, productQty: 1, qtyPrice: 11400 },
  { productImage: tworowDiamond, productName: "Two-Row Diamond Crawler Earrings", unitPrice: 6500, productQty: 1, qtyPrice: 6500 }
];

const helpMenu = [
  { menuName: "Contact Us", route: '/jewel-contact' },
  { menuName: "About", route: '/jewel-about' },
  { menuName: "Delivery Information", route: '/jewel-delivery' },
  { menuName: "Return Policy", route: '/jewel-returns' }
];

const landingCategory = [
  {
    template: 1, categoryTitle: "Necklaces for Every Occasion", mainImage: necklaceMain,
    categoryCode: 'Necklaces',
    productList: [{ productImage: necklace1, productName: "Zircon Ruby Stone Necklace" }, { productImage: necklace2, productName: "Gold tone pink-green kundan jadau" },
    { productImage: necklace3, productName: "Red Beads Studded Necklace" }, { productImage: necklace4, productName: "White Pearl Necklace" }]
  },
  {
    template: 2, categoryTitle: "Look Elegant with Bracelets", mainImage: braceletMain,
    categoryCode: 'Bangles',
    productList: [{ productImage: bracelet1, productName: "Pearl and Simulated Stone Bracelet" }, { productImage: bracelet2, productName: "Gold Finish Bangle South Indian" },
    { productImage: bracelet3, productName: "Kemp Stone Antique Gold Bangle" }, { productImage: bracelet4, productName: "Gold Screw Bangle" }]
  },
  {
    template: 2, categoryTitle: "Hurnace Your Feminity with Earring", mainImage: earringMain,
    categoryCode: 'Earrings',
    productList: [{ productImage: earring1, productName: "Golden Polished Stone Studded Earrings" }, { productImage: earring2, productName: "Green Meenakari Golden Jhumka set" },
    { productImage: earring3, productName: "Antique Earring" }, { productImage: earring4, productName: "Stunning Light Weight Jhumkas" }]
  }
];

const featuredCategory = [
  {categoryImage: e1, categoryName: "Antique Earrings", code: 'Earrings' }, { categoryImage: e2, categoryName: "Antique Necklaces", code: 'Necklaces'},
  {categoryImage: e3, categoryName: "Kumkum Box", code: 'Kumkum Box'}, {categoryImage: e4, categoryName: "Bangles", code: 'Bangles'},
];

const jewelleryCategory = [
  {categoryImage: allEarrings, code: 'Earrings', categoryName: "All Earrings"},
  {categoryImage: allPendent, code: 'Pendant Sets', categoryName: "All Pendants"}, 
  {categoryImage: allBangles, code: 'Bangles', categoryName: "All Bangles"},
  {categoryImage: allNecklace, code: 'Necklaces', categoryName: "All Necklaces"}
];

const sampleCategory = [
  {categorgyId: 0, categoryName: "Ring", subCategory: [{subName: "Daily Wear"}, {subName: "Casual Wear"}, {subName: "Office Wear"}, {subName: "Party Wear"}]},
  {categorgyId: 1, categoryName: "Earring", subCategory: [{subName: "Studs"}, {subName: "Drops"}, {subName: "Clip-on"}, {subName: "Jhumki"}]},
  {categorgyId: 2, categoryName: "Pendant", subCategory: [{subName: "Casual"}, {subName: "Thali"}, {subName: "Mangalsutra"}]},
  {categorgyId: 3, categoryName: "Chain", subCategory: [{subName: "Hand Crafted"}, {subName: "Machine Made"}, {subName: "Fancy"}, {subName: "For Kids"}]},
  {categorgyId: 4, categoryName: "Bangles", subCategory: [{subName: "Kangan"}, {subName: "Kada"}, {subName: "Hollow"}, {subName: "Oval"}]},
];
const sampleSubCategory = [
  {subCatName: "Studs"}, {subCatName: "Drops"}, {subCatName: "Clip-on"}, {subCatName: "Jhumki"}, {subCatName: "Daily Wear"}, {subCatName: "Party Wear"}, 
];

const breadcrumbsData = [
  {displayName: "Home", route: "/jewel-dashboard"}, {displayName: "Products", route: "/products"}, {displayName: "Product Detail", route: ""}
];

const banners = [
  {bannerImage: banner1}, {bannerImage: banner3}, {bannerImage: banner4}, {bannerImage: banner5}
];

export const jewelleryData: any = {
  items: items,
  cartItems: cartItems,
  paymentOptions: paymentOptions,
  deliveryOptions: deliveryOptions,
  summaryItems: summaryItems,
  leftMenu: leftMenu,
  myOrders: myOrders,
  orderDetail: orderDetail,
  helpMenu: helpMenu,
  landingCategory: landingCategory,
  featuredCategory: featuredCategory,
  jewelleryCategory: jewelleryCategory,
  sampleCategory: sampleCategory,
  sampleSubCategory: sampleSubCategory,
  breadcrumbsData: breadcrumbsData,
  banners: banners
}
