import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const CustomerService = () => {

  const registerCustomer = async (inputData: any) => {
    let apiOptions: any = {
      url: 'customers',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getMyProfile = async () => {
    let apiOptions: any = {
      url: 'my/profile',
      method: 'get',
      data: {}
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const updateProfile = async (inputData: any) => {
    let apiOptions: any = {
      url: `my/profile`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const login = async (inputData: any) => {
    let apiOptions: any = {
      url: 'security/login',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getCurrentUser = async () => {
    let apiOptions: any = {
      url: 'security/user',
      method: 'get',
      data: null
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const logout = async () => {
    let apiOptions: any = {
      url: 'security/logout',
      method: 'get',
      data: null
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const changePassword = async (inputData: any) => {
    let apiOptions: any = {
      url: `security/password`,
      method: 'put',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getOTP = async (inputData: any) => {
    let apiOptions: any = {
      url: `security/otp`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const forgotPasswordUpdate = async (inputData: any) => {
    let apiOptions: any = {
      url: `security/users/${inputData.userId}/password`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }


  const createTicket = async (inputData: any) => {
    let apiOptions: any = {
      url: 'tickets',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { registerCustomer, login, logout, updateProfile, getMyProfile, changePassword, getOTP, forgotPasswordUpdate, getCurrentUser, createTicket };
}