
import { useQuery } from "react-query";

function useREST(props:any) {
  const { queryKey, queryFn, queryOptions={} } = props;
  const { data, isLoading, error, isFetching, refetch } = useQuery(queryKey, queryFn, queryOptions);  
   
  return {
      data,
      isLoading,
      error,
      isFetching,
      refetch
  };
}

export { useREST };