import { Box, Fab } from "@mui/material";
import React from "react";
import "./JewelleryFab.scss";

export const JewelleryFab = (props: any) => {
  const openLink = (link: string, openInNewWindow = true) => window.open(link, openInNewWindow ? "_blank" : "_self")

  return (
    <Box>
      <Fab onClick={() => openLink('https://web.whatsapp.com/send?phone=+919150456745')} 
      sx={{ bottom: 40, position: "fixed", left: 40, backgroundColor: "#2BB741", color: "#fff", "&:hover": { backgroundColor: "#11a929" } }}>
        <i className="fab fa-whatsapp font-28"  ></i>
      </Fab>
    </Box>
  )
}