import React from "react";
import "./CategoryFilter.scss";
import PopupState, { bindToggle, bindPopper, bindHover, bindMenu, bindTrigger, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { usePopupState } from 'material-ui-popup-state/hooks';
import { makeStyles } from "@mui/styles";
import { Box, Button, ClickAwayListener, createStyles, Divider, Fade, List, ListItemButton, ListItemText, Menu, MenuItem, Paper, Popper, Theme } from "@mui/material";
import useCategories from "../../../../providers/catalog/useCategories";


// const styles: any = makeStyles((theme: Theme) =>
//   createStyles({
//     popper: {
//       [theme.breakpoints.down(599)]: {
//         maxWidth: "80%",
//         marginRight: "5px"
//       }
//     }
//   }));



export const CategoryFilter = (props: any) => {
  //const classes = styles();
  const popupState = usePopupState({ variant: 'popover', popupId: 'category-filter-popper' });
  const { categories } = useCategories();
  let defaultSubCategories: any = [];
  const [subcategories, setSubcategories] = React.useState(defaultSubCategories);
  let defaultCategory: any = {};
  const [category, setCategory] = React.useState(defaultCategory);

  const closePopper = (popupState: any) => {
    popupState.close();
  }

  const categoryOnFocus = (cat: any) => {
    setCategory(cat);
    setSubcategories(cat.subItems);
  }

  const categoryClick = (cat: any, popupState: any) => {
    closePopper(popupState)
    props.openProducts(cat);
  }

  const subcategoryClick = (subCat: any, popupState: any) => {
    closePopper(popupState)
    props.openProducts(subCat, subCat.parentCategory);
  }
  const btnColor = "#5d5a5a";
  const btnFont = "18px";
  // const width="100vw";

  return (
    <Box>
      {props.icon && <span className="pointer" title={props.action} {...bindHover(popupState)}>
        <i className={props.icon + " icon-style"} />
      </span>}
      {props.image && <span className="pointer" title={props.action} {...bindHover(popupState)}>
        <img src={props.image} className="icon-style" />
      </span>}
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <Button {...bindHover(popupState)} onMouseLeave={popupState.close}
              sx={{
                fontSize: btnFont, color: btnColor, fontWeight: 600, textTransform: "none", borderBottom: "2px solid", borderColor: "secondary.main",
                "&:hover": { color: "primary.main", borderBottom: "2px solid", borderColor: "primary.main" }
              }}>
              Shop by Category
              {/* <Popper {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}> */}
              <HoverPopover sx={{ mt: "24px", boxShadow: "none" }}
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Paper sx={{boxShadow: "none"}}>
                  <Box sx={{ display: "flex", p: 4, minWidth: "300px", boxShadow: "none" }}>
                    <Box>
                      <List>
                        {categories && categories.map((cat: any, i: any) => (
                          <span>
                            <ListItemButton onMouseEnter={() => categoryOnFocus(cat)}
                              sx={{ color: "#5d5a5a", "&:hover": { background: "#FDEBF7", color: "primary.main" }, pr: 0 }}
                              onClick={() => categoryClick(cat, popupState)}>
                              <ListItemText sx={{ fontSize: "14px", fontFamily: "sans-serif", fontWeight: 600, pr: 1 }} disableTypography>
                                {cat.name}
                              </ListItemText>
                              <Box> <i className="font-24 color-white fas fa-caret-left"></i></Box>
                            </ListItemButton>
                            <Divider />
                          </span>
                        ))
                        }
                      </List>
                    </Box>
                    <Box sx={{ pl: 5 }}>
                      <List>
                        {subcategories && subcategories.map((subCat: any, i: any) => (
                          <ListItemButton sx={{ color: "primary.main", "&:hover": { background: "none" } }}
                            onClick={() => subcategoryClick(subCat, popupState)}>
                            <ListItemText sx={{ fontSize: "14px", fontFamily: "sans-serif" }} disableTypography>{subCat.name}</ListItemText>
                          </ListItemButton>
                        ))
                        }
                      </List>
                    </Box>
                  </Box>

                </Paper>
              </HoverPopover>
              {/* </Fade>
                )}
              </Popper> */}
            </Button>
          </React.Fragment>
        )}
      </PopupState>
    </Box>
  );
};