import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const AddressService = () => {

  const getAddresses = async () => {
    let apiOptions: any = {
      url: `addresses`,
      method: 'get',
      data: {}
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const addAddress = async (inputData:any) => {
    let apiOptions: any = {
      url: `addresses`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const updateAddress = async (inputData:any) => {
    let apiOptions: any = {
      url: `addresses/${inputData._id}`,
      method: 'put',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const deleteAddress = async (inputData:any) => {
    let apiOptions: any = {
      url: `addresses/${inputData._id}`,
      method: 'delete'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getAddressById = async (inputData:any) => {
    let apiOptions: any = {
      url: `addresses/${inputData._id}`,
      method: 'get',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getAddresses, addAddress, updateAddress, getAddressById, deleteAddress };
}