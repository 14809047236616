import React from 'react';
import moment from 'moment';
import { Constants } from '../../../helpers';

export const FxDateDisplayLocal = (props: any) => {
    const getDateTimeDisplay = () => {
        let inputDateVal = props.value;
        let displayType = props.displayType;

        if (inputDateVal) {
            let inputDate = getLocalDateTimeFromUTC(inputDateVal);
            if (displayType == 'date') {
                const result = moment(inputDate).format(Constants.DateDisplayFormat);
                return result;
            }
        }
    }

    const getLocalDateTimeFromUTC = (inputDate:any) => {
        var result;
        if(inputDate) {
            let temp = moment.utc(inputDate).local();
            result = temp.format(Constants.DateDisplayFormat);
        }
        return result;
    }
      
    return (
        <span>
            {getDateTimeDisplay()}
        </span>
    );
};