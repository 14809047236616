import { Box, Slider } from "@mui/material";
import React from "react";
import { AnyIfEmpty } from "react-redux";


export const FxSlider = ({ priceList, min, max}: any) => {

  const [value, setValue] = React.useState([min, max]);

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  function numFormatter(num:any) {
    return num;
  }

  return (
 <Box>
    <Slider
        style={{ maxWidth: 400 }}
        value={value}
        min={min}
        step={1}
        max={max}
        valueLabelFormat={numFormatter}
        marks={priceList}        
        onChange={handleChange}        
        valueLabelDisplay="auto"       
      />
 </Box>
  );
}