import { useNavigation } from "@fx/components";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { MockDataService } from "../../../services";
import "./LeftMenu.scss";

const LeftMenu = ({ closeAction }: any) => {
  const { navigate } = useNavigation();

  const mockDataService = MockDataService();
  const leftMenu = mockDataService.getData('leftMenu');
  const openPage = (item: any) => {
    let navData = {
      targetRoute: item.route
    };
    navigate(navData);
    closeAction()
  }

  const openLink = (link: string, openInNewWindow = true) => window.open(link, openInNewWindow ? "_blank" : "_self")

  return (
    <div>
      <List>
        {leftMenu.length > 0 && leftMenu.map((item: any, i: any) => (
          <ListItem disablePadding>
            <ListItemButton onClick={() => openPage(item)}>
              <ListItemIcon>
                <i className={item.icon}></i>
              </ListItemIcon>
              <ListItemText primary={item.menuName} />
            </ListItemButton>
          </ListItem>
        ))
        }
      </List>
      <div className="pl-4 pt-5">
        <div className="font-18">
          Follow us
        </div>
        <div className="pt-3">
          {/* <i className="fab fa-twitter font-22"></i> */}
          <i className="fab fa-facebook-square font-22" onClick={() => openLink('https://www.facebook.com/chennaijewel/')}></i>
          {/* <i className="fab fa-pinterest pl-5 font-22"></i> */}
          <i className="fab fa-instagram pl-5 font-22" onClick={() => openLink(' https://www.instagram.com/chennai_jewels/')}></i>
        </div>
      </div>
    </div>
  );
}
export default LeftMenu;
