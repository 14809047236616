import { useNavigation } from "@fx/components";
import { BottomNavigation, BottomNavigationAction, Box, Drawer } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { localstorage } from "../../../services";
import { businessCategoryMap } from "../businessCategoryMap";
import LeftMenu from "../left-menu/LeftMenu";
import "./Footer.scss";

export const Footer = (props: any) => {
  const { navigate } = useNavigation();
  const [value, setValue] = React.useState(0);
  const businessCategory: any = localstorage.getItem('businesscategory');

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToCart = () => {
    let route = businessCategoryMap[businessCategory]?.routes.cart;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToLogin = () => {
    let route = "/login";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [leftMenuOpen, setLeftMenuOpen] = useState(false); 

  const openLeftMenu = (item: any) => {    
    setLeftMenuOpen(true);
  }

  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  return (
    <div className="footer">
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation
        showLabels
         value={value}
         onChange={(event, newValue) => {
           setValue(newValue);
         }}
        >
           <BottomNavigationAction label="Home" icon={<i className="fas fa-home icon-style" onClick={navigateToDashboard}></i>} />
          <BottomNavigationAction label="Cart" icon={<i className="fas fa-shopping-cart icon-style" onClick={navigateToCart}></i>} />
          <BottomNavigationAction label="Menu" icon={<i className="fas fa-bars icon-style" onClick={openLeftMenu}></i>} /> 
          <BottomNavigationAction label="Signin" icon={<i className="fas fa-sign-in-alt icon-style" onClick={navigateToLogin}></i>} />
          {/* <BottomNavigationAction label="Profile" icon={<i className="fas fa-user icon-style"></i>} /> */}         
        </BottomNavigation>
      </Box>
      <Drawer
        anchor="left"       
        open={leftMenuOpen}
        onClose={closeLeftMenu}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%", md: "20%" } },
        }}>
        <LeftMenu closeAction={closeLeftMenu} />
      </Drawer>
    </div>
  );
}
