import mulluMurukku from "../../../../assets/sweets/mulluMurukku.jpg";
import maavurundai from "../../../../assets/sweets/maavurundai.jpg";
import uppuSeedai from "../../../../assets/sweets/uppuSeedai.jpg";
import adhirasam from "../../../../assets/sweets/adhirasam.jpg";
import splThattai from "../../../../assets/sweets/splThattai.jpg";
import murukku from "../../../../assets/sweets/mulluMurukku.jpg";
import urundai from "../../../../assets/sweets/maavurundai.jpg";
import seedai from "../../../../assets/sweets/uppuSeedai.jpg";
import sweets from "../../../../assets/sweets/adhirasam.jpg";
import snacks from "../../../../assets/sweets/splThattai.jpg";

import contentArea from "../../../../assets/sweets/contentArea.png"

const categories: any = [
  { categoryImage: murukku, categoryName: "Murukku" },
  { categoryImage: urundai, categoryName: "Urundai" },
  { categoryImage: seedai, categoryName: "Seedai" },
  { categoryImage: sweets, categoryName: "Sweets" },
  { categoryImage: snacks, categoryName: "Snacks" },
];

const offerData: any = [
  { 
    productImage :  murukku,  
    headerText: "10% off on Murukku varieties",
    contentArea : contentArea
  },
  { 
    productImage : maavurundai,  
    headerText: "5% off on Sweets",
    contentArea: contentArea
  },
  { 
    productImage : uppuSeedai,  
    headerText: "10% off on Seedai",
    contentArea: contentArea
  }
];

const items: any = [
  { productImage: mulluMurukku, productName: "Mullu Murukku", productUnit: "1 kg", unitPrice: 400 },
  { productImage: maavurundai, productName: "Maavurundai", productUnit: "5 Pieces", unitPrice: 40 },
  { productImage: uppuSeedai, productName: "Uppu Seedai", productUnit: "1 kg", unitPrice: 400 },
  { productImage: adhirasam, productName: "Adhirasam", productUnit: "5 Pieces", unitPrice: 40 },
  { productImage: splThattai, productName: "Spl Thattai", productUnit: "1 kg", unitPrice: 450 }
];

const cartItems: any = [
  { productName: "Mullu Murukku", productUnit: "1 kg", unitPrice: 400, productQty: 1, qtyPrice: 400 },
  { productName: "Maavurundai", productUnit: "5 Pieces", unitPrice: 40, productQty: 1, qtyPrice: 40 },
  { productName: "Uppu Seedai", productUnit: "1 kg", unitPrice: 400, productQty: 1, qtyPrice: 400 },
  { productName: "Adhirasam", productUnit: "5 Pieces", unitPrice: 40, productQty: 1, qtyPrice: 40 },
  { productName: "Spl Thattai", productUnit: "1 kg", unitPrice: 450, productQty: 1, qtyPrice: 450 }
];

const paymentOptions: Array<any> = [
  { code: "cash", text: "Cash on Delivery", icon: "fas fa-wallet" },
  { code: "card", text: "Credit/Debit Card", icon: "far fa-credit-card" },
];

const deliveryOptions: any = [
  { code: "DoorDelivery", text: "Door Delivery", icon: "fas fa-biking" },
  { code: "TakeAway", text: "Take Away", icon: "fas fa-people-carry" },
];

const summaryItems: any = [
  { productImage: mulluMurukku, productName: "Mullu Murukku", productUnit: "1 kg", unitPrice: 400, productQty: 1, qtyPrice: 400 },
  { productImage: maavurundai, productName: "Maavurundai", productUnit: "5 Pieces", unitPrice: 40, productQty: 1, qtyPrice: 40 },
  { productImage: uppuSeedai, productName: "Uppu Seedai", productUnit: "1 kg", unitPrice: 400, productQty: 1, qtyPrice: 400 },
  { productImage: adhirasam, productName: "Adhirasam", productUnit: "5 Pieces", unitPrice: 40, productQty: 1, qtyPrice: 40 },
  { productImage: splThattai, productName: "Spl Thattai", productUnit: "1 kg", unitPrice: 450, productQty: 1, qtyPrice: 450 }
];

const leftMenu = [
  {icon: "fas fa-user", menuName: "My Profile", route: '/my-profile'},
  {icon: "fas fa-envelope", menuName: "Address", route: '/address-list'},
  {icon: "fas fa-clipboard-list", menuName: "My Orders", route: '/my-orders'}
];

const myOrders = [
  { orderDate: "04/09/21, 08.00 am", orderDisplayId: "#12345", orderAmount: 93.00, paymentMode: "Pending" },
  { orderDate: "01/09/21, 11.00 am", orderDisplayId: "#25634", orderAmount: 115.00, paymentMode: "Paid" },
  { orderDate: "25/08/21, 05.00 pm", orderDisplayId: "#35489", orderAmount: 120.00, paymentMode: "Paid" },
  { orderDate: "13/08/21, 09.00 am", orderDisplayId: "#86432", orderAmount: 150.00, paymentMode: "Paid" },
  { orderDate: "07/08/21, 03.00 pm", orderDisplayId: "#23895", orderAmount: 50.00, paymentMode: "Paid" },
];

const orderDetail: any = [
  { productImage: mulluMurukku, productName: "Mullu Murukku", productUnit: "1 kg", unitPrice: 400, productQty: 1, qtyPrice: 400 },
  { productImage: maavurundai, productName: "Maavurundai", productUnit: "5 Pieces", unitPrice: 40, productQty: 1, qtyPrice: 40 },
  { productImage: uppuSeedai, productName: "Uppu Seedai", productUnit: "1 kg", unitPrice: 400, productQty: 1, qtyPrice: 400 },
  { productImage: adhirasam, productName: "Adhirasam", productUnit: "5 Pieces", unitPrice: 40, productQty: 1, qtyPrice: 40 },
  { productImage: splThattai, productName: "Spl Thattai", productUnit: "1 kg", unitPrice: 450, productQty: 1, qtyPrice: 450 }
];

const filter =  {  
  priceList: [{value: 0, label : 0}, {value: 250, label : 250}, {value: 500, label : 500}, {value: 750, label : 750}, {value: 1000, label : 1000}, {value: 1250, label : 1250}, {value: 1500, label : 1500}]  
};

export const sweetStallData: any = {
  categories: categories,
  offerData: offerData,
  items: items,
  cartItems: cartItems,
  paymentOptions: paymentOptions,
  deliveryOptions: deliveryOptions,
  summaryItems: summaryItems,
  leftMenu: leftMenu,
  myOrders: myOrders,
  orderDetail: orderDetail,
  filter: filter
}