import { AppBar, Box, Button, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./JewelleryHeader.scss";

import { AlertService, useNavigation } from "@fx/components";
import { businessCategoryMap } from "../../businessCategoryMap";
import { useDispatch, useSelector } from "react-redux";
import { localstorage, MockDataService } from "../../../../services";
import Filter from "../../filter/Filter";
import InfoList from "../../info-list/InfoList";
import LeftMenu from "../../left-menu/LeftMenu";
import { JewelleryHeaderMobile } from "./jewellery-header-mobile/JewelleryHeaderMobile";
import useCategories from "../../../providers/catalog/useCategories";
import useCart from "../../../providers/cart/useCart";
import useSecurityContext from "../../../providers/security/useSecurityContext";
import useLogin from "../../../providers/security/useLogin";

import { Breadcrumbs } from "@mui/material";
import { FxPopper } from "../../../fx/FxPopper/FxPopper";
import { CategoryFilter } from "./category-filter/CategoryFilter";
import { JewelleryExclusive } from "../../exclusive-features/jewellery/JewelleryExclusive";
import useWishlist from "../../../providers/wishlist/useWishlist";

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  const customClass = props.customClass || "p-3";
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        //   <Box p={2}>
        <div className={customClass}>
          {children}
        </div>
        //   </Box>
      )}
    </div>
  );
}


export const JewelleryHeader = (props: any) => {
  const mockDataService = MockDataService();
  const helpMenu = mockDataService.getData('helpMenu');
  const sampleCategory = mockDataService.getData('sampleCategory');
  const sampleSubCategory = mockDataService.getData('sampleSubCategory');
  const { categories, categoryMap } = useCategories();
  const { totalItems } = useCart();
  const { isLogin, signOut, getCurrentUser } = useLogin();
  const { wishlistCount } = useWishlist();

  const { navigate, navigateRoot, goTo, clear } = useNavigation();
  const businessCategory: any = localstorage.getItem('businesscategory');

  const headerTitle = businessCategoryMap[businessCategory]?.headerTitle;
  const headerLogo = businessCategoryMap[businessCategory]?.headerLogo;
  const navStack = useSelector<any, any>((s) => (s.navData ? s.navData.navStack : null));
  const [showBreadcrumb, setShowBreadcrumb] = React.useState(false);
  let defaultBreadcrumbsData: any = [];
  const [breadcrumbsData, setBreadcrumbsData] = React.useState(defaultBreadcrumbsData);
  let defaultSubCategories: any = [];
  const [subcategories, setSubcategories] = React.useState(defaultSubCategories);
  const globalDispatch = useDispatch();
  const { refreshMyWishlistCount } = useWishlist();
  const { clearCartItems } = useCart();

  const prepareBreadCrumb = () => {
    console.log('navStack=', navStack)

    let navHead = navStack?.length > 0 ? navStack[navStack.length - 1] : null;
    if (navHead && (navHead.route.indexOf('/product-detail/') > -1 || navHead.route.indexOf('/products') > -1)) {
      setShowBreadcrumb(true);
    } else {
      setShowBreadcrumb(false);
    }
    let result: any = [];
    for (let navItem of navStack) {
      let item = { displayName: navItem.title, route: navItem.route };
      result.push(item);
    }
    setBreadcrumbsData(result);
  }

  useEffect(() => {
    prepareBreadCrumb();
  }, [navStack])

  useEffect(() => {
    getCurrentUser();
    refreshMyWishlistCount();
  }, [])

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route,
      targetTitle: 'Home'
    };
    navigateRoot(navData);
  }

  const navigateToJewelCart = () => {
    let route = '/jewel-cart';
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToLogin = () => {
    let route = "/login";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [leftMenuOpen, setLeftMenuOpen] = React.useState(false);

  const openLeftMenu = (item: any) => {
    setLeftMenuOpen(true);
  }

  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  const openLink = (link: string, openInNewWindow = true) => window.open(link, openInNewWindow ? "_blank" : "_self")

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const categoryMenuOpen = Boolean(anchorEl);
  const openCategoryMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeCategoryMenu = () => {
    setAnchorEl(null);
  };

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const helpMenuOpen = Boolean(anchorE2);
  const openHelpMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const closeHelpMenu = () => {
    setAnchorE2(null);
  };

  const openPage = (item: any) => {
    let navData = {
      targetRoute: item.route
    };
    navigate(navData);
    closeHelpMenu()
  }

  const [headerMenuOpen, setHeaderMenuOpen] = React.useState(false);
  const openHeaderMenu = () => {
    setHeaderMenuOpen(!headerMenuOpen);
  }
  const closeHeaderMenu = () => {
    setHeaderMenuOpen(false);
  }

  const openProducts = (category: any, parentCategory: any = null) => {
    closeCategoryMenu();
    clear();
    const navDataDashboard = {
      route: '/jewel-dashboard',
      title: 'Home'
    };
    globalDispatch({ type: "nav-push", payload: navDataDashboard });

    if (parentCategory) {
      const navDataParentCategory = {
        route: `/products-parent-category/${parentCategory.slug}`,
        title: parentCategory.name
      };
      globalDispatch({ type: "nav-push", payload: navDataParentCategory });
    }

    let navData = {
      targetRoute: `/products/${category.slug}`,
      targetTitle: category.name
    };
    navigate(navData);
  }

  const navigateToProducts = (slug: any) => {
    let navData = {
      targetRoute: `/products/${slug}`,
      targetTitle: 'Products'
    };
    navigate(navData);
  }

  const navToHome = () => {
    let navData = {
      targetRoute: `/jewel-dashboard`,
      targetTitle: 'Home'
    };
    navigateRoot(navData);
  }

  const [anchorE3, setAnchorE3] = React.useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(anchorE3);
  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE3(event.currentTarget);
  };
  const closeUserMenu = () => {
    setAnchorE3(null);
  };

  const navToMyProfile = () => {
    let navData = {
      targetRoute: '/my-profile'
    };
    navigate(navData);
    closeUserMenu();
  }

  const navToMyOrders = () => {
    let navData = {
      targetRoute: '/my-orders'
    };
    navigate(navData);
    closeUserMenu();
  }

  const logout = () => {
    signOut();
    closeUserMenu();
    clearCartItems();
    let navData = {
      targetRoute: '/jewel-dashboard'
    };
    navigate(navData);
  }


  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [anchorE4, setAnchorE4] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorE4(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorE4(null);
  };

  const navToPage = (item: any, index: number) => {
    let navData = {
      targetRoute: item.route,
      targetTitle: item.displayName
    };
    if (index == 0) {
      navigateRoot(navData);
    } else {
      setTimeout(() => {
        goTo(index);
      }, 1000);
    }
  }


  const [firstToolbarDisplay, setFirstToolbarDisplay] = useState("flex")
  const listenFirstScrollEvent = () => {
    window.scrollY > 300
      ? setFirstToolbarDisplay("none")
      : setFirstToolbarDisplay("flex")
  }
  const [secondToolbarDisplay, setSecondToolbarDisplay] = useState("flex")
  const listenSecondScrollEvent = () => {
    window.scrollY > 500
      ? setSecondToolbarDisplay("none")
      : setSecondToolbarDisplay("flex")
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    window.addEventListener("scroll", listenFirstScrollEvent);
    window.addEventListener("scroll", listenSecondScrollEvent);
  })

  const searchClick = () => {
    if (categories?.length > 0) {
      let cat = categories[0];
      let navData = {
        targetRoute: `/products/${cat.slug}`,
        targetTitle: cat.name
      };
      navigate(navData);
    }
  }

  const openWishlist = () => {
    if (isLogin) {
      let navData = {
        targetRoute: `/wishlist`,
        targetTitle: 'Wishlist'
      };
      navigate(navData);
    } else {
      AlertService.showErrorMsg('Please login to view your wishlist!');
      let navData = {
        targetRoute: '/login'
      };
      navigate(navData);
    }
  }

  return (
    <div className="jewellery-header">
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ display: firstToolbarDisplay, justifyContent: "space-between", minHeight: { xs: 40, md: 40 } }}>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: { xs: "100%", md: "80%" } }}>
            <Box sx={{ fontSize: "17px", display: "flex" }}>
              <Box sx={{ fontFamily: "sans-serif", fontSize: "17px" }}>+</Box>
              <Box sx={{ fontFamily: "ForNumbers" }}>91-915-0456745</Box>
            </Box>
            <Box sx={{ display: "flex", fontSize: "17px" }}>
              <Box sx={{ cursor: "pointer" }} onClick={() => openLink('https://www.facebook.com/chennaijewel/')}>
                <i className="fab fa-facebook-f"></i>
              </Box>
              <Box sx={{ ml: 3, cursor: "pointer" }} onClick={() => openLink('https://www.instagram.com/chennai_jewels/')}>
                <i className="fab fa-instagram" ></i>
              </Box>
              <Box sx={{ ml: 3, cursor: "pointer" }} onClick={() => openLink('https://web.whatsapp.com/send?phone=+919150456745')}>
                <i className="fab fa-whatsapp"></i>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
        </Toolbar>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#fff", color: "#000", minHeight: { xs: 100, md: 100 } }}>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: { xs: "100%", md: "80%" } }}>
            <Box sx={{ width: "25%" }}>
              <Box component="img" sx={{ height: { xs: "88px", xl: "100px" }, display: "flex", alignItems: "center" }} src={headerLogo} className="pointer" onClick={navigateToDashboard} >
              </Box>
            </Box>
            <Box sx={{ px: 2, display: { xs: "none", md: "flex" }, justifyContent: "space-around", alignItems: "center", width: "50%", fontWeight: 600, fontSize: "18px" }}>
              <Box>
                <Button sx={{
                  fontSize: "18px", fontFamily: "JewelFont", fontWeight: 600, textTransform: "none", color: "#5d5a5a", borderBottom: "2px solid", borderColor: "secondary.main",
                  "&:hover": { color: "primary.main", borderBottom: "2px solid", borderColor: "primary.main" }
                }} onClick={navToHome}>
                  Home
                </Button>
              </Box>
              <Box>
                <CategoryFilter openProducts={openProducts} />
              </Box>
              <Box><Button sx={{
                fontSize: "18px", fontFamily: "JewelFont", fontWeight: 600, textTransform: "none", color: "#5d5a5a", borderBottom: "2px solid", borderColor: "secondary.main",
                "&:hover": { color: "primary.main", borderBottom: "2px solid", borderColor: "primary.main" }
              }} onClick={()=>navigateToProducts('designer-signature')}>Designer Signature</Button></Box>
              <Box>
                <Button
                  id="help-button"
                  aria-controls={helpMenuOpen ? 'help-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={helpMenuOpen ? 'true' : undefined}
                  onMouseEnter={openHelpMenu}
                  onMouseOver={openHelpMenu}
                  onClick={openHelpMenu}
                  sx={{
                    fontSize: "18px", fontFamily: "JewelFont", fontWeight: 600, textTransform: "none", color: "#5d5a5a", borderBottom: "2px solid", borderColor: "secondary.main",
                    "&:hover": { color: "primary.main", borderBottom: "2px solid", borderColor: "primary.main" }
                  }}
                >
                  Help
                </Button>
              </Box>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", ml: "auto", mr: 0, cursor: "pointer" }} onClick={openHeaderMenu}>
              <i className="fas fa-bars"></i>
            </Box>

            <Menu
              id="category-menu"
              anchorEl={anchorEl}
              open={categoryMenuOpen}
              onClose={closeCategoryMenu}
              onMouseLeave={closeCategoryMenu}
              MenuListProps={{
                'aria-labelledby': 'category-button',
              }}
            >
              {categories && categories.map((cat: any, i: any) => (
                <MenuItem sx={{ color: "primary.main" }} onClick={() => openProducts(cat)}>{cat.name}</MenuItem>
              ))
              }
            </Menu>

            <Menu
              id="help-menu"
              anchorEl={anchorE2}
              open={helpMenuOpen}
              onClose={closeHelpMenu}
              onMouseLeave={closeHelpMenu}
              MenuListProps={{
                'aria-labelledby': 'help-button',
              }}
              sx={{ mt: 3 }}
            >
              {helpMenu.length > 0 && helpMenu.map((item: any, i: any) => (
                <MenuItem sx={{ color: "primary.main" }} onClick={() => openPage(item)}>{item.menuName}</MenuItem>
              ))
              }
            </Menu>

            <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", fontFamily: "JewelFont", fontWeight: 600, alignItems: "center", width: "25%", fontSize: "18px", color: "#5D687A" }}>
              <Button sx={{ fontSize: "18px", fontFamily: "JewelFont", fontWeight: 500, textTransform: "none", color: "#5d5a5a" }} onClick={searchClick}>
                <Box><i className="fas fa-search"></i></Box>
                <Box sx={{ pl: 1 }}>Search</Box>
              </Button>
              {/* <Box sx={{ display: "flex", cursor: "pointer" }}>
                <Box><i className="fas fa-search"></i></Box>
                <Box sx={{ pl: 1 }}>Search</Box>
              </Box> */}
              {!isLogin &&
                <Button sx={{ fontSize: "18px", fontFamily: "JewelFont", fontWeight: 500, textTransform: "none", color: "#5d5a5a" }} onClick={navigateToLogin}>
                  Login
                </Button>
                // <Box sx={{ cursor: "pointer" }} onClick={navigateToLogin}>Login</Box>
              }

              {isLogin &&
                <Button
                  id="user-button"
                  aria-controls={userMenuOpen ? 'user-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={userMenuOpen ? 'true' : undefined}
                  onClick={openUserMenu}
                  sx={{ fontSize: "18px", fontFamily: "JewelFont", fontWeight: 500, textTransform: "none", color: "#5d5a5a" }}
                >
                  <i className="fas fa-user-circle"></i>
                </Button>
              }
              <Menu
                id="user-menu"
                anchorEl={anchorE3}
                open={userMenuOpen}
                onClose={closeUserMenu}
                MenuListProps={{
                  'aria-labelledby': 'user-button',
                }}
                sx={{ mt: 4 }}
              >
                <MenuItem sx={{ color: "primary.main" }} onClick={navToMyProfile}>My Profile</MenuItem>
                <MenuItem sx={{ color: "primary.main" }} onClick={navToMyOrders}>My Orders</MenuItem>
                <MenuItem sx={{ color: "primary.main" }} onClick={logout}>Logout</MenuItem>
              </Menu>

              <Button sx={{
                fontSize: "18px", minWidth: "30px", fontFamily: "JewelFont",
                fontWeight: 500, textTransform: "none", color: "#5d5a5a", px: 0
              }}
                onClick={openWishlist}>
                <Box><i className="far fa-heart"></i></Box>
                <Box sx={{ pl: 1 }}>{wishlistCount}</Box>
              </Button>
              <Button onClick={navigateToJewelCart} sx={{ fontSize: "18px", fontFamily: "JewelFont", fontWeight: 500, textTransform: "none", color: "#5d5a5a", pr: 0 }}>
                <Box><i className="fas fa-shopping-bag"></i></Box>
                <Box sx={{ pl: 1 }}>{totalItems}</Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
        </Toolbar>
        <Toolbar sx={{ justifyContent: "space-between", display: { xs: "none", md: secondToolbarDisplay }, backgroundColor: "#C95CC7", minHeight: { xs: 45, md: 45 } }}>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
          <Box sx={{ width: { xs: "100%", md: "80%" } }}>
            <Box sx={{ width: "100%" }}>
              <JewelleryExclusive />
            </Box>
          </Box>
          <Box sx={{ width: { xs: "0%", md: "10%" } }}></Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={headerMenuOpen}
        onClose={closeHeaderMenu}
        PaperProps={{ sx: { width: "100%" } }}
      >
        <JewelleryHeaderMobile closeAction={closeHeaderMenu} />
      </Drawer>
      <Drawer
        anchor="right"
        open={leftMenuOpen}
        onClose={closeLeftMenu}
        PaperProps={{
          sx: { width: { xs: "60%", sm: "40%", md: "20%" } },
        }}>
        <LeftMenu closeAction={closeLeftMenu} />
      </Drawer>
    </div>
  );
}
