import React from 'react';
import { CircularProgress, createStyles, Theme } from '@mui/material';
import {makeStyles} from "@mui/styles";

const useProgressStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      animationDuration: '400ms',
      position: 'absolute',
      left: 650,
      top: 300,
      zIndex: 9999
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

export const FxProgress = (props: any) => {
  const classes = useProgressStyles();
  return (
    <CircularProgress
      variant="indeterminate"
      disableShrink
      className={classes.top}
      classes={{
        circle: classes.circle,
      }}
      size={35}
      thickness={3}
      {...props} />
  );
}
