export const jewelleryTemplates = {
  product: `<JewelleryProduct data={data} actions={actions}/>`,
  productDetail: `<JewelleryProductDetail data={data} actions={actions} />`,
  cart: `<div className="cart-card">
  <Card className="pointer" sx={{ borderRadius: "4px", px: "15px", py: 2 }} > 
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={3}>
        <Box component="img" src={data.item.item.productImage} sx={{width: "70px", height: "70px" }} />
      </Grid>
      <Grid item xs={6}>
        <Box className="product-name">
          {data.item.item.name}
        </Box>
        <Box sx={{display: "flex", alignItems: "center", pt: 1}}>
          <Box sx={{width: "90px", border: "1px solid #CCCCCC", py: 1, px: 2, display: "flex", justifyContent: "space-between", fontSize: 15}}>
            <CardIcon action={() => actions.minusQuantity(data.item.item)} item={data.item} icon="fas fa-minus" sx={{color: '#989898' }}/> 
            <Box>{data.item.quantity}</Box>
            <CardIcon action={() => actions.addQuantity(data.item.item)} item={data.item} icon="fas fa-plus" sx={{color: '#989898' }}/>            
          </Box>
          <Box sx={{backgroundColor: "primary.main", color: "secondary.main", py: 1, px: "10px", ml: 1}}>
            <i className="far fa-trash-alt"></i>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <div className="font-15 text-right"><FxCurrency value={data.item.lineTotal} /></div>
      </Grid>
    </Grid>
  </Card>
</div>`,
  paymentOptions: `<FxButtonGroup action={actions.handleChange} selected={data.paymentType} item={data.item}/>`,
  deliveryOptions: `<FxButtonGroup action={actions.handleChange} selected={data.deliveryType} item={data.item}/>`,
  deliveryAddress: `<div className="delivery-address">
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
      <div className="font-weight-medium font-16">Delivery Address</div>
      <Box sx={{color: "secondary.main"}}><i className="fas fa-pen font-15"></i></Box>
    </Box>

    {/* If no address selected */}
    {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div className="font-17">No Address Selected</div>
      <div><FxButton label="Select" variant="contained" size="small" className="btn-style" /></div>
    </Box> */}

    <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
      <div>Karthik</div>
      <div>North Street</div>
      <div>Chennai</div>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
      <div className="font-weight-medium font-16">Phone Number</div>
      <Box sx={{color: "secondary.main"}}><i className="fas fa-pen font-15"></i></Box>
    </Box>
    <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
      <div>9012345678</div>
    </Box>
  </Card>
</div>`,
  deliverySlot: `<Grid item xs={6} sm={3}>
      <Box
          sx={{
            backgroundColor: "#EDEDED",
            border: "1px solid #ededed",
            borderRadius: "20px",
            paddingRight: "10px",
            paddingLeft: "10px",
            paddingTop: "5px",
            paddingBottom: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
        {data.item}
      </Box>
    </Grid>`,
  billDetails: `<div className="delivery-address">
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex" }} className="pb-2">
      <div className="font-weight-medium font-16">Bill Details</div>         
    </Box>
    <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
      <div>Subtotal</div>
      <div><FxCurrency value={data.item.totalAmount} /></div>
    </Box>
    <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="font-15 pb-1">
      <div>Delivery charge</div>
      <Box className="font-12">Free Delivery</Box>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="font-15 pb-1 font-weight-medium">
      <div>Grand Total</div>
      <div><FxCurrency value={data.item.totalAmount} /></div>
    </Box>
  </Card>
</div>`,
  couponCard: `<div className="coupon-card">
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex" }} className="pb-2">
      <div><img src={data.item.icon} width="23px" /></div>
      <div className="font-weight-medium font-16 pl-2">Apply Coupon</div>
    </Box>
    <Box className="pb-3">
      <div>Use coupon code to avail 10% offer for your order</div>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <div>
        <FxInput name="couponCode" value={data.item.couponCode} variant="outlined" size="small" />
      </div>
      <div className="pl-3">
        <FxButton label="Select" variant="contained" size="small" sx={{
          height: "30px",
          textTransform: "none",
          fontWeight: "600"
        }} />
      </div>
    </Box>
  </Card>
</div>`,
  summary: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
  <Grid container direction="row" alignItems="center" spacing={1}>
    <Grid item xs={3}>
      <Box component="img" src={data.item.productImage} sx={{width: "50px",
        height: "50px",
        borderRadius: "4px"}} />
    </Grid>
    <Grid item xs={5}>
      <div>{data.item.name}</div>
      <div><FxCurrency value={data.item.unitPrice} /></div>
    </Grid>
    <Grid item xs={1} className="text-right">{data.item.quantity}</Grid>
    <Grid item xs={3} className="text-right"><FxCurrency value={data.item.lineTotalPrice} /></Grid>
  </Grid>
  </Box>`,
  myOrders: `<MyordersCard data={data} actions={actions} />`,
  orderDetail: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
<Grid container direction="row" alignItems="center" spacing={1}>
  <Grid item xs={3}>
    <Box component="img" src={data.item.productImage} sx={{width: "50px",
      height: "50px",
      borderRadius: "4px"}} />
  </Grid>
  <Grid item xs={5}>
    <div>{data.item.name}</div>
    <div><FxCurrency value={data.item.unitPrice} /></div>
  </Grid>
  <Grid item xs={1} className="text-right">{data.item.quantity}</Grid>
  <Grid item xs={3} className="text-right"><FxCurrency value={data.item.lineTotalPrice} /></Grid>
</Grid>
</Box>`
}