import mustard from "../../../../assets/grocery/mustard.png";
import cumin from "../../../../assets/grocery/cumin.png";
import fennel from "../../../../assets/grocery/fennel.png";
import fenugreek from "../../../../assets/grocery/fenugreek.png";
import pepper from "../../../../assets/grocery/pepper.png";

import pulses from "../../../../assets/grocery/pulses.png";
import spices from "../../../../assets/grocery/spices.png";
import powder from "../../../../assets/grocery/powder.png";
import oil from "../../../../assets/grocery/oil.png";
import chocolates from "../../../../assets/grocery/chocolates.png";
import noodles from "../../../../assets/grocery/noodles.png";
import biscuits from "../../../../assets/grocery/biscuits.png";
import milk from "../../../../assets/grocery/milk.png";
import dairyProducts from "../../../../assets/grocery/dairyProducts.png";
import vegetables from "../../../../assets/grocery/vegetables.png";
import fruits from "../../../../assets/grocery/fruits.png";
import breadegg from "../../../../assets/grocery/breadegg.png";
import snacks from "../../../../assets/grocery/snacks.png";
import dryfruits from "../../../../assets/grocery/dryfruits.png";
import beverages from "../../../../assets/grocery/beverages.png";
import pooja from "../../../../assets/grocery/pooja.png";
import offerimg from "../../../../assets/grocery/offerimg.png";
import contentArea from "../../../../assets/grocery/contentArea.png"

const categories: any = [
  { categoryImage: pulses, categoryName: "Pulses" },
  { categoryImage: spices, categoryName: "Spices" },
  { categoryImage: powder, categoryName: "Powder" },
  { categoryImage: oil, categoryName: "Oil" },
  { categoryImage: chocolates, categoryName: "Chocolates" },
  { categoryImage: noodles, categoryName: "Noodles" },
  { categoryImage: biscuits, categoryName: "Biscuits" },
  { categoryImage: milk, categoryName: "Milk" },
  { categoryImage: dairyProducts, categoryName: "Dairy Products" },
  { categoryImage: vegetables, categoryName: "Vegetables" },
  { categoryImage: fruits, categoryName: "Fruits" },
  { categoryImage: breadegg, categoryName: "Bread & Egg" },
  { categoryImage: snacks, categoryName: "Snacks" },
  { categoryImage: dryfruits, categoryName: "Dry Fruits" },
  { categoryImage: beverages, categoryName: "Beverages" },
  { categoryImage: pooja, categoryName: "Pooja Items" }
];

const offerData: any = [
  { 
    productImage :  pulses,  
    headerText: "10% off on Pulses",
    contentArea : contentArea
  },
  { 
    productImage : dairyProducts,  
    headerText: "5% off on Dairy products",
    contentArea: contentArea
  },
  { 
    productImage : vegetables,  
    headerText: "10% off on Vegetables", 
    contentArea: contentArea
  }
];

const items: any = [
  { productImage: mustard, productName: "Mustard Seeds", productUnit1: "1000g", unit1Price: 100, productUnit2: "500g", unit2Price: 50, productUnit3: "250g", unit3Price: 25 },
  { productImage: cumin, productName: "Cumin Seeds", productUnit1: "1000g", unit1Price: 240, productUnit2: "500g", unit2Price: 120, productUnit3: "250g", unit3Price: 60 },
  { productImage: fennel, productName: "Fennel Seeds", productUnit1: "1000g", unit1Price: 160, productUnit2: "500g", unit2Price: 80, productUnit3: "250g", unit3Price: 40 },
  { productImage: fenugreek, productName: "Fenugreek Seeds", productUnit1: "1000g", unit1Price: 100, productUnit2: "500g", unit2Price: 50, productUnit3: "250g", unit3Price: 25 },
  { productImage: pepper, productName: "Black Pepper", productUnit1: "1000g", unit1Price: 500, productUnit2: "500g", unit2Price: 250, productUnit3: "250g", unit3Price: 125 }
];

const cartItems: any = [
  { productName: "Mustard Seeds", productUnit: "1000g", unitPrice: 100, productQty: 1, qtyPrice: 100 },
  { productName: "Cumin Seeds", productUnit: "1000g", unitPrice: 240, productQty: 1, qtyPrice: 240 },
  { productName: "Fennel Seeds", productUnit: "1000g", unitPrice: 160, productQty: 1, qtyPrice: 160 },
  { productName: "Fenugreek Seeds", productUnit: "1000g", unitPrice: 100, productQty: 1, qtyPrice: 100 },
  { productName: "Black Pepper", productUnit: "1000g", unitPrice: 500, productQty: 1, qtyPrice: 500 }
];

const paymentOptions: Array<any> = [
  { code: "cash", text: "Cash on Delivery", icon: "fas fa-wallet" },
  { code: "card", text: "Credit/Debit Card", icon: "far fa-credit-card" },
];

const deliveryOptions: any = [
  { code: "DoorDelivery", text: "Door Delivery", icon: "fas fa-biking" },
  { code: "TakeAway", text: "Take Away", icon: "fas fa-people-carry" },
];

const summaryItems = [
  { productImage: mustard, productName: "Mustard Seeds", productUnit: "1000g", unitPrice: 100, productQty: 1, qtyPrice: 100 },
  { productImage: cumin, productName: "Cumin Seeds", productUnit: "1000g", unitPrice: 240, productQty: 1, qtyPrice: 240 },
  { productImage: fennel, productName: "Fennel Seeds", productUnit: "1000g", unitPrice: 160, productQty: 1, qtyPrice: 160 },
  { productImage: fenugreek, productName: "Fenugreek Seeds", productUnit: "1000g", unitPrice: 100, productQty: 1, qtyPrice: 100 },
  { productImage: pepper, productName: "Black Pepper", productUnit: "1000g", unitPrice: 500, productQty: 1, qtyPrice: 500 }
];

const leftMenu = [
  {icon: "fas fa-user", menuName: "My Profile", route: '/my-profile'},
  {icon: "fas fa-envelope", menuName: "Address", route: '/address-list'},
  {icon: "fas fa-clipboard-list", menuName: "My Orders", route: '/my-orders'}
];

const myOrders = [
  { orderDate: "04/09/21, 08.00 am", orderDisplayId: "#12345", orderAmount: 93.00, paymentMode: "Pending" },
  { orderDate: "01/09/21, 11.00 am", orderDisplayId: "#25634", orderAmount: 115.00, paymentMode: "Paid" },
  { orderDate: "25/08/21, 05.00 pm", orderDisplayId: "#35489", orderAmount: 120.00, paymentMode: "Paid" },
  { orderDate: "13/08/21, 09.00 am", orderDisplayId: "#86432", orderAmount: 150.00, paymentMode: "Paid" },
  { orderDate: "07/08/21, 03.00 pm", orderDisplayId: "#23895", orderAmount: 50.00, paymentMode: "Paid" },
];

const orderDetail = [
  { productImage: mustard, productName: "Mustard Seeds", productUnit: "1000g", unitPrice: 100, productQty: 1, qtyPrice: 100 },
  { productImage: cumin, productName: "Cumin Seeds", productUnit: "1000g", unitPrice: 240, productQty: 1, qtyPrice: 240 },
  { productImage: fennel, productName: "Fennel Seeds", productUnit: "1000g", unitPrice: 160, productQty: 1, qtyPrice: 160 },
  { productImage: fenugreek, productName: "Fenugreek Seeds", productUnit: "1000g", unitPrice: 100, productQty: 1, qtyPrice: 100 },
  { productImage: pepper, productName: "Black Pepper", productUnit: "1000g", unitPrice: 500, productQty: 1, qtyPrice: 500 }
];

const filter =  {  
  priceList: [{value: 0, label : 0}, {value: 250, label : 250}, {value: 500, label : 500}, {value: 750, label : 750}, {value: 1000, label : 1000}, {value: 1250, label : 1250}, {value: 1500, label : 1500}]  
};

export const groceryData: any = {
  categories: categories,
  offerData: offerData,
  items: items,
  cartItems: cartItems,
  paymentOptions: paymentOptions,
  deliveryOptions: deliveryOptions,
  summaryItems: summaryItems,
  leftMenu: leftMenu,
  myOrders: myOrders,
  orderDetail: orderDetail,
  filter: filter
}
