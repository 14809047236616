import garlicbread from "../../../../assets/restaurant/garlicbread.jpg";
import porkribs from "../../../../assets/restaurant/porkribs.jpg";
import mussels from "../../../../assets/restaurant/mussels.jpg";
import chickenwings from "../../../../assets/restaurant/chickenwings.jpg";
import pansearedsalmon from "../../../../assets/restaurant/pansearedsalmon.jpg";
import lambshanks from "../../../../assets/restaurant/lambshanks.jpg";
import beefburger from "../../../../assets/restaurant/beefburger.jpg";
import chuckburger from "../../../../assets/restaurant/chuckburger.jpg";
import fishandchips from "../../../../assets/restaurant/fishandchips.jpg";
import smokedchickenbriepizza from "../../../../assets/restaurant/smokedchickenbriepizza.jpg";
import tandoorichickenpizza from "../../../../assets/restaurant/tandoorichickenpizza.jpg";

const categoryitems: any = [
  {
    categoryName: "DIG INTO THE APPETIZERS",
    items: [
      { productImage: garlicbread, productName: "Garlic Bread", unitPrice: 10.00 },
      { productImage: porkribs, productName: "Pork Ribs", unitPrice: 13.50 },
      { productImage: mussels, productName: "Mussels", unitPrice: 13.50 },
      { productImage: chickenwings, productName: "Chicken Wings", unitPrice: 13.50 }
    ]
  },
  {
    categoryName: "GOURMET MAIN COURSE",
    items: [
      { productImage: pansearedsalmon, productName: "Pan Sea Red Salmon", unitPrice: 29.50 },
      { productImage: lambshanks, productName: "Lamb Shanks", unitPrice: 29.50 }
    ]
  },
  {
    categoryName: "BURGERS",
    items: [
      { productImage: beefburger, productName: "Beef Burger", unitPrice: 17.50 },
      { productImage: chuckburger, productName: "Chuck Burger", unitPrice: 17.50 }
    ]
  },
  {
    categoryName: "FISH AND CHIPS",
    items: [
      { productImage: fishandchips, productName: "Fish and Chips", unitPrice: 17.50 }
    ]
  },
  {
    categoryName: "PIZZAS",
    items: [
      { productImage: smokedchickenbriepizza, productName: "Smoked Chicken and Brie Pizza", unitPrice: 10.50 },
      { productImage: tandoorichickenpizza, productName: "Tandoori Chicken Pizza", unitPrice: 10.50 }
    ]
  }
];

const cartItems = [
  { productName: "Chicken Wings", unitPrice: 13.50, productQty: 1, qtyPrice: 13.50 },
  { productName: "Smoked Chicken and Brie Pizza", unitPrice: 10.50, productQty: 1, qtyPrice: 10.50 },
  { productName: "Tandoori Chicken Pizza", unitPrice: 10.50, productQty: 1, qtyPrice: 10.50 },
  { productName: "Pan Sea Red Salmon", unitPrice: 29.50, productQty: 1, qtyPrice: 29.50 },
  { productName: "Fish and Chips", unitPrice: 17.50, productQty: 1, qtyPrice: 17.50 }
];

const deliveryOptions: any = [
  { code: "DoorDelivery", text: "Door Delivery", icon: "fas fa-biking" },
  { code: "TakeAway", text: "Take Away", icon: "fas fa-people-carry" },
];

const paymentOptions: any = [
  { code: "cash", text: "Cash on Delivery", icon: "fas fa-wallet" },
  { code: "card", text: "Credit/Debit Card", icon: "far fa-credit-card" },
];

const summaryItems = [
  { productImage: chickenwings, productName: "Chicken Wings", unitPrice: 13.50, productQty: 1, qtyPrice: 13.50 },
  { productImage: smokedchickenbriepizza, productName: "Smoked Chicken and Brie Pizza", unitPrice: 10.50, productQty: 1, qtyPrice: 10.50 },
  { productImage: tandoorichickenpizza, productName: "Tandoori Chicken Pizza", unitPrice: 10.50, productQty: 1, qtyPrice: 10.50 },
  { productImage: pansearedsalmon, productName: "Pan Sea Red Salmon", unitPrice: 29.50, productQty: 1, qtyPrice: 29.50 },
  { productImage: fishandchips, productName: "Fish and Chips", unitPrice: 17.50, productQty: 1, qtyPrice: 17.50 }
];

const leftMenu = [
  {icon: "fas fa-user", menuName: "My Profile", route: '/my-profile'},
  {icon: "fas fa-envelope", menuName: "Address", route: '/address-list'},
  {icon: "fas fa-clipboard-list", menuName: "My Orders", route: '/my-orders'}
];

const myOrders = [
  { orderDate: "04/09/21, 08.00 am", orderDisplayId: "#12345", orderAmount: 93.00, paymentMode: "Pending" },
  { orderDate: "01/09/21, 11.00 am", orderDisplayId: "#25634", orderAmount: 115.00, paymentMode: "Paid" },
  { orderDate: "25/08/21, 05.00 pm", orderDisplayId: "#35489", orderAmount: 120.00, paymentMode: "Paid" },
  { orderDate: "13/08/21, 09.00 am", orderDisplayId: "#86432", orderAmount: 150.00, paymentMode: "Paid" },
  { orderDate: "07/08/21, 03.00 pm", orderDisplayId: "#23895", orderAmount: 50.00, paymentMode: "Paid" },
];

const orderDetail = [
  { productImage: chickenwings, productName: "Chicken Wings", unitPrice: 13.50, productQty: 1, qtyPrice: 13.50 },
  { productImage: smokedchickenbriepizza, productName: "Smoked Chicken and Brie Pizza", unitPrice: 10.50, productQty: 1, qtyPrice: 10.50 },
  { productImage: tandoorichickenpizza, productName: "Tandoori Chicken Pizza", unitPrice: 10.50, productQty: 1, qtyPrice: 10.50 },
  { productImage: pansearedsalmon, productName: "Pan Sea Red Salmon", unitPrice: 29.50, productQty: 1, qtyPrice: 29.50 },
  { productImage: fishandchips, productName: "Fish and Chips", unitPrice: 17.50, productQty: 1, qtyPrice: 17.50 }
];

const filter = {
  priceList: [{ value: 0, label: 0 }, { value: 250, label: 250 }, { value: 500, label: 500 }, { value: 750, label: 750 }, { value: 1000, label: 1000 }, { value: 1250, label: 1250 }, { value: 1500, label: 1500 }]
};

export const restaurantData: any = { 
  categoryitems: categoryitems, 
  cartItems: cartItems,
  paymentOptions: paymentOptions,
  deliveryOptions: deliveryOptions,
  summaryItems: summaryItems,
  leftMenu: leftMenu,
  myOrders: myOrders,
  orderDetail: orderDetail,
  filter: filter
}