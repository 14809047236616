import React from "react"

export const AllRoutes: any = [
  {
    path: '/login',
    component: React.lazy(() => import("./components/generic/login/Login"))
  },
  {
    path: '/register',
    component: React.lazy(() => import("./components/generic/register/Register"))
  },
  {
    path: '/forgotpassword',
    component: React.lazy(() => import("./components/generic/forgot-password/ForgotPassword"))
  },
  {
    path: '/signed-in',
    component: React.lazy(() => import("./components/generic/signed-in/SignedIn"))
  },
  {
    path: '/dashboard',
    component: React.lazy(() => import("./components/generic/dashboard/Dashboard"))
  },
  {
    path: '/products/:slug',
    component: React.lazy(() => import("./components/generic/products/Products"))
  },
  {
    path: '/products-parent-category/:slug',
    component: React.lazy(() => import("./components/generic/products/Products"))
  },
  {
    path: '/cart',
    component: React.lazy(() => import("./components/generic/cart/Cart"))
  },
  {
    path: '/order-confirm',
    component: React.lazy(() => import("./components/generic/order-confirmation/OrderConfirmation"))
  },
  {
    path: '/order-summary/:id',
    component: React.lazy(() => import("./components/generic/order-summary/OrderSummary"))
  },
  {
    path: '/address-list',
    component: React.lazy(() => import("./components/generic/address-list/AddressList"))
  },
  {
    path: '/my-profile',
    component: React.lazy(() => import("./components/generic/my-profile/MyProfile"))
  },
  {
    path: '/my-orders',
    component: React.lazy(() => import("./components/generic/my-orders/MyOrders"))
  },
  {
    path: '/order-detail/:id/:transactionStatus?',
    component: React.lazy(() => import("./components/generic/order-detail/OrderDetail"))
  },
  {
    path: '/jewel-returns',
    component: React.lazy(() => import("./components/generic/return-policy/jewellery/JewelleryReurns"))
  },
  {
    path: '/jewel-delivery',
    component: React.lazy(() => import("./components/generic/delivery-information/jewellery/JewelleryDelivery"))
  },
  {
    path: '/jewel-faq',
    component: React.lazy(() => import("./components/generic/faq/jewellery/JewelleryFaq"))
  },
  {
    path: '/jewel-about',
    component: React.lazy(() => import("./components/generic/about/jewellery/JewelleryAbout"))
  },
  {
    path: '/jewel-contact',
    component: React.lazy(() => import("./components/generic/contact-us/jewellery/JewelleryContact"))
  },
  {
    path: '/jewel-dashboard',
    component: React.lazy(() => import("./components/generic/dashboard/jewellery/JewelleryLandingPage"))
  },
  {
    path: '/jewel-privacy-policy',
    component: React.lazy(() => import("./components/generic/privacy-policy/jewellery/JewelleryPrivacyPolicy"))
  },
  {
    path: '/jewel-terms-and-conditions',
    component: React.lazy(() => import("./components/generic/terms-and-conditions/jewellery/JewelleryTermsAndConditions"))
  },
  {
    path: '/product-detail/:id/:parent?',
    component: React.lazy(() => import("./components/generic/product-detail/ProductDetail"))
  },
  {
    path: '/bookingrequest',
    component: React.lazy(() => import("./components/generic/booking/booking-request/BookingRequest"))
  },
  {
    path: '/jewel-cart',
    component: React.lazy(() => import("./components/generic/cart/jewellery-cart/JewelleryCart"))
  },
  {
    path: '/jewel-order-confirm',
    component: React.lazy(() => import("./components/generic/order-confirmation/jewellery-order-confirmation/JewelleryOrderConfirmation"))
  },
  {
    path: '/wishlist',
    component: React.lazy(() => import("./components/generic/wishlist/Wishlist"))
  },
]
