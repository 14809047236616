import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const OrderService = () => {

  const placeOrder = async (inputData: any) => {
    let apiOptions: any = {
      url: 'orders/place',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getOrders = async (inputData: any) => {
    let apiOptions: any = {
      url: `my/orders/query`,
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const getOrderById = async (inputData: any) => {
    let apiOptions: any = {
      url: `orders/${inputData.orderId}/details`,
      method: 'get'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  const initiatePayment = async (inputData: any) => {
    let apiOptions: any = {
      url: `orders/${inputData.orderId}/pay`,
      method: 'get'
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { placeOrder, getOrders, getOrderById, initiatePayment };
}