import _ from "lodash";
import { useEffect, useState } from "react";
import { useREST } from "../../../helpers/useREST";
import { localstorage, MockDataService, ProductService } from "../../../services";
import useCartContext from "./useCartContext";

export default function useCart(productId: any = "") {
    const { addQuantity, minusQuantity, deleteProduct, items, clearCart,
        currentProductQuantity, computeCurrentProductQuantity, totalAmount, totalItems } = useCartContext();

    const addItem = (item: any, quantity: any = 1) => {
        const options = { productId: item._id, quantity, item };
        addQuantity(options)
    }

    const minusItem = (item: any, quantity: any = 1) => {
        const options = { productId: item._id, quantity };
        minusQuantity(options)
    }

    const deleteItem = (item: any) => {
        const options = { productId: item._id, item };
        deleteProduct(options)
    }

    const clearCartItems = () => {
        clearCart();
    }

    useEffect(() => {
        computeCurrentProductQuantity(productId);
    }, [productId]);

    return { items, addItem, minusItem, deleteItem, currentProductQuantity, totalAmount, totalItems, clearCartItems };
}
