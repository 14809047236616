import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";


type CatalogContextType = {
  setItems: (options: any) => void,
  setItem: (options: any) => void,
  setCategories: (options: any) => void,
  setCategory: (options: any) => void,
  items: any,
  item: any;
  categories: any,
  category: any,
};

export const CatalogContext = createContext<CatalogContextType>(null!);
export const CatalogProvider: React.FC = ({ children }) => {

  const [items, setItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [item, setItem] = useState<any[]>([]);


  return (
    <CatalogContext.Provider
      value={{
        setItems,
        setItem,
        setCategories,
        setCategory,
        items,
        item,
        categories,
        category
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};
