import { Box, Card } from "@mui/material";
import React from "react";
import { FxCurrency } from "../../../FxCurrency/FxCurrency";

export const HoneybeeCraftzProduct = ({ data, actions }: any) => {

  return (
    <div>
      <Card sx={{ borderRadius: "10px", height: "370px", px: 2, mx: 2, pt: 3 }} >
        <Box sx={{ display: "flex", justifyContent: "space-between"}} onClick={() => actions.productDetailAction(data.item)}>
          <Box component="img" src={data.item.productImage} sx={{ height: "300px", width: "300px" }} ></Box>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Box sx={{color: "#FF0202", textAlign: "right"}}><i className="far fa-heart font-26"></i></Box>
            <Box>
              <div><i className="fas fa-plus-circle font-26"></i></div>
              <div className="font-26 py-3 text-center">0</div>
              <div><i className="fas fa-minus-circle font-26"></i></div>
              
            </Box>
          </Box>        
        </Box>
        <Box>      
            <div className="font-16 font-weight-medium pt-2">{data.item.productName}</div>            
            <div className="font-15 pt-1"><FxCurrency value={data.item.unitPrice} /></div>
            {/* <div className="pt-2">
            <FxButton label="Add" variant="contained" size="small" 
              sx={{height: "25px", backgroundColor: "#3f3f3f", color: "#ffffff", textTransform: "none", fontWeight: 600}}
            />
            </div>  */} 
        </Box>
      </Card>
    </div>
  );
}