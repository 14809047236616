import { useNavigation } from "@fx/components";
import { Box, Card, Grid } from "@mui/material";
import React, { useEffect } from "react";
import "./LandingPage.scss";
import sweetStall from "../../assets/images/sweetStall.jpg";
import maligaiKadai from "../../assets/images/maligaiKadai.jpg";
import liquorCentre from "../../assets/images/liquorCentre.jpg";
import restaurant from "../../assets/images/restaurant.jpg";
import honeybee from "../../assets/images/honeybee.jpg";
import jewellery from "../../assets/images/jewellery.png";
import { useDispatch } from 'react-redux';

const LandingPage = (props: any) => {
  const { navigate } = useNavigation();
  const globalDispatch = useDispatch();
  const reactAppMode = process.env.REACT_APP_MODE;
  const routeMap: any = {
    "sweetStall" : "/dashboard",
    "grocery" : "/dashboard",
    "liquor" : "/dashboard",
    "restaurant" : "/products",
    "honeybee" : "/products",
    "jewellery": "/jewel-dashboard",
    "booking": "/bookingrequest"
  }
  const navigateToHome = (businessCategory:any) => {
    globalDispatch({ type: "update-businesscategory", payload: { name : businessCategory } });
    let navData = {
      targetRoute: routeMap[businessCategory]
    };
    navigate(navData);
  }

  useEffect(() => {
    if( reactAppMode == 'prod') {
      navigateToHome(process.env.REACT_APP_BUSINESS_CATEGORY);
    }
  }, []);

  return (<>
      {reactAppMode != 'prod' && <div className="landing-page">
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", mx: 3 }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} sx={{pb: 10}}>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ display: "flex", alignItems: "center", height: "150px", cursor: "pointer" }} 
                onClick={() => navigateToHome('sweetStall')}>
                <div><img src={sweetStall} className="img-style" /></div>
                <div className="font-30 font-weight-medium px-3 text-center">Sweet Stall</div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ display: "flex", alignItems: "center", height: "150px", cursor: "pointer" }} 
                onClick={() => navigateToHome('grocery')}>
                <div><img src={maligaiKadai} className="img-style" /></div>
                <div className="font-30 font-weight-medium px-3 text-center">Maligai Kadai</div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ display: "flex", alignItems: "center", height: "150px", cursor: "pointer" }} 
                onClick={() => navigateToHome('liquor')}>
                <div><img src={liquorCentre} className="img-style" /></div>
                <div className="font-30 font-weight-medium px-3 text-center">Liquor Centre</div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ display: "flex", alignItems: "center", height: "150px", cursor: "pointer" }} 
                onClick={() => navigateToHome('restaurant')}>
                <div><img src={restaurant} className="img-style" /></div>
                <div className="font-30 font-weight-medium px-3 text-center">Restaurant</div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ display: "flex", alignItems: "center", height: "150px", cursor: "pointer" }} 
                onClick={() => navigateToHome('honeybee')}>
                <div><img src={honeybee} className="img-style" /></div>
                <div className="font-30 font-weight-medium px-3 text-center">Honeybee Craftz</div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ display: "flex", alignItems: "center", height: "150px", cursor: "pointer" }} 
                onClick={() => navigateToHome('jewellery')}>
                <div><img src={jewellery} className="img-style" /></div>
                <div className="font-30 font-weight-medium px-3 text-center">Jewellery</div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div> }
    </>
  );
}
export default LandingPage;