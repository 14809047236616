import { Box, Card } from "@mui/material";
import React from "react";
import { FxCurrency } from "../../../FxCurrency/FxCurrency";

export const LiquorProduct = ({ data, actions }: any) => {

  return (
    <div>
      <Card sx={{ borderRadius: "10px", height: "150px", px: 3, display: "flex", alignItems: "center" }} >
        <Box sx={{ display: "flex" }} onClick={() => actions.productDetailAction(data.item)}>
          <Box component="img" src={data.item.productImage} sx={{ height: "140px" }} ></Box>
          <div className="pl-5">
            <div className="font-15 font-weight-medium pl-3">{data.item.productName}</div>
            <div className="font-12 color-7c pl-3 pt-1">{data.item.subProductName}</div>
            <div className="font-15 font-weight-medium py-4 pl-3"><FxCurrency value={data.item.unitPrice} /></div>
            {/* <div className="pt-2">
            <FxButton label="Add" variant="contained" size="small" 
              sx={{height: "25px", backgroundColor: "#3f3f3f", color: "#ffffff", textTransform: "none", fontWeight: 600}}
            />
            </div>  */}
            <div className="pt-2 d-flex font-18 pl-3">
              <span><i className="fas fa-minus-circle"></i></span>
              <span className="px-3">0</span>
              <span><i className="fas fa-plus-circle"></i></span>
            </div>
          </div>
        </Box>
      </Card>
    </div>
  );
}
