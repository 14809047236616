import { FxCheckbox } from "@fx/components";
import React from "react";
import { MockDataService } from "../../../../services";

export const SearchCard = ({items}: any) => {
  return (
    <div>
      {items.length > 0 && items.map((item: any, i: any) => (
       <div><FxCheckbox value="" label={item.label} name="" onValueChange={() => { }} /></div>
      ))
      }
    </div>
  );
}