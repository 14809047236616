import React from 'react';
import { localstorage } from './LocalStorageService';
import { groceryData, sweetStallData, liquorData, restaurantData, honeybeeCraftzData, jewelleryData } from '../components/generic/data';

export const MockDataService = () => {

  const mockDataMap: any = {
    sweetStall : sweetStallData,
    grocery: groceryData,
    liquor: liquorData,
    restaurant: restaurantData,
    honeybee: honeybeeCraftzData,
    jewellery: jewelleryData
  }

  const getData = (dataKey:any) => {
    const businessCategory:any = localstorage.getItem('businesscategory');
    const mockData = mockDataMap[businessCategory];
    return mockData[dataKey];
  }

  return { getData };
}