import React, {
  createContext,
  useState
} from "react";


type SecurityContextType = {
  setIsLogin: (options: any) => void,
  isLogin: any;
};

export const SecurityContext = createContext<SecurityContextType>(null!);
export const SecurityProvider: React.FC = ({ children }) => {

  const [isLogin, setIsLogin] = useState<boolean>(false);

  return (
    <SecurityContext.Provider
      value={{
        isLogin,
        setIsLogin
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};
