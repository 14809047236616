import { AppBar, Box, Drawer, Toolbar } from "@mui/material";
import React from "react";
import "./BookingHeader.scss";

import { useNavigation } from "@fx/components";
import { businessCategoryMap } from "../../businessCategoryMap";
import { localstorage, MockDataService } from "../../../../services";

export const BookingHeader = (props: any) => {
  const mockDataService = MockDataService();
  const { navigate } = useNavigation();
  const businessCategory: any = localstorage.getItem('businesscategory');

  const headerTitle = businessCategoryMap[businessCategory]?.headerTitle;
  const headerLogo = businessCategoryMap[businessCategory]?.headerLogo;

  const navigateToDashboard = () => {
    let route = businessCategoryMap[businessCategory]?.routes.dashboard;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToCart = () => {
    let route = businessCategoryMap[businessCategory]?.routes.cart;
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const navigateToLogin = () => {
    let route = "/login";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
  }

  const [leftMenuOpen, setLeftMenuOpen] = React.useState(false); 

  const openLeftMenu = (item: any) => {    
    setLeftMenuOpen(true);
  }

  const closeLeftMenu = () => {
    setLeftMenuOpen(false);
  }

  const [filterOpen, setFilterOpen] = React.useState(false);
  const closeFilter = () => {
    setFilterOpen(false);
  }
  const openFilter = () => {
    setFilterOpen(true);
  }

  const [infoListOpen, setInfoListOpen] = React.useState(false);
  const closeInfoList = () => {
    setInfoListOpen(false);
  }
  const openInfoList = () => {
    setInfoListOpen(true);
  }

  //const filter = mockDataService.getData('filter');

  return (
    <div className="header">
      <AppBar>
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
          {headerLogo && 
          <Box component="img" sx={{height: "40px", display: "flex", alignItems: "center"}} src={headerLogo} className="pointer" onClick={navigateToDashboard} >
           </Box> }
          <Box className="font-24">{headerTitle}</Box>
          <Box sx={{display: "flex"}}>
            <Box sx={{display: {xs: "none", md: "flex"}}}>
              <Box sx={{cursor: "pointer"}}><i className="fas fa-home icon-style font-17 pl-5" onClick={navigateToDashboard}></i></Box>
            </Box>
          </Box>         
        </Toolbar>
      </AppBar>
    </div>
  );
}
