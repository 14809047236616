import { session } from "@fx/components";
import _ from "lodash";
import { useEffect } from "react";
import { useREST } from "../../../helpers/useREST";
import { CategoryService, CustomerService } from "../../../services";
import useSecurityContext from "./useSecurityContext";

export default function useLogin() {
    const { setIsLogin, isLogin } = useSecurityContext();
    const customerService = CustomerService();

    const signIn = async(inputData:any) => {
        let result = await customerService.login(inputData);
        if(result?.accessToken) {
            //session.setItem('token', result.accessToken);
            setIsLogin(true);
            return true;
        }
        return false;
    }

    const getCurrentUser = async() => {
        let result = await customerService.getCurrentUser();
        if(result?.userName) {
            setIsLogin(true);
            return true;
        }
        return false;
    }

    const signOut = async() => {
        let result = await customerService.logout();
        setIsLogin(false);
    }

    return { signIn, signOut, isLogin, getCurrentUser };
}
