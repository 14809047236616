import { Box } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import { localstorage } from '../../services';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export const FxCurrency = (props: any) => {

    const businessCategory: any = localstorage.getItem('businesscategory');

    let currencyDisplay = 0;
    let decimalDisplay = "00";
    if (props.value) {
        let value = props.value.toFixed(2);
        var splits = value.split(".");
        currencyDisplay = splits[0];
        decimalDisplay = splits[1];
    }

    return (
        <>
        <div>
        <i className="fas fa-rupee-sign pr-1"></i>{currencyDisplay}.{decimalDisplay}
        </div>
            {/* <Box sx={{ display: "flex", alignItems: "center"}}>
                <CurrencyRupeeIcon fontSize='inherit' color="inherit" />
                <Box>{currencyDisplay}.{decimalDisplay}</Box>
            </Box> */}
        </>
    );
};

