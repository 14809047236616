import React from 'react';
import { apiClient } from '../context-helpers/ApiClient';

export const CategoryService = ({}: any) => {

  const getCategories = async ({ queryKey }: any) => {
    const [_, inputData] = queryKey;
    let apiOptions: any = {
      url: 'categories/query',
      method: 'post',
      data: inputData
    };
    let result = await apiClient.doAction(apiOptions);
    return result;
  }

  return { getCategories };
}