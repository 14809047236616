import { useContext } from "react";
import { CatalogContext } from "./CatalogProvider";

export default function useCatalogContext() {
  const context = useContext(CatalogContext);
  if (!context) {
    throw new Error("useContext must be used within a CatalogProvider");
  }
  return context;
}
