export const restaurantTemplates = {
  product: `<RestaurantProduct data={data} actions={actions}/>`,
  cart: `<div className="cart-card">
    <Card className="pointer" sx={{ borderRadius: "4px", height: "70px", display: "flex", justifyContent: "space-between", alignItems: "center", px: "15px" }} >
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <div>
            <span className="font-15 font-weight-medium pr-1">{data.item.productName}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div><FxCurrency value={data.item.unitPrice} /></div>
        </Grid>
        <Grid item xs={6} sx={{display: "flex", justifyContent: "center"}}>
          <div className="d-flex font-20">
            <Box sx={{color: "primary.main"}}><i className="fas fa-minus-circle pointer"></i></Box>
            <span className="px-3">{data.item.productQty}</span>
            <Box sx={{color: "primary.main"}}><i className="fas fa-plus-circle pointer"></i></Box>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="font-15 font-weight-medium text-right"><FxCurrency value={data.item.qtyPrice} /></div>
        </Grid>
      </Grid>
    </Card>
  </div>`,
  paymentOptions: `<FxButtonGroup action={actions.handleChange} selected={data.paymentType} item={data.item}/>`,
  deliveryOptions: `<FxButtonGroup action={actions.handleChange} selected={data.deliveryType} item={data.item}/>`,
  deliveryAddress: `<div className="delivery-address">
      <Card sx={{ borderRadius: "4px", p: "10px" }} >
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
          <div className="font-weight-medium font-16">Delivery Address</div>
          <Box sx={{color: "primary.main"}}><i className="fas fa-pen font-15"></i></Box>
        </Box>
        <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
          <div>Karthik</div>
          <div>North Street</div>
          <div>Chennai</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
          <div className="font-weight-medium font-16">Phone Number</div>
          <Box sx={{color: "primary.main"}}><i className="fas fa-pen font-15"></i></Box>
        </Box>
        <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
          <div>9012345678</div>
        </Box>
      </Card>
    </div>`,
  deliverySlot: `<Grid item xs={6} sm={3}>
        <Box
            sx={{
              backgroundColor: "#EDEDED",
              border: "1px solid #ededed",
              borderRadius: "20px",
              paddingRight: "10px",
              paddingLeft: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
          {data.item}
        </Box>
      </Grid>`,
  billDetails: `<div>
      <Card sx={{ borderRadius: "4px", p: "10px" }} >
        <Box sx={{ display: "flex" }} className="pb-2">
          <div className="font-weight-medium font-16">Bill Details</div>         
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
          <div>Subtotal</div>
          <div><FxCurrency value={1100} /></div>
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="font-15 pb-1">
          <div>Delivery charge</div>
          <Box className="font-12 font-weight-medium" sx={{color: "#00A611"}}>Free Delivery</Box>
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
          <div>Coupon</div>
          <div><FxCurrency value={0} /></div>
        </Box>
        <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
          <div>GST</div>
          <div><FxCurrency value={0} /></div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="font-15 pb-1 font-weight-medium">
          <div>Grand Total</div>
          <div><FxCurrency value={1100} /></div>
        </Box>
      </Card>
    </div>`,
  couponCard: `<div>
        <Card sx={{ borderRadius: "4px", p: "10px" }} >
          <Box sx={{ display: "flex" }} className="pb-2">
            <div><img src={data.item.icon} width="23px" /></div>
            <div className="font-weight-medium font-16 pl-2">Apply Coupon</div>
          </Box>
          <Box className="pb-3">
            <div>Use coupon code to avail 10% offer for your order</div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <div>
              <FxInput name="couponCode" value={data.item.couponCode} variant="outlined" size="small" />
            </div>
            <div className="pl-3">
              <FxButton label="Select" variant="contained" size="small" sx={{height: "30px",
                textTransform: "none",
                fontWeight: 600}} />
            </div>
          </Box>
        </Card>
      </div>`,
  summary: `<div>
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item xs={3}>
        <Box component="img" src={data.item.productImage} sx={{width: "50px", height: "50px"}}></Box>     
      </Grid>
      <Grid item xs={5}>
        <div>{data.item.productName}</div>       
        <div><FxCurrency value={data.item.unitPrice} /></div>
      </Grid>
      <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
      <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
    </Grid>
  </Box>
</div>`,
  myOrders: `<MyordersCard data={data} actions={actions} />`,
  orderDetail: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
  <Grid container direction="row" alignItems="center" spacing={1}>
    <Grid item xs={3}>
      <Box component="img" src={data.item.productImage} sx={{width: "50px",
        height: "50px",
        borderRadius: "4px"}}  />
    </Grid>
    <Grid item xs={5}>
      <div>{data.item.productName}</div>
      <div className="font-12 color-888">{data.item.productUnit}</div>
      <div><FxCurrency value={data.item.unitPrice} /></div>
    </Grid>
    <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
    <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
  </Grid>
</Box>`,
  filter: `<div className="filter px-5 py-5">    
<div className="pt-4 pb-4 font-20 font-weight-medium">Price Range</div> 
<Box className="px-3"><FxSlider priceList={data.priceList} min={0} max={1500} /></Box>
<Box sx={{position: "fixed", bottom: 20, left: 15, right: 15}}>
  <FxButton label="Apply" fullWidth variant="contained" />
</Box>
</div>`
}