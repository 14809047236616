import { Box, Grid } from "@mui/material";
import React from "react";
import "./JewelleryExclusive.scss";

export const JewelleryExclusive = (props: any) => {
  return (
    <Box sx={{ py: 1 , backgroundColor: "#C95CC7", px:2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", fontSize: "20px", lineHeight: "1.0" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <i className="fas fa-map"></i>
              <Box sx={{ pl: 1 }}>Track Your Order</Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <i className="fas fa-shipping-fast"></i>
              <Box sx={{ pl: 1 }}>Delivery All Over India</Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <i className="fas fa-infinity"></i>
              <Box sx={{ pl: 1 }}>Premium Designs</Box>
            </Box>
          </Box>
    </Box>
  )
}