import wine from "../../../../assets/liquor/wine.png";
import spirits from "../../../../assets/liquor/spirits.png";
import gifts from "../../../../assets/liquor/gifts.png";
import mixedCases from "../../../../assets/liquor/mixedCases.png";
import foodMatching from "../../../../assets/liquor/foodMatching.png";
import offerimg from "../../../../assets/liquor/offerImage.png";

import villamaria from "../../../../assets/liquor/villamaria.png";
import lanvin from "../../../../assets/liquor/lanvin.png";
import duckgoose from "../../../../assets/liquor/duckgoose.jpg";
import targries from "../../../../assets/liquor/targries.jpg";
import liquorbrut from "../../../../assets/liquor/liquorbrut.png";
import topValue from "../../../../assets/liquor/topValue.png";
import bobCampbell from "../../../../assets/liquor/bobCampbell.jpg";
import wineAwards from "../../../../assets/liquor/wineAwards.jpg";
import cuisineMagazine from "../../../../assets/liquor/cuisineMagazine.jpg";
import silverMedal from "../../../../assets/liquor/silverMedal.jpg";
import goldMedal from "../../../../assets/liquor/goldMedal.jpg";
import fish from "../../../../assets/liquor/fish.jpg";
import duck from "../../../../assets/liquor/duck1.jpg";
import lamb from "../../../../assets/liquor/lamb1.jpg";
import shellfish from "../../../../assets/liquor/shellfish.jpg";
import pork from "../../../../assets/liquor/pork.jpg";
import beefSteak from "../../../../assets/liquor/beefSteak.jpg";
import asian from "../../../../assets/liquor/asian.jpg";
import vegetarian from "../../../../assets/liquor/vegetarian.jpg";
import bbq from "../../../../assets/liquor/bbq.jpg";


const categories = [
  { categoryImage: wine, categoryName: "Wine" },
  { categoryImage: spirits, categoryName: "Spirits" },
  { categoryImage: gifts, categoryName: "Gifts" },
  { categoryImage: mixedCases, categoryName: "Mixed Cases" },
  { categoryImage: foodMatching, categoryName: "Food Matching" },
];

const offerData: any = [
  { 
    productImage : villamaria,  
    headerText: "Bigger, bolder, more buttery - it's Bogle Chardonnay supercharged!",
    productName: "Villa Maria Cellar Selection Syrah",
    productPrice: 19.99
  },
  { 
    productImage : lanvin,  
    headerText: "Big. Buttery. Brand new.",
    productName: "H.Lanvin & Fils Champagne Brut",
    productPrice: 41.99
  },
  { 
    productImage : duckgoose,  
    headerText: "Best Shiraz. Officially.",
    productName: "Duck Duck Goose Shiraz",
    productPrice: 19.99
  }
];
const items = [
  { 
    productImage: villamaria, 
    productName: "Villa Maria Cellar Selection Syrah", 
    subProductName: "2020, Hawkes Bay", 
    unitPrice: 19.99, 
    certificates: [{ image : topValue}, { image : bobCampbell}, { image : wineAwards}],
    foodMatch: [{ image : lamb}, { image : duck}],
    shortDesc: [
      "In our opinion, one of NZ's best value Syrahs under $20 - year after year after year!",
      "90/100 & Top Value (Bob Campbell MW, The Real Review) - 'Deep-tinted, juicy syrah with bright violet, dark cherry and berry, pepper, anise and spice flavours'",
      "Silky, supple medium bodied red bursting with dark berries and spice",
      "Just the red for a cut of good old Kiwi lamb"
    ],
    longDesc: [
      "It seems pretty fitting that Villa Maria decided to put their Cellar Selection range behind a Gold label – being NZ’s most awarded winery they’ve won a Gold or few in their time.",
      "And their Villa Maria Cellar Selection Syrah is no stranger to the podium. We cannot remember the last time this label didn’t win a Gold or a 5 Star review. Honestly, this must be up there with some of the best NZ Syrah you can lay your mits on under the $20 mark.",
      "The 2020 is here and no doubt shapes up to be another beauty. A rich, silky, supple, medium bodied red and absolutely that’s just bursting with delicious dark berries and spice.",
      "It’s got the legs to be cellared for a good 6-7 years yet so open some now and tuck some away for a few years yet."
    ],
    alcohol: "13% alc",
    quantity: "750ml",
    cap: "Screwcap"
  },
  { 
    productImage: lanvin, 
    productName: "H.Lanvin & Fils Champagne Brut", 
    subProductName: "Non Vintage, Champagne", 
    unitPrice: 41.99, 
    certificates: [{ image : cuisineMagazine}, { image : goldMedal}],
    foodMatch: [{ image : fish }, { image : shellfish }],
    shortDesc: [
      "One of the best value for money French Champagnes money can buy",
      "Recommended in Cuisine magazine's annual Champagne tasting 9 years running!",
      "Gold medal (NZ Int'l Wine Show 2020), 4 Stars & Best Buy (Cuisine magazine 2020)"
    ],
    longDesc: [
      "There’s no ifs, buts or maybes about it. H.Lanvin & Fils Champagne Brut (also just called Lanvin Champagne) is the best bang for buck, genuine imported French Champagne on the market.",
      "Why? Well, here’s just a few reasons…",
      "4 Stars & Best Buy (Cuisine magazine 2020)", 
      "Gold medal (NZ Int’l Wine Show 2020)",
      "4 Stars & Best Buy (Cuisine magazine 2019)",
      "5 Stars & Best Buy (Cuisine magazine 2018)",
      "4 1/2 Stars & Best Buy (Cuisine magazine 2017)",
      "Gold medal (NZ Int’l Wine Show 2017)",
      "4 Stars & Best Buy (Cuisine magazine 2016)",
      "4 Stars & Best Buy (Cuisine magazine 2015)",
      "Gold medal (NZ Int’l Wine Show 2014)",
      "4 1/2 Stars (Cuisine magazine 2014)",
      "5 Stars & Best Buy (Cuisine magazine 2013)",
      "5 Stars & Best Buy (Cuisine magazine 2012)",
      "5 Stars (Winestate magazine 2012)",
      "Gold medal (Spiegelau Int’l Wine Comp 2012)",
      "Gold medal (NZ Int’l Wine Show 2012)",
      "5 Stars & 94/100 (Wine Orbit, Sam Kim)",
      "In Cuisine magazine’s annual Champagne tasting Lanvin has scored 4 Stars & Best Buy or higher in 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013 and 2012. Talk about consistency!",
      "Lanvin might not having the celebrity status of other Champagne brands but it’s is a true lesson in drinking wine with your mouth and not your eyes. So if you’re after the real deal in French Champagne but don’t want to fork out top dollar – this is the one."
    ],
    alcohol: "12.5% alc",
    quantity: "750ml",
    cap: "Cork"
  },
  { 
    productImage: duckgoose, 
    productName: "Duck Duck Goose Shiraz", 
    subProductName: "2018, Barossa Valley", 
    unitPrice: 19.99, 
    certificates: [{ image :topValue}, { image : silverMedal}],
    foodMatch: [{ image : beefSteak}, { image : bbq }, { image : pork}],
    shortDesc: [
      "Duck, Duck, Duck, GOOSE - tag, you're it!",
      "Deep, dark, full bodied Barossa Shiraz",
      "Silver medal (NZ Int'l Wine Show 2020), 86/100 & Top Value (Ralph Kyte-Powell, The Real Review)",
      "'A friendly sort of Barossa quaffer with dark berry smells and flavours, fruit-sweet, medium-bodied' (Ralph Kyte-Powell, The Real Review)"
    ],
    longDesc: [
      "Duck, Duck, Duck, Duck, GOOSE! Tag, you’re it!",
      "If chasing deep, dark, full bodied Aussie Shiraz is your game then you’re in the right spot. From the world famous Barossa Valley region this has been a firm favourite and quacking good value for many years now.",
      "This latest 2018 vintage received a “Top Value” award by Aussie wine critic Ralph Kyte-Powell in The Real Review. Ralph called it “A friendly sort of Barossa quaffer with dark berry smells and flavours, fruit-sweet, medium-bodied.”",
      "Just the kind of sun drenched Aussie Shiraz you’ll want to go with those sizzling hot slabs of meat coming off the grill."
    ],
    alcohol: "14.5% alc",
    quantity: "750ml",
    cap: "Screwcap"
  },
  { 
    productImage: targries, 
    productName: "The Doctors Riesling", 
    subProductName: "2020, Marlborough", 
    unitPrice: 19.99,
    certificates: [{ image : ""}],
    foodMatch: [{ image : asian}, { image : vegetarian}],
    shortDesc: [
      "Low alcohol (9%) medium style Riesling - made by real Doctors!",
      "Like biting into a fresh Granny Smith, this is simply devine!",
      "Find out why this wine has a cult like following in Aotearoa"
    ],
    longDesc: [
      "John and Brigid Forrest are The Doctors (yep, they’re real doctors!) and they recommend regular doses of this multi award winning Riesling that’s gained cult like status.",
      "They put it best when they say “Like biting into a deliciously crisp granny smith apple, licking lime sherbet and apricot, all at one time! So easy to drink, with the perfect balance of fruit sweetness and fresh acidity at a modest 9% alcohol. Perfect for your modern lifestyle.”",
      "If you’ve tried this before you’ll know why it’s got a huge following. It’s pure deliciousness from first sip and quite frankly, hard not to love!",
      "It’s a touch on the sweeter side and balanced to perfection. We love this wine and always keep a couple of bottles in the staff fridge, especially over the warmer months. Serve this one up as a pre-dinner tipple or with spicy Asian dishes."
    ],
    alcohol: "9% alc",
    quantity: "750ml",
    cap: "Screwcap"
  },
  { 
    productImage: liquorbrut, 
    productName: "Veuve Clicquot Champagne Brut", 
    subProductName: "Non Vintage, Champagne", 
    unitPrice: 69.99, 
    certificates: [{ image :cuisineMagazine}],
    foodMatch: [{ image :fish}, { image : shellfish}],
    shortDesc: [
      "It's the world's coolest French Champagne brand!",
      "'Veuve' means 'Widow' in French - named after the legendary Madame Clicquot, the widow who built this legendary wine into what it is today!",
      "“Only one quality, the finest” was Madame Clicquot’s motto. Only the finest gets issued with a Royal Warrant by Queen Elizabeth II"
    ],
    longDesc: [
      "Madame Clicquot was a 19th Century widow (‘veuve’ in French) who took control of her late husband’s champagne business and built it into what is today. ",
      "Officially known as Veuve Clicquot Ponsardin and formed almost 250 years ago, today it’s one of the biggest (and coolest!) French Champagne brands in the world.",
      "“Only one quality, the finest” was Madame Clicquot’s motto. Only the finest gets issued with a Royal Warrant by Queen Elizabeth II that’s for sure. Yep, Liz loves a cheeky little flute of Clicquot now and again so keeps a stash in her royal cellars!",
      "Having her royal highness sip your Champagne regularly, well that’s just priceless. So is the bottle they found north of the border in Scotland back in 2008. Hiding in a sideboard in a castle at the Isle of Mull was a perfectly preserved bottle of Clicquot, dated from 1893! The bottle is the oldest known bottle of Clicquot, it’s on display at the Maison Clicquot in Reims and officially priceless.",
      "The instantly recognisable yellowy-orangey label of Veuve Clicquot Champagne Brut is the signature Champagne of Maison Clicquot. A premium Champagne now sipped in every corner of the globe. Bust out your chilled bottle of Clicquot, get your instagram filters set and serve!"
    ],
    alcohol: "12% alc",
    quantity: "750ml",
    cap: "Cork"
  }
];

const cartItems = [
  { productName: "Villa Maria Cellar Selection Syrah", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productName: "H.Lanvin & Fils Champagne Brut", unitPrice: 41.99, productQty: 1, qtyPrice: 41.99 },
  { productName: "Duck Duck Goose Shiraz", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productName: "The Doctors Riesling", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productName: "Veuve Clicquot Champagne Brut", unitPrice: 69.99, productQty: 1, qtyPrice: 69.99 }
];

const deliveryOptions: any = [
  { code: "DoorDelivery", text: "Door Delivery", icon: "fas fa-biking" },
  { code: "TakeAway", text: "Take Away", icon: "fas fa-people-carry" },
]

const paymentOptions: any = [
  { code: "cash", text: "Cash on Delivery", icon: "fas fa-wallet" },
  { code: "card", text: "Credit/Debit Card", icon: "far fa-credit-card" },
]

const summaryItems = [
  { productImage: villamaria, productName: "Villa Maria Cellar Selection Syrah", subProductName: "2020, Hawkes Bay", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productImage: lanvin, productName: "H.Lanvin & Fils Champagne Brut", subProductName: "Non Vintage, Champagne", unitPrice: 41.99, productQty: 1, qtyPrice: 41.99 },
  { productImage: duckgoose, productName: "Duck Duck Goose Shiraz", subProductName: "2018, Barossa Valley", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productImage: targries, productName: "The Doctors Riesling", subProductName: "2020, Marlborough", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productImage: liquorbrut, productName: "Veuve Clicquot Champagne Brut", subProductName: "Non Vintage, Champagne", unitPrice: 69.99, productQty: 1, qtyPrice: 69.99 }
];

const leftMenu = [
  {icon: "fas fa-user", menuName: "My Profile", route: '/my-profile'},
  {icon: "fas fa-envelope", menuName: "Address", route: '/address-list'},
  {icon: "fas fa-clipboard-list", menuName: "My Orders", route: '/my-orders'}
];

const myOrders = [
  { orderDate: "04/09/21, 08.00 am", orderDisplayId: "#12345", orderAmount: 93.00, paymentMode: "Pending" },
  { orderDate: "01/09/21, 11.00 am", orderDisplayId: "#25634", orderAmount: 115.00, paymentMode: "Paid" },
  { orderDate: "25/08/21, 05.00 pm", orderDisplayId: "#35489", orderAmount: 120.00, paymentMode: "Paid" },
  { orderDate: "13/08/21, 09.00 am", orderDisplayId: "#86432", orderAmount: 150.00, paymentMode: "Paid" },
  { orderDate: "07/08/21, 03.00 pm", orderDisplayId: "#23895", orderAmount: 50.00, paymentMode: "Paid" },
];

const orderDetail = [
  { productImage: villamaria, productName: "Villa Maria Cellar Selection Syrah", subProductName: "2020, Hawkes Bay", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productImage: lanvin, productName: "H.Lanvin & Fils Champagne Brut", subProductName: "Non Vintage, Champagne", unitPrice: 41.99, productQty: 1, qtyPrice: 41.99 },
  { productImage: duckgoose, productName: "Duck Duck Goose Shiraz", subProductName: "2018, Barossa Valley", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productImage: targries, productName: "The Doctors Riesling", subProductName: "2020, Marlborough", unitPrice: 19.99, productQty: 1, qtyPrice: 19.99 },
  { productImage: liquorbrut, productName: "Veuve Clicquot Champagne Brut", subProductName: "Non Vintage, Champagne", unitPrice: 69.99, productQty: 1, qtyPrice: 69.99 }
];

const filter =  {
  searchCategories: [{label: "Wine"}, {label: "Spirits"}, {label: "Gifts"}, {label: "Food matching"}],
  priceList: [{value: 0, label : 0}, {value: 10, label : 10}, {value: 25, label : 25}, {value: 50, label : 50}, {value: 75, label : 75}, {value: 100, label : 100}]  
};

const productFilterModel: any = {
  fromPrice: 20,
  toPrice : 50
}


export const liquorData: any = {
  categories: categories,
  offerData: offerData,
  items: items,
  cartItems: cartItems,
  paymentOptions: paymentOptions,
  deliveryOptions: deliveryOptions,
  summaryItems: summaryItems,
  leftMenu: leftMenu,
  myOrders: myOrders,
  orderDetail: orderDetail,
  filter: filter,
  productFilterModel: productFilterModel
}