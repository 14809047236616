import React, {
  createContext,
  useState
} from "react";

type WishlistContextType = {
  wishlistCount: any;
  setWishlistCount: any
};

export const WishlistContext = createContext<WishlistContextType>(null!);
export const WishlistProvider: React.FC = ({ children }) => {

  const [wishlistCount, setWishlistCount] = useState(0);
  
  return (
    <WishlistContext.Provider
      value={{
        wishlistCount,
        setWishlistCount
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
