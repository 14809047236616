import React from 'react'
import JsxParser from 'react-jsx-parser';
import { ComponentMap } from './ComponentMap';

const DynamicComponent = ({ options, componentHtml}: any) => {
  const onError = (e:any) => {
    console.log('jsxparser on error=',e);
  }
  return (
    <JsxParser bindings={options}
      components={ComponentMap}
      jsx={componentHtml}
      renderInWrapper={false}
      onError={onError}
    />
  );
}
export default DynamicComponent;