import { FxLink } from "@fx/components";
import { Box, Card } from "@mui/material";
import React from "react";
import { FxDateDisplayLocal } from "../../../fx/date-display/FxDateDisplayLocal";
import { FxCurrency } from "../../../FxCurrency/FxCurrency";
import Link from '@mui/material/Link'

export const MyOrdersCard = ({ data, actions }: any) => {

  return (
    <div>
      <Card sx={{ border: "1px solid #E4E4E4", borderRadius: "5px", display: "flex", justifyContent: "space-between", flexDirection: "column" }}
        onClick={() => actions.orderDetailAction(data.item)}>
        <div className="font-13 pb-2 pt-3 pl-2"> 
          <FxDateDisplayLocal value={data.item.orderDate} displayType="date"/>
          <Box sx={{float:"right", paddingRight: "15px"}}>
              {data.item.paymentStatusId !=2 &&<Link onClick={(e:any) => actions.payAction(e, data.item)}>
                  Pay
              </Link> }
              {data.item.paymentStatusId == 2 && <div>Paid</div>}
            </Box>
        </div>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-3 px-2">
          <div className="col-4 text-center">
            <div className="font-13 color-888">Order Number</div>
            <div className="font-13 font-weight-bold">#{data.item.displayId}</div>
          </div>
          <div className="col-4 text-center">
            <div className="font-13 color-888">Order Total</div>
            <div className="font-13 font-weight-bold"><FxCurrency value={data.item.orderTotal} /></div>
          </div>
          <div className="col-4 text-center">
            <div className="font-13 color-888">Payment Mode</div>
            <div className="font-13 font-weight-bold">{data.item.paymentMode}</div>
          </div>
        </Box>
      </Card>
    </div>
  );
}