import { session } from "@fx/components";
import _ from "lodash";
import { useEffect } from "react";
import { useREST } from "../../../helpers/useREST";
import { CategoryService, CustomerService, WishlistService } from "../../../services";
import useWishlistContext from "./useWishlistContext";

export default function useWishlist() {
    const { setWishlistCount, wishlistCount } = useWishlistContext();

    const wishlistService = WishlistService({});
    const refreshMyWishlistCount = async() => {
      let result = await wishlistService.getWishlistCount();
      setWishlistCount(result);
    }

    return { refreshMyWishlistCount, wishlistCount};
}
