import { IAppAction } from ".";

const initialState = {
};

export const profileInfo = (state: any = initialState, action: IAppAction): any => {
  switch (action.type) {
    case "update-profileinfo":
      let payload = {...action.payload };
      //use for privilege testing
      //payload.privilege = [];
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}