import vsnLogo from "../../assets/sweets/logo.jpg";
import liquorLogo from "../../assets/liquor/logo.png";
import jewelleryLogo from "../../assets/jewellery/chennai-jewel-logo.jpg";
import bookingLogo from "../../assets/booking/hh-logo.png";

export const businessCategoryMap: any = {
    sweetStall : { routes: { dashboard : '/dashboard', cart : '/cart' },  headerLogo : vsnLogo, headerTitle: 'Krishna Sweets', canShowProductDetail: false},
    grocery : { routes : { dashboard : '/dashboard', cart : '/cart' }, headerLogo : '', headerTitle: 'Grocery Shop', canShowProductDetail: false },
    liquor : { routes : { dashboard : '/dashboard', cart : '/cart'}, headerLogo : liquorLogo, headerTitle: 'Liquor Centre', canShowProductDetail: true },
    restaurant: { routes : { dashboard : '/products', cart : '/cart'}, headerLogo : '', headerTitle: 'Restaurant', canShowProductDetail: false },
    honeybee: { routes : { dashboard : '/products', cart : '/cart'}, headerLogo : '', headerTitle: 'Honeybee Craftz', canShowProductDetail: true },
    jewellery: { routes : { dashboard : '/jewel-dashboard', cart : '/cart'}, headerLogo : jewelleryLogo, headerTitle: 'Chennai Jewels', canShowProductDetail: true },
    booking: { routes : { dashboard : '/bookingrequest', cart : ''}, headerLogo : bookingLogo, headerTitle: 'HERBS & HUB', canShowProductDetail: true },
}