import React, { useEffect, useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigation } from "@fx/components";
import useCategories from "../../../../providers/catalog/useCategories";
import { useDispatch } from "react-redux";

export const JewelleryHeaderMobile = ({ closeAction }: any) => {
  const { navigate, navigateRoot, clear } = useNavigation();
  const { categories } = useCategories();
  const defaultCurrentCategory: any = {};
  const [currentCategory, setCurrentCategory] = React.useState(defaultCurrentCategory);
  const globalDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const categoryMenuOpen = Boolean(anchorEl);

  const closeCategoryMenu = () => {
    setAnchorEl(null);
  };

  const [categoryOpen, setCategoryOpen] = React.useState(false);
  const openCategoryMenu = () => {
    setCategoryOpen(!categoryOpen);
  };

  const categoryClick = (cat:any, index:number) => {
    if(cat.subItems.length > 0) {
      if(currentCategory._id == cat._id) {
        setCurrentCategory({});
      } else {
        setCurrentCategory(cat);
      }
    } else {
      openProducts(cat);
    }
  }

  const subcategoryClick = (subCat:any) => {
      openProducts(subCat, subCat.parentCategory);
  }

  const openProducts = (category: any, parentCategory: any = null) => {
    closeCategoryMenu();
    clear();
    const navDataDashboard = {
      route: '/jewel-dashboard',
      title: 'Home'
    };
    globalDispatch({ type: "nav-push", payload: navDataDashboard });

    if(parentCategory) {
      const navDataParentCategory = {
        route: `/products-parent-category/${parentCategory._id}`,
        title: parentCategory.name
      };
      globalDispatch({ type: "nav-push", payload: navDataParentCategory });
    }

    let navData = {
      targetRoute: `/products/${category.slug}`,
      targetTitle: category.name
    };
    closeAction();
    navigate(navData);
  }

  const [helpOpen, setHelpOpen] = React.useState(false);
  const openHelp = () => {
    setHelpOpen(!helpOpen);
  };

  const [informationOpen, setInformationOpen] = React.useState(false);
  const openInformation = () => {
    setInformationOpen(!informationOpen);
  };

  const navigateToDelivery = () => {
    let route = "/jewel-delivery";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }

  const navigateToReturn = () => {
    let route = "/jewel-returns";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }
  const navigateToAbout = () => {
    let route = "/jewel-about";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }
  const navigateToContact = () => {
    let route = "/jewel-contact";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }

  const navigateToPolicy = () => {
    let route = "/jewel-privacy-policy";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }
  const navigateToFaq = () => {
    let route = "/jewel-faq";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }
  const navigateToTermsAndCondition = () => {
    let route = "/jewel-terms-and-conditions";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }

  const navigateToPrivacyPolicy = () => {
    let route = "/jewel-contact";
    let navData = {
      targetRoute: route
    };
    navigate(navData);
    closeAction()
  }

  const navToHome = () => {
    let navData = {
      targetRoute: `/jewel-dashboard`
    };
    navigateRoot(navData);
  }


  return (
    <List sx={{ pt: 18 }} className="SlideHeader">
      <ListItem disablePadding>
        <ListItemButton sx={{ py: "2px" }}>
          <ListItemText sx={{ fontSize: "15px", cursor: "pointer" }} primary="Home" onClick={navToHome} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton sx={{ py: "2px" }} onClick={openCategoryMenu}>
          <ListItemText sx={{ fontSize: "15px", cursor: "pointer" }} primary="Shop by Category" />
          {categoryOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={categoryOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {categories && categories.map((cat: any, i: any) => (
            <Box>
            <ListItemButton sx={{ pl: 4 }} onClick={() =>categoryClick(cat, i)}>
              <ListItemText primary={cat.name} />
              {cat.subItems.length > 0 && <>{cat._id == currentCategory._id ? <ExpandLess /> : <ExpandMore />}</>}
             
            </ListItemButton>
            <Collapse in={cat._id == currentCategory._id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{pl: 2}}>
                      {cat.subItems && cat.subItems.map((subCat: any, i: any) => (
                        <ListItemButton sx={{ pl: 4 }}  onClick={() =>subcategoryClick(subCat)}>
                          <ListItemText primary={subCat.name} />
                        </ListItemButton>
                      ))
                      }
                    </List>
              </Collapse>
            </Box>
          ))
          }        
        </List>
      </Collapse>           
     
      <ListItem disablePadding>
        <ListItemButton sx={{ py: "2px" }}>
          <ListItemText sx={{ fontSize: "15px", cursor: "pointer" }} primary="Designer Signature" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton sx={{ py: "2px" }} onClick={openHelp}>
          <ListItemText sx={{ fontSize: "15px", cursor: "pointer" }} primary="Help" />
          {helpOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={helpOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToContact}>
            <ListItemText primary="Contact Us" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToAbout}>
            <ListItemText primary="About" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToDelivery}>
            <ListItemText primary="Delivery Information" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToReturn}>
            <ListItemText primary="Return Policy" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton sx={{ py: "2px" }} onClick={openInformation}>
          <ListItemText sx={{ fontSize: "15px", cursor: "pointer" }} primary="Information" />
          {informationOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={informationOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToFaq}>
            <ListItemText primary="FAQ" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToPolicy}>
            <ListItemText primary="Privacy Policy" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={navigateToTermsAndCondition}>
            <ListItemText primary="Terms & Conditions" />
          </ListItemButton>          
        </List>
      </Collapse>
    </List>
  );
}