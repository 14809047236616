import _ from "lodash";
import { useEffect, useState } from "react";
import { useREST } from "../../../helpers/useREST";
import { CategoryService } from "../../../services";
import useCatalogContext from "./useCatalogContext";

export default function useCategories({ categoryId }:any = {}) {
    const categoryService = CategoryService({});
    const { setCategories, categories } = useCatalogContext();
    const { setCategory, category } = useCatalogContext();
    const defaultCategoryMap: any = {};
    const [categoryMap, setCategoryMap] = useState(defaultCategoryMap);

    const queryKey = "getCategories";
    const queryOptions = {};
    let inputData: any = {
        data: {
            filters: {
            }
        }
    };
    const { data, isLoading, error, refetch, isFetching }: any = useREST({
        queryKey: [queryKey, inputData],
        queryFn: categoryService.getCategories, queryOptions
    });

    useEffect(() => {
        let catMap: any = {};
        if (data && data.items) {
            let items = data?.items;
            //let categoryList = items.filter((cat:any) => cat.includeInMenu);
            //let categoryList = _.orderBy(items, ['displayOrder'], ['asc']);
            for(let item of data.items) {
                catMap[item.name] = item;
                if(categoryId && item._id == categoryId) {
                    setCategory(item);
                }
                for(let subItem of item.subItems) {
                    subItem.parentCategory = item;
                    catMap[subItem.name] = subItem;
                    if(categoryId && subItem._id == categoryId) {
                        setCategory(item);
                    }
                }
            }
            setCategoryMap(catMap);
            setCategories(items);
        }
    }, [data]);
    return { categories, category, categoryMap };
}
