import React, { useState, useEffect, Suspense, useRef } from "react";
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { TokenContextProvider } from "./context-helpers/TokenProvider";
import { loginRequest } from "./authConfig";
import { Home } from "./components/home/Home";
import LandingPage from "./components/landingPage/LandingPage";
import { Box, Breadcrumbs, createTheme, Grid, ThemeProvider } from "@mui/material";
import { useSelector } from 'react-redux';
import groceryTheme from './themes/grocery/theme1.module.scss';
import sweetStallTheme from './themes/sweetstall/theme1.module.scss';
import liquorTheme from './themes/liquor/theme1.module.scss';
import restaurantTheme from './themes/restaurant/theme1.module.scss';
import honeybeeCraftzTheme from './themes/honeybeeCraftz/theme1.module.scss';
import jewelleryTheme from './themes/jewellery/theme1.module.scss';
import bookingTheme from './themes/booking/theme1.module.scss';
import { Header } from "./components/generic/header/Header";
import { HoneybeeHeader } from "./components/generic/header/honeybee/HoneybeeHeader";
import { JewelleryHeader } from "./components/generic/header/jewellery/JewelleryHeader";
import { JewelleryFooter } from "./components/generic/footer/jewellery/JewelleryFooter";
import { Footer } from "./components/generic/footer/Footer";
import { AllRoutes } from "./AllRoutes";
import { localstorage, MockDataService } from "./services";
import { CatalogProvider } from "./components/providers/catalog/CatalogProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { JewelleryFab } from "./components/generic/floating-button/jewellery/JewelleryFab";
import { CartProvider } from "./components/providers/cart/CartProvider";
import { SecurityProvider } from "./components/providers/security/SecurityProvider";
import { BookingHeader } from "./components/generic/header/booking/BookingHeader";
import { BookingFooter } from "./components/generic/footer/booking/BookingFooter";
import { ConfirmProvider } from "@fx/components";
import { WishlistProvider } from "./components/providers/wishlist/WishlistProvider";

const queryClient = new QueryClient();

const PageLoader: React.FC = () => <div />

function App() {
  const defaultBusinessCategory: any = localstorage.getItem('businesscategory');
  const [businessCategory, setBusinessCategory] = React.useState(defaultBusinessCategory);
  const businessCategoryInst = useSelector<any, any>(s => s.businessCategory);
  const mockDataService = MockDataService();

  const themeMap: any = {
    sweetStall: {
      palette: {
        primary: {
          main: sweetStallTheme.primary,
          contrastText: sweetStallTheme.primaryContrast
        },
        secondary: {
          main: sweetStallTheme.secondary
        },
      },
    },
    grocery: {
      palette: {
        primary: {
          main: groceryTheme.primary,
          contrastText: groceryTheme.primaryContrast
        },
        secondary: {
          main: groceryTheme.secondary
        },
      },
    },
    liquor: {
      palette: {
        primary: {
          main: liquorTheme.primary,
          contrastText: liquorTheme.primaryContrast
        },
        secondary: {
          main: liquorTheme.secondary
        },
      },
    },
    restaurant: {
      palette: {
        primary: {
          main: restaurantTheme.primary,
          contrastText: restaurantTheme.primaryContrast
        },
        secondary: {
          main: restaurantTheme.secondary
        },
      },
    },
    honeybee: {
      palette: {
        primary: {
          main: honeybeeCraftzTheme.primary,
          contrastText: honeybeeCraftzTheme.primaryContrast
        },
        secondary: {
          main: honeybeeCraftzTheme.secondary
        },
      },
    },
    jewellery: {
      palette: {
        primary: {
          main: jewelleryTheme.primary,
          contrastText: jewelleryTheme.primaryContrast
        },
        secondary: {
          main: jewelleryTheme.secondary
        },
      },
      typography: {
        fontFamily: jewelleryTheme.fontFamily
      },
    },
    booking: {
      palette: {
        primary: {
          main: bookingTheme.primary,
          contrastText: bookingTheme.primaryContrast
        },
        secondary: {
          main: bookingTheme.secondary
        },
      },
      typography: {
        fontFamily: bookingTheme.fontFamily
      },
    }
  }

  const headerFooterMap: any = {
    sweetStall: { header: <Header />, footer: <Footer /> },
    grocery: { header: <Header />, footer: <Footer /> },
    liquor: { header: <Header />, footer: <Footer /> },
    restaurant: { header: <Header />, footer: <Footer /> },
    honeybee: { header: <HoneybeeHeader />, footer: <Footer /> },
    jewellery: { header: <JewelleryHeader />, footer: <JewelleryFooter /> },
    booking: { header: <BookingHeader />, footer: <BookingFooter /> },
  }

  const businessCategoryChanged = (category: any) => {
    if (category && category.name) {
      setBusinessCategory(category.name)
    }
  }

  useEffect(() => {
    businessCategoryChanged(businessCategoryInst);
  }, [businessCategoryInst]);

  const renderHeader = () => {
    if (!businessCategory) return <></>
    return headerFooterMap[businessCategory].header;
  }

  const renderFooter = () => {
    if (!businessCategory) return <></>
    return headerFooterMap[businessCategory].footer;
  }

  const theme = createTheme(themeMap[businessCategory]);

  const renderRoute = (route: any) => {
    const Component = route.component
    return <Route path={route.path}
      component={Component} />
  }


  return (
    <Box sx={{ fontFamily: theme.typography.fontFamily }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ConfirmProvider>
            <CatalogProvider>
              <CartProvider>
                <SecurityProvider>
                  <WishlistProvider>
                  <Router>
                    <Suspense fallback={<PageLoader />}>
                      <Box>
                        <Route exact={true} path="/" component={LandingPage} />
                      </Box>
                      <Box>
                        <Box>
                          <div id="loadingbar"></div>
                          {renderHeader()}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
                          <Box sx={{ pt: 10 }}>
                            {AllRoutes.map((route: any) => renderRoute(route))}
                          </Box>
                          <Box>
                            {renderFooter()}
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <JewelleryFab />
                      </Box>
                    </Suspense>
                    <ToastContainer />
                  </Router>
                  </WishlistProvider>
                </SecurityProvider>
              </CartProvider>
            </CatalogProvider>
          </ConfirmProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Box>
  );
}

export default App;
function componentDidMount() {
  throw new Error("Function not implemented.");
}

