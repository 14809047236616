export const honeybeeCraftzTemplates = {
  product: `<HoneybeeCraftzProduct data={data} actions={actions}/>`,
  productDetail: `<div className="product-details pb-5">
    <Box sx={{
      display: "flex", justifyContent: "space-between", width: { xs: "85%", sm: "70%", md: "40%" },
      backgroundColor: "primary.main", color: "secondary.main", position: "fixed"
      }} className="py-2 px-3">
      <span>
        <div className="font-17 font-weight-medium">{data.item.productName}</div>    
      </span>
      <CardIcon icon="fas fa-times-circle font-17 pr-5" action={actions.closeProductDetail}/>
    </Box>
    <Box sx={{ pt: 10, px: { sm: 2, md: 5 } }} className="px-5">
      <Box component="img" src={data.item.productImage} sx={{height: "300px", width: "300px"}} className="text-center" ></Box>
      <Box> 
        <div className="font-18 font-weight-medium pt-2">{data.item.productName}</div> 
        <Box sx={{display:"flex", justifyContent: "space-between"}}>
          <div className="pt-1 font-17"><FxCurrency value={data.item.unitPrice} /></div>
          <div className="d-flex font-26 pr-3">
            <span><i className="fas fa-minus-circle"></i></span>
            <span className="px-3">0</span>
            <span><i className="fas fa-plus-circle"></i></span>
        </div>
        </Box>
        <div className="font-15 font-weight-medium pt-4">Product Description</div>
        <div className="font-13 pt-1">{data.item.description}</div>
        <div className="font-15 font-weight-medium pt-3">
          <span>Size:</span>
          <span className="pl-2">{data.item.size}</span>
        </div>
      </Box>
      <Box sx={{py: 5}}><FxButton label="Add to Cart" variant="contained" size="small" fullWidth className="btn-style" /></Box>
    </Box>
  </div>`,
  cart: `<div className="cart-card">
  <Card className="pointer" sx={{ borderRadius: "4px", height: "70px", display: "flex", justifyContent: "space-between", alignItems: "center", px: "15px" }} >
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item xs={6}>
        <div>
          <span className="font-15 font-weight-medium pr-1">{data.item.productName}</span>         
        </div>
        <div><FxCurrency value={data.item.unitPrice} /></div>
      </Grid>
      <Grid item xs={3}>
        <div className="d-flex font-20">
          <CardIcon action={actions.minusQuantity} icon="fas fa-minus-circle" sx={{color: 'secondary.main' }}/>
          <span className="px-3">{data.item.productQty}</span>
          <CardIcon action={actions.addQuantity} icon="fas fa-plus-circle" sx={{color: 'secondary.main' }}/>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="font-15 font-weight-medium text-right"><FxCurrency value={data.item.qtyPrice} /></div>
      </Grid>
    </Grid>
  </Card>
</div>`,
paymentOptions: `<FxButtonGroup action={actions.handleChange} selected={data.paymentType} item={data.item}/>`,
deliveryOptions: `<FxButtonGroup action={actions.handleChange} selected={data.deliveryType} item={data.item}/>`,
deliveryAddress: `<div className="delivery-address">
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
      <div className="font-weight-medium font-16">Delivery Address</div>
      <Box sx={{color: "secondary.main"}}><i className="fas fa-pen font-15"></i></Box>
    </Box>

    {/* If no address selected */}
    {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div className="font-17">No Address Selected</div>
      <div><FxButton label="Select" variant="contained" size="small" className="btn-style" /></div>
    </Box> */}

    <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
      <div>Karthik</div>
      <div>North Street</div>
      <div>Chennai</div>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="pb-2">
      <div className="font-weight-medium font-16">Phone Number</div>
      <Box sx={{color: "secondary.main"}}><i className="fas fa-pen font-15"></i></Box>
    </Box>
    <Box sx={{ color: "#6C6C6C" }} className="font-15 pb-3">
      <div>9012345678</div>
    </Box>
  </Card>
</div>`,
deliverySlot: `<Grid item xs={6} sm={3}>
      <Box
          sx={{
            backgroundColor: "#EDEDED",
            border: "1px solid #ededed",
            borderRadius: "20px",
            paddingRight: "10px",
            paddingLeft: "10px",
            paddingTop: "5px",
            paddingBottom: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
        {data.item}
      </Box>
    </Grid>`,
billDetails: `<div className="delivery-address">
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex" }} className="pb-2">
      <div className="font-weight-medium font-16">Bill Details</div>         
    </Box>
    <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
      <div>Subtotal</div>
      <div><FxCurrency value={1234} /></div>
    </Box>
    <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="font-15 pb-1">
      <div>Delivery charge</div>
      <Box className="font-12 font-weight-medium" sx={{color: "#00A611"}}>Free Delivery</Box>
    </Box>
    <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
      <div>Coupon</div>
      <div><FxCurrency value={0} /></div>
    </Box>
    <Box sx={{ color: "#6C6C6C", display: "flex", justifyContent: "space-between" }} className="font-15 pb-1">
      <div>GST</div>
      <div><FxCurrency value={0} /></div>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="font-15 pb-1 font-weight-medium">
      <div>Grand Total</div>
      <div><FxCurrency value={1234} /></div>
    </Box>
  </Card>
</div>`,
couponCard: `<div className="coupon-card">
  <Card sx={{ borderRadius: "4px", p: "10px" }} >
    <Box sx={{ display: "flex" }} className="pb-2">
      <div><img src={data.item.icon} width="23px" /></div>
      <div className="font-weight-medium font-16 pl-2">Apply Coupon</div>
    </Box>
    <Box className="pb-3">
      <div>Use coupon code to avail 10% offer for your order</div>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <div>
        <FxInput name="couponCode" value={data.item.couponCode} variant="outlined" size="small" />
      </div>
      <div className="pl-3">
        <FxButton label="Select" variant="contained" size="small" sx={{
          height: "30px",
          textTransform: "none",
          fontWeight: "600"
        }} />
      </div>
    </Box>
  </Card>
</div>`,
summary: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
<Grid container direction="row" alignItems="center" spacing={1}>
  <Grid item xs={3}>
    <Box component="img" src={data.item.productImage} sx={{width: "50px",
      height: "50px",
      borderRadius: "4px"}} />
  </Grid>
  <Grid item xs={5}>
    <div>{data.item.productName}</div>
    <div className="font-12 color-888">{data.item.productUnit}</div>
    <div><FxCurrency value={data.item.unitPrice} /></div>
  </Grid>
  <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
  <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
</Grid>
</Box>`,
myOrders: `<MyordersCard data={data} actions={actions} />`,
orderDetail: `<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", px: { xs: "10px", md: "50px", lg: "300px" }, py: "10px" }}>
<Grid container direction="row" alignItems="center" spacing={1}>
  <Grid item xs={3}>
    <Box component="img" src={data.item.productImage} sx={{width: "50px",
      height: "50px",
      borderRadius: "4px"}} />
  </Grid>
  <Grid item xs={5}>
    <div>{data.item.productName}</div>
    <div className="font-12 color-888">{data.item.productUnit}</div>
    <div><FxCurrency value={data.item.unitPrice} /></div>
  </Grid>
  <Grid item xs={1} className="text-right">{data.item.productQty}</Grid>
  <Grid item xs={3} className="text-right"><FxCurrency value={data.item.qtyPrice} /></Grid>
</Grid>
</Box>`
}