import { useContext } from "react";
import { WishlistContext } from "./WishlistProvider";

export default function useWishlistContext() {
  const context = useContext(WishlistContext);
  if (!context) {
    throw new Error("useContext must be used within a WishlistProvider");
  }
  return context;
}
