import { IAppAction } from ".";
import { localstorage } from "../services/LocalStorageService";

const initialState: any = {
};

export const businessCategory = (state: any = initialState, action: IAppAction): any => {
  switch (action.type) {
    case "update-businesscategory":
      localstorage.setItem('businesscategory', action.payload.name);
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}